import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';

interface Iprops {
  isLogin?: boolean;
}

const BelowForm = ({ isLogin }: Iprops): JSX.Element => {
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentScanner] = useGlobalState('currentScanner');
  const [language] = useGlobalState('language');

  const i18n = I18N(language);
  const navigation = useNavigation();
  const navigateTo = isLogin ? 'Register' : 'Login';
  const title = isLogin ? i18n.t('auth.createAccount') : i18n.t('auth.logIn');
  const buttontext = isLogin ? i18n.t('auth.register') : i18n.t('auth.connect');
  const routeIndex = navigation.getState().index
  const routeName = navigation.getState().routes[routeIndex].name
  const navigate = (navigateTo: string) => {
    if(routeName.includes('RegisterConfirmation')) navigation.goBack(null)
    if (Platform.OS === 'web') navigation.replace(`${navigateTo}`);
    else if (!routeName.includes('Modal')) navigation.navigate(`${navigateTo}Modal`);
    else navigation.replace(routeName.includes('Measurement') && currentMeasurements ? `${navigateTo}MeasurementModal` : `${navigateTo}Modal`);
  };

  return (
    <View style={Styles.contentWrapper}>
      <View style={styles.divider} />
      <Text style={styles.titeText}>{title}</Text>
      <TouchableOpacity style={Styles.submitButtonLight} onPress={() => currentScanner ? navigate(navigateTo) : undefined}>
        <Text style={styles.buttonText}> {buttontext}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default BelowForm;

const styles = StyleSheet.create({
  divider: {
    width: '95%',
    alignSelf: 'center',
    borderTopColor: '#707070',
    paddingEnd: '5%',
    borderTopWidth: 1,
  },
  titeText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
