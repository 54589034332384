import BouncyCheckbox from "react-native-bouncy-checkbox";
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { useGlobalState } from '../../shared/state';
import { useNavigation } from '@react-navigation/native';
import { I18N } from '../../shared/util';

interface Props {
  text:string
  submitted?: boolean;
  termsAndConditions: boolean;
  parentCallback: () => void;
  isNewsLetter?: boolean

}

const CustomCheckBox = ({ text, submitted, termsAndConditions, parentCallback, isNewsLetter=false }: Props): JSX.Element => {
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [language] = useGlobalState('language');
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);
  const [Colors] = useGlobalState('colors');

  const navigation = useNavigation();
  const i18n = I18N(language);
  // @ts-ignore
  const toProps = {
    style: submitted && !termsAndConditions && !isNewsLetter ? notChecked : styles.checkbox,
    checked: termsAndConditions,
    onPress: () => parentCallback(),
    color: '#7cbae6'
  };

  const navigate = () => {
    navigation.state.routeName.includes('Measurement') &&
      currentMeasurements
      ? navigation.navigate('DataProtectionModal', { MeasurementModal: true, isNewsLetter })
      : navigation.navigate('DataProtectionModal', { MeasurementModal: false, isNewsLetter })
  }

  return (
    <View style={styles.checkboxWrapper}>
      <BouncyCheckbox 
        {...toProps}
        textComponent 
        innerIconStyle={{ borderColor: "#2bc6ff" }}
        fillColor={"#2bc6ff"}
        isChecked={checkBoxValue}
        onPress={() => { setCheckBoxValue(!checkBoxValue), parentCallback() }}
      />
      <Text style={styles.termsAndConditions}>
        {text}
        <Text style={Colors.mainColorLight} onPress={() => WebBrowser.openBrowserAsync('https://onefid.com/datenschutz-plattform/', {windowFeatures: {width:1200,height:1000}})}>
          {' '} {i18n.t('auth.termsAndConditions')[1]}{' '}
        </Text>
        {isNewsLetter && i18n.t('auth.shoepassionNewsletter')[1]}
      </Text>
    </View>
  );
};

export default CustomCheckBox;

const styles = StyleSheet.create({
  checkboxWrapper: {
    flexDirection: 'row',
    marginTop: '1%',
  },
  checkbox: {
    borderColor: '#707070',
  },
  termsAndConditions: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
    color: '#000000',
    fontWeight: '100',
    marginLeft: '4%',
    marginTop: '0.5%'
  }
});
export const notChecked = {
  height: '30%',
  borderRadius: 3,
  borderWidth: 1,
  marginLeft: '-1.5%',
  shadowColor: '#ff3434',
  borderColor: 'red',
  shadowOpacity: 0.6,
  shadowRadius: 3,
  elevation: 10
};
