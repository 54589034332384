import { View } from 'react-native';
import React from 'react';
import { StyleSheet, Text, ActivityIndicator } from 'react-native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';


type Props = {
  progress: number
  scanners: any[]
}

const NetworkScan: React.FC<Props> = ({ progress }: Props): JSX.Element =>{
  const [ language ] = useGlobalState('language');
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);

   return(
      <View>
    <View style={styles.contentWrapper}>
      <Text style={styles.scanningText}>{i18n.t('detect scanner message')}</Text>
      <View style={styles.progressBarWrapper}>
        <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
      </View>
    </View>
  </View>)
  }

export default NetworkScan;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    maxHeight: 100
  },
  scanningText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 30,
    textTransform: 'uppercase',
    marginBottom: 15
  }
});
