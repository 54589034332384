import 'react-native-gesture-handler';
import { useFonts } from "expo-font";
import React, { useEffect, useState } from "react";
import { LogBox, Platform, StatusBar } from 'react-native';
import UserInactivity from "react-native-user-inactivity";
import { GlobalStateProvider, useGlobalState } from "./src/shared/state";
import { decode, encode } from "js-base64";
import Routes from './src/routes';
import * as Sentry from "sentry-expo";

export default function App() {

  if (Platform.OS != "web") LogBox.ignoreLogs([""]);
  const [, setIsUserActive] = useGlobalState("isUserActive");
  const [fontsLoading, setFontsLoading] = useState(false);

  const [fontsLoaded] = useFonts({
    "Montserrat-Black": require("./src/assets/fonts/Montserrat-Black.ttf"),
    "Montserrat-Bold": require("./src/assets/fonts/Montserrat-Bold.ttf"),
    "Montserrat-ExtraLight": require("./src/assets/fonts/Montserrat-ExtraLight.ttf"),
    "Montserrat-Light": require("./src/assets/fonts/Montserrat-Light.ttf"),
    "Montserrat-Regular": require("./src/assets/fonts/Montserrat-Regular.ttf"),
    "Montserrat-SemiBold": require("./src/assets/fonts/Montserrat-SemiBold.ttf")
  });

  useEffect(() => {
    if (!global.btoa) {
      global.btoa = encode;
    }
    if (!global.atob) {
      global.atob = decode;
    }
    
    fontsLoaded && setFontsLoading(true)
    Sentry.init({
      dsn: globalThis.env.SENTRY_DSN,
      enableInExpoDevelopment: true
    });
  }, [fontsLoaded]);

  return (
    <>
     {fontsLoading && ( <GlobalStateProvider>
        <StatusBar hidden />
        <UserInactivity
          timeForInactivity={900000}
          onAction={setIsUserActive}
        >
          <Routes />
        </UserInactivity>
      </GlobalStateProvider>)}
    </>
  );
}