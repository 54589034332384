import React, { useState } from 'react';
import { Image, View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';
import BouncyCheckbox from "react-native-bouncy-checkbox";
import I18n from '../../i18nSets/i18n';
import { useGlobalState } from '../../shared/state';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { insoleCategories } from '../InsoleRecommendation/InsoleRecommendation';

const MarketingProductExtension: React.FC<any> = (
  enableOrderButton: boolean,
  productData: any
): JSX.Element => {
    const [currentMeasurements] = useGlobalState('currentMeasurements');
    const [currentScanner] = useGlobalState('currentScanner');
    const [currentUser] = useGlobalState('currentUser');
    const [newScanState] = useGlobalState('newScanState');
    const navigation = useNavigation();
    const [product] = useState<any>(productData || undefined)
    const [language] = useGlobalState('language');
    const [toggleCheckBox, setToggleCheckBox] = useState(false)
    const gender = currentUser ? currentUser.gender : newScanState.gender === "male" ? "m" : "f";
    const genderForInsoleFilter = gender === "f" ? "female" : "unisex";
    var newInsoleDataStructure: any = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter]

    var insoleCategoryIndex = Math.min(insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation), insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation));
    var extensionProductData: any = newInsoleDataStructure ? newInsoleDataStructure[insoleCategories[insoleCategoryIndex]]: currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === `${insoleCategories[insoleCategoryIndex]}`)

    return (
        <View style={styles.recommendationCrad} >
            <View style={styles.recommendationHeaderContainer}>
                <Text style={styles.recommendationCardNameText}>{I18n.t('recommendedForYou', { locale: language })}</Text>
                <View style={styles.seperator}></View>
            </View>
            <View style={styles.recommendationDetailContainer}>
                <Image source={{ uri: extensionProductData.image }} style={styles.imageContainer} />
                <Text style={styles.productCardNameText}>{extensionProductData.name}</Text>
                {extensionProductData.price != undefined && <Text style={styles.productCardPriceText}>{`${I18n.t('price'), { locale: language }}: ${extensionProductData.price}€`}</Text>}
            </View>

            {enableOrderButton && currentScanner.enableOrder ? 
            <>
              <View style={styles.checkboxWrapper}>
                <BouncyCheckbox 
                  innerIconStyle={{ borderColor: "#2bc6ff" }}
                  fillColor={"#2bc6ff"}
                  isChecked={toggleCheckBox}
                  onPress={() => setToggleCheckBox(!toggleCheckBox)}
                  text={I18n.t("addItem", { locale: language })}
                  textStyle={styles.checkBoxText}
                />
              </View>
              <View>
                <TouchableOpacity style={styles.basketContainer} onPress={() => product?.msi ? navigation.navigate('CustomiseProducOrder', { GTIN: product?.gtin }) : navigation.navigate('Order', { GTIN: product?.gtin, INCLUDE_INSOLE: toggleCheckBox })} >
                  <View style={styles.basketContainerView}>
                    <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
                    <Text style={styles.basketContainerText}>{I18n.t('order now', { locale: language })}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </>
              : 
              <TouchableOpacity style={styles.basketContainer} onPress={() => Platform.OS === 'web' ? navigation.navigate('InsoleRecommendation', { step: 3 }): navigation.navigate('InsoleDetailModal')}>
                <View style={styles.basketContainerView}>
                    <Image source={Icons.feedbackIcon} style={styles.feedbackIcon}></Image>
                    <Text style={styles.basketContainerText}>{I18n.t('moreDetails', { locale: language })}</Text>
                </View>
              </TouchableOpacity>
            }

        </View >)
}

export default MarketingProductExtension;

const styles = StyleSheet.create({
    recommendationCrad: {
        height: '98.5%',
        borderWidth: 0,
    },
    recommendationHeaderContainer: {
        height: '15%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: 10,
        width: '100%'
    },
    seperator: {
        width: '50%',
        height: '20%',
        backgroundColor: 'black',
        alignSelf: "flex-start"
    },
    recommendationDetailContainer: {
        height: '65%',
        padding: 10
    },
    recommendationCardNameText: {
        fontFamily: 'Montserrat-Black',
        fontSize: 18,
        fontWeight: '900',
    },

    feedbackIcon: {
        width: '7%',
        height: '20%',
        marginRight: '2%'
    },
    imageContainer: {
        flex: 1,
        resizeMode: 'contain'
    },
    productCardNameText: {
        textAlign: "left",
        fontFamily: 'Montserrat-Black',
        fontSize: 16,
    },
    productCardPriceText: {
        fontFamily: 'Montserrat-SemiBold',
        fontSize: 14
    },
    basketContainer: {
        position: 'absolute',
        right: 0,
        bottom: -7,
        borderWidth: 0.5,
        borderColor: '#d6d7da',
        shadowColor: "#000",
        shadowOffset: {
            width: 0.2,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 1,
        elevation: 1,
        zIndex: -10,
        height: '17%',
        width: '100%',
        backgroundColor: 'transparent'
    },

    basketContainerView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    basketContainerText: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 16,
        fontWeight: '900'
    },
    basketIcon: {
      width: '15%',
      height: '40%',
      resizeMode: 'contain',
      marginRight: '5%'
    },
    checkboxWrapper: {
      justifyContent: "center",
      width:'95%',
      flexDirection: 'row',
      alignContent: "center",
    },
    checkBoxText: {
      fontFamily: 'Montserrat-Regular',
      fontSize: 12,
      color: '#000000',
      fontWeight: '100',
      textDecorationLine: "none",
    }
})