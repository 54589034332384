import { StyleSheet } from 'react-native';

export const Fonts = StyleSheet.create({
  pageTitle: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  pageSubTitle: {
    fontFamily: 'Montserrat-Black',
    fontSize: 19,
    fontWeight: '600',
    textAlign: 'center',
    alignSelf: 'center',
    width: '75%'
  },
  textGigantic: {
    fontFamily: 'Montserrat-Black',
    fontSize: 51,
    fontWeight: '900'
  },
  textHuge: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900'
  },
  text: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
  },
  textSmaller: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 11,
  },
  textTiny: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 8,
  },
  textTaller: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 13,
  },
  textBold: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 12
  },
  textInput: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
  },
  menuButtonCaption: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 16,
  },
  squareMenuButtonCaption: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900'
  },
  size12: {
    fontSize: 12
  },
  size14: {
    fontSize: 14
  },
  size16: {
    fontSize: 16
  },
  size18: {
    fontSize: 18
  },
  size20: {
    fontSize: 20
  },
  size24: {
    fontSize: 24
  },
  size30: {
    fontSize: 30
  },
  size40: {
    fontSize: 40
  },
  center: {
    textAlign: 'center'
  },
  mBlack: {
    fontFamily: 'Montserrat-Black'
  },
  mBold: {
    fontFamily: 'Montserrat-Bold'
  },
  mSemiBold: {
    fontFamily: 'Montserrat-SemiBold'
  },
  mLight: {
    fontFamily: 'Montserrat-Light'
  },
  mRegular: {
    fontFamily: 'Montserrat-Regular'
  },
});
