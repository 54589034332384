import API from '../../api';
import { sleep } from '../../util';

// Basic config
const CORE_BASE_URL = globalThis.env.ONEFID_CORE_API;
const apiLevel = "v2/";


const SCANNER_SERVICE_URL = `${CORE_BASE_URL}${apiLevel}scanconfig/`;
const SCAN_ID_ENDPOINT = '/scan';

const getScannerInfo = async (partnerToken: string): Promise<any> =>
    API.get(SCANNER_SERVICE_URL, { headers: { Authorization: `Token ${partnerToken}` } });

const moveFileFromScannerHost = async (
    destinationFolder: string,
    gpManagerPath: string,
    gpManagerParams: string,
    firstName: string,
    lastName: string,
    birthdate: string,
    applianceId: string,
    date: string,
    industryPartner: string,
    deviceIdentifier: string,
    shoeType: string,
    shoeModel: string,
    shoeSize: string,
    cartId: string): Promise<any> =>
    API.post(`http://localhost:3000/api/v1/move-file`, {
        destinationFolder: destinationFolder,
        gpManagerPath: gpManagerPath,
        gpManagerParams: gpManagerParams,
        first_name: firstName,
        last_name: lastName,
        birthdate: birthdate,
        appliance_id: applianceId,
        date: date,
        industry_partner: industryPartner,
        device_identifier: deviceIdentifier,
        shoe_type: shoeType,
        shoe_model: shoeModel,
        shoe_size: shoeSize,
        cart_id: cartId
    });

const calibrateScanner = (): Promise<any> =>
    API.post("http://localhost:3000/api/v1/calibration",{});



const getScan = (scannerAddress: string, scannerParameter: any): Promise<any> => {
    if (globalThis.env.MOCK_PARTNER_TOKEN) {
        
        const scanVolume = scannerParameter.side;

        return sleep(2000).then(() => Promise.resolve(
            scanVolume === 'both'
                ? [{
                    "InsoleRecommendation": "mid",
                    "rawMeasurements": {
                        "archHeight": 12.75,
                        "heelAngle": 180,
                        "heelAngleH": 0,
                        "heelAngleT": 0
                    },
                    "categorizedMeasurements": {
                        "archHeight": {
                            "value": 0.31775587197273975,
                            "category": 1
                        },
                        "heelAngle": {
                            "value": 0.625,
                            "category": 2
                        },
                        "heelAngleH": {
                            "value": 0.525,
                            "category": 2
                        },
                        "heelAngleT": {
                            "value": -0.48,
                            "category": 0
                        },
                        "footType": {
                            "value": "flat",
                            "category": 0
                        }
                    },
                    "plantarImage": 'cdjn8v23n8f23nu290fin90cf3==',
                    "colouredSole": JSON.parse(JSON.stringify(require('../../../assets/mockData/right_sole.json'))),
                    "format": "ply",
                    "side": "right",
                    "data": JSON.parse(JSON.stringify(require('../../../assets/mockData/right_mesh.json')))
                },
                {
                    "InsoleRecommendation": "special",
                    "rawMeasurements": {
                        "archHeight": 10.75,
                        "heelAngle": 180,
                        "heelAngleH": 0,
                        "heelAngleT": 0
                    },
                    "categorizedMeasurements": {
                        "archHeight": {
                            "value": -0.6900012090436459,
                            "category": 0
                        },
                        "heelAngle": {
                            "value": 0.625,
                            "category": 2
                        },
                        "heelAngleH": {
                            "value": 0.525,
                            "category": 2
                        },
                        "heelAngleT": {
                            "value": -0.48,
                            "category": 0
                        },
                        "footType": {
                            "value": "flat",
                            "category": 0
                        }
                    },
                    "plantarImage": "cdjn8v23n8f23nu290fin90cf3==",
                    "colouredSole": JSON.parse(JSON.stringify(require('../../../assets/mockData/left_sole.json'))),
                    "format": "ply",
                    "side": "left",
                    "data": JSON.parse(JSON.stringify(require('../../../assets/mockData/left_mesh.json')))
                }]
                :
                {
                    side: scanVolume,
                    data: '83hc8rdddj29ik9203ix'
                }

        ));
    } else {
        return API.post(`${scannerAddress}:3000/api/v1${SCAN_ID_ENDPOINT}/`, scannerParameter, { timeout: 360000 });
    }
};

const ScannerService = { getScannerInfo, getScan, calibrateScanner, moveFileFromScannerHost };

export default ScannerService;
