import React from 'react';
import { Image, PixelRatio, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { defaultLogo, Icons } from '../../assets';
import I18n from '../../i18nSets/i18n';
import { useGlobalState } from '../../shared/state';
import { Fonts } from '../../styles';
import RecommendationModal from './RecommendationModal';

type Props = {
  conclusion: number;
  onClick: () => void;
};

const QuestionnaireEvaluation: React.FC<Props> = ({ conclusion, onClick }: Props): JSX.Element => {
  const [language] = useGlobalState('language');

  return (
    <>
      {conclusion === 2 ? (
        <RecommendationModal />
      ) : (
        <View style={styles.contentBox}>
          {conclusion === 0 && (
            <View style={styles.logoContainer}>
              <Image style={styles.logo} source={defaultLogo} />
            </View>
          )}
          <View style={styles.mainMessageContainer}>
            <Text style={[Fonts.mRegular, Fonts.size40, Fonts.center, { color: 'black' }]}>{I18n.t(`insole.recommendation.${conclusion}.mainMessage`, { locale: language })}</Text>
          </View>
          <View style={styles.subMessageContainer}>
            <Text style={[Fonts.text, Fonts.center, { color: 'black' }]}>{I18n.t(`insole.recommendation.${conclusion}.subMessage`, { locale: language })}</Text>
          </View>
          <View style={styles.spacerContainer}>
            <Image source={conclusion === 1 ? Icons.NotFoundImage : Icons.checkmarkIconCircle} style={styles.spacerIcon} />
          </View>

          <View style={styles.conclusionContainer}>
            <Text style={[Fonts.mBlack, Fonts.size20, Fonts.center]}>{I18n.t(`insole.recommendation.${conclusion}.conclusion`, { locale: language })}</Text>
          </View>
          <View style={styles.buttonRowContainer}>
              <TouchableOpacity style={styles.button} activeOpacity={0.6} onPress={onClick}>
                <Text style={[Fonts.textTaller, { color: 'black' }]}>{I18n.t('insole.recommendation.button', { locale: language })}</Text>
              </TouchableOpacity>
          </View>
        </View>
      )}
    </>
  );
};

export default QuestionnaireEvaluation;

const styles = StyleSheet.create({
  contentBox: {
    backgroundColor: '#ffffff',
    opacity: 1,
    width: '80%',
    minHeight: '87%',
    maxHeight: '87%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 1
  },
  logoContainer: {
    width: '100%',
    height: '30%',
    minHeight: '30%',
    maxHeight: '30%'
  },
  logo: {
    width: '100%',
    height: '30%',
    maxWidth: '100%',
    maxHeight: '30%',
    resizeMode: 'contain',
    marginTop: '12%'
  },
  mainMessageContainer: {
    minHeight: '25%',
    maxHeight: '25%',
    maxWidth: '70%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  subMessageContainer: {
    minHeight: '11%',
    maxHeight: '11%',
    maxWidth: '70%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  spacerContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '7%',
    maxHeight: '25%',
    width: '50%',
    paddingBottom: '8%',
    borderBottomWidth: 1 / PixelRatio.get(),
    borderBottomColor: '#000000'
  },
  spacerIcon: {
    maxHeight: '125%',
    resizeMode: 'contain'
  },
  conclusionContainer: {
    minHeight: '16%',
    maxHeight: '16%',
    maxWidth: '50%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonRowContainer: {
    minHeight: '9%',
    maxHeight: '9%',
    minWidth: '30%',
    maxWidth: '50%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    borderWidth: 1 / PixelRatio.get(),
    borderColor: '#000000',
    borderRadius: 30 / PixelRatio.get(),
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
});
