import { View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, ActivityIndicator } from 'react-native';
import { background } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { Styles } from '../../styles';
import CategoryCard from './CategoryCard';
import { CategoryCardDTO } from '../../shared/model/types';
import { useGlobalState } from '../../shared/state';
import Swiper from 'react-native-web-swiper';
import { chunk, groupByKey, I18N } from '../../shared/util';

const CatalogTree: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  const [matchedProducts] = useGlobalState('matchedProducts');
  const [subcategoryList, setSubcategoryList] = useState<any>([])
  const [isLoading, setLoading] = useState<boolean>(true);
  const [language] = useGlobalState('language');
  const i18n = I18N(language);
  const navigation = useNavigation();

  const category: any = (params && params.category) || undefined
  const translatedTitle: string = i18n.t(`SafetyCatalog.${category['name'].toLocaleLowerCase()}`)
  const title: any = translatedTitle.includes('missing') ? category.name : translatedTitle

  const nav = (categoryAttribute: CategoryCardDTO) => {
    navigation.navigate('CatalogProducts', { categoryID: category.id, categoryAttribute: categoryAttribute });
  };

  useEffect(() => {
    if (subcategoryList.length > 0 && isLoading) setLoading(false);
  }, [subcategoryList])

  useEffect(() => {
    category.attributes.forEach((attribute: CategoryCardDTO, index: number) => {
      for (let res of groupByKey(matchedProducts, "id")[category.id]) {
        if (res.attributes === attribute.id) {
          setSubcategoryList((prevState: any) => [...prevState,
            attribute
          ])
          break;
        }
      }
    })
  }, [])

  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={title}>
          <View style={styles.loader}>
            <ActivityIndicator size="large" color={'#2bc6ff'} />
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  }

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={title}>
        <View style={styles.swipeWrapper}>
          <Swiper
            from={0}
            minDistanceForAction={0.1}
            controlsProps={{
              dotsTouchable: true,
              nextPos: false,
              prevPos: false,
              dotActiveStyle: { backgroundColor: '#3a3a3a' },
              dotsWrapperStyle: { width: 13, height: 13 },
            }}
          >
            {chunk(subcategoryList, 6).map((attributes: any, index: number) => (
              <View style={styles.cardWrapper} key={index} >
                {attributes.map((attribute: any, index: number) => {
                  return <CategoryCard key={index} CategoryCard={attribute} onPress={nav} />
                })}
              </View>
            ))}
          </Swiper>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default CatalogTree;

const styles = StyleSheet.create({
  cardWrapper: {
    width: '81%',
    height: '90%',
    flexDirection: 'row',
    alignSelf: 'center',
    flexWrap: 'wrap',
    paddingTop: '3.5%',
    paddingLeft: '0.2%'
  },
  swipeWrapper: {
    flex: 1
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '2%',
    paddingLeft: '1%'
  },
});
