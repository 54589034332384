import React, { useState, useEffect, useRef } from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, PixelRatio, Image, View, ActivityIndicator, TextInput, Platform, ScrollView } from 'react-native';
import { background, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { applyMultiFilter, I18N } from '../../shared/util';
import { Styles, Fonts } from '../../styles';
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { getMatchedProductsByGTIN } from '../../shared/products';

const CatalogFilter: React.FC<any> = (route): JSX.Element => {
    const { params } = route.route;
    const [language] = useGlobalState("language");
    const [matchedProducts] = useGlobalState('matchedProducts');
    const [currentScan] = useGlobalState('currentScan');
    const [currentUser] = useGlobalState('currentUser');
    const [, setIsOrthoOrder] = useGlobalState('isOrthoOrder');

    const [category, setCategory] = useState<string[]>([]);
    const [brand, setBrand] = useState<string[]>([]);
    const [color, setColor] = useState<string[]>([]);
    const [leatherTypes, setLeatherTypes] = useState<string[]>([]);
    const [underground, setUnderground] = useState<string[]>([]);
    const [support, setSupport] = useState<string[]>([]);
    const [categoryValue, setCategoryValue] = useState<string[]>([]);
    const [brandValue, setBrandValue] = useState<string[]>([]);
    const [colorValue, setColorValue] = useState<string[]>([]);
    const [leatherTypeValue, setLeatherTypeValue] = useState<string[]>([]);
    const [undergroundValue, setUndergroundValue] = useState<string[]>([]);
    const [supportValue, setSupportValue] = useState<string[]>([]);
    const [isSaleSelected, setSelection] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [scrollEnd, setScrollEnd] = useState(false);
    const [dispayScrollHelper, setDispayScrollHelper] = useState(true);
    const [filtredProducts, setFiltredProducts] = useState<any[]>([]);
    const [matchedProductHolder, setMatchedProductHolder] = useState<any[]>([]);
    const [matchedProductOnSale, setMatchedProductOnSale] = useState<any[]>([]);
    const [Colors] = useGlobalState('colors');

    const scrollViewRef = useRef(null);

    const i18n = I18N(language);
    const navigation = useNavigation();

    useEffect(()=>{
        if(params && params.isOrthoOrder === false) {
            setIsOrthoOrder(false);
        }
    }, [params]);

    useEffect(() => {
        setFiltredProducts(applyMultiFilter(isSaleSelected ? matchedProductOnSale : matchedProductHolder, {
            brand: brandValue,
            categoryName: categoryValue,
            color: colorValue,
            leatherType: leatherTypeValue,
            underground: undergroundValue,
            support: supportValue
        }))
    }, [categoryValue, brandValue, colorValue, isSaleSelected, leatherTypeValue, undergroundValue, supportValue])

    useEffect(() => {
        if (matchedProducts) {
            var brandArray: any = []
            var categoryArray: any = []
            var colorArray: any = []
            var letherTypeArray: any = []
            var undergroundArray: any = []
            var supportArray: any = []
            var filtredProductsArray: any = []
            var filtredProductsOnSaleArray: any = []
            matchedProducts.map((product: any) => {
                brandArray.push(product.products.brand)
                categoryArray.push(product.name)
                colorArray.push(product.products.color)
                filtredProductsArray.push(product.products)
                letherTypeArray.push(product.products.leatherType)
                undergroundArray.push(product.products.underground)
                supportArray.push(product.products.support)
                if (product.products.salePrice && product.products.price > product.products.salePrice) filtredProductsOnSaleArray.push(product.products)
            })
            setBrand(Array.from(new Set(brandArray.filter(brand => !!brand))))
            setCategory(Array.from(new Set(categoryArray.filter(category => !!category))))
            setColor(Array.from(new Set(colorArray.filter(color => !!color))))
            setLeatherTypes(Array.from(new Set(letherTypeArray.filter(leather => !!leather))))
            setUnderground(Array.from(new Set(undergroundArray.filter(underground => !!underground))))
            setSupport(Array.from(new Set(supportArray.filter(support => !!support))))
            setMatchedProductHolder(filtredProductsArray)
            setFiltredProducts(filtredProductsArray)
            setMatchedProductOnSale(filtredProductsOnSaleArray)
            setIsLoading(false)
        }
    }, [matchedProducts])

    const nav = () => { filtredProducts.length ? navigation.navigate('CatalogProducts', { filtredProducts: filtredProducts }) : undefined };
    //Filter matched products with product name
    const onchangeText = (text: string) => setFiltredProducts(matchedProductHolder.filter((product: any) => product.name.toLowerCase().includes(text.toLowerCase())))
    //Get scanned GTIN on mavigation.GoBack 
    const onSelect = data => {
        var onfid: string = currentUser && currentUser.email || currentUser && currentUser.employeeNumber || currentScan.customer_uuid
        getMatchedProductsByGTIN(onfid, data.GTIN).then((res: any) => setFiltredProducts([res]))
    };

    const doScroll = () => {
        scrollEnd
            ? [scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true }), setScrollEnd(false)]
            : [scrollViewRef.current.scrollToEnd({ animated: true }), setScrollEnd(true)]
    }

    const searshBar = () =>
        <>
            <View style={styles.leftHeaderWrapper}>
                <View style={styles.searchWrapper}>
                    <View style={styles.loopIconWrapper}>
                        <Image source={Icons.searshLoop} style={styles.loopIcon} />
                    </View>
                    <TextInput
                        placeholder={i18n.t('searchPlaceHolder')}
                        onChangeText={((value: any) => onchangeText(value))}
                        style={styles.searchBar}
                        returnKeyType='search'
                    />
                    {Platform.OS != 'web' && <TouchableOpacity style={styles.barCodeWrapper} onPress={() => navigation.navigate('BarCodeScanner', { onSelect: onSelect })}>
                        <Image source={Icons.barCodeIcon} style={styles.barCodeIcon} />
                    </TouchableOpacity>}
                </View>
                <TouchableOpacity style={{ backgroundColor: 'transparent' }} onPress={() => { setBrandValue([]), setCategoryValue([]), setColorValue([]) }}  >
                    <Text style={[styles.buttonText, Colors.mainColorLight, { textDecorationLine: 'underline' }]}>{i18n.t('resetFilter')}</Text>
                </TouchableOpacity>
            </View>
        </>

    const selectOption = (name: string, values: string[], setValue: (option: any) => void, optionvalue: string[]) => {
        return (
            <View style={styles.selectionContent}>
                <View style={styles.headerWrapper}>
                    <Text style={Fonts.textHuge}>{name}</Text>
                </View>
                <View style={styles.optioncardWrapper}>
                    {values.map((option: any, index: number) => (
                        <TouchableOpacity style={optionvalue.includes(option) ? styles.optionCardActive : styles.optionCard} key={index} onPress={() => optionvalue.includes(option) ? setValue(optionvalue.filter((e: any) => e !== option)) : setValue((prevValue: any) => [...prevValue, option])} >
                            <Text style={optionvalue.includes(option) ? styles.optionCardCaptionActive : styles.optionCardCaption}>{option}</Text>
                        </TouchableOpacity>))}
                </View>
            </View >
        )
    }
    if (isLoading) {
        return (
            <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
                <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={i18n.t('FilterCatalog.title')}>
                    <View style={styles.loaderWrapper}>
                        <View style={styles.loader}>
                            <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
                            <Text style={[Fonts.textTaller, Fonts.size18]}>{i18n.t('loadProductMessage')}</Text>
                        </View>
                    </View>
                </LayoutWrapper>
            </ImageBackground>
        );
    }
    return (
        <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
            <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={i18n.t('FilterCatalog.title')}>
                <View style={Styles.contentWrapper}>
                    <View style={styles.cardWrapper}>
                        <View style={styles.scrollViewWrapper}>
                            {searshBar()}
                            <ScrollView
                                ref={scrollViewRef}
                                style={styles.selectOptionCardWrapper}
                                persistentScrollbar={true}
                                onScrollBeginDrag={() => { setDispayScrollHelper(false) }} 
                                onScrollEndDrag={() => [setDispayScrollHelper(true), setScrollEnd(true)]}
                            >
                                {category.length > 0 && selectOption(i18n.t('FilterCatalog.category'), category, setCategoryValue, categoryValue)}
                                {brand.length > 0 && selectOption(i18n.t('FilterCatalog.brand'), brand, setBrandValue, brandValue)}
                                {color.length > 0 && selectOption(i18n.t('FilterCatalog.color'), color, setColorValue, colorValue)}
                                {leatherTypes.length > 0 && selectOption(i18n.t('FilterCatalog.leahtherType'), leatherTypes, setLeatherTypeValue, leatherTypeValue)}
                                {underground.length > 0 && selectOption(i18n.t('FilterCatalog.underground'), underground, setUndergroundValue, undergroundValue)}
                                {support.length > 0 && selectOption(i18n.t('FilterCatalog.support'), support, setSupportValue, supportValue)}
                            </ScrollView>
                            {dispayScrollHelper && <TouchableOpacity style={styles.scrollIconWrapper} onPress={() => doScroll()} >
                                <Image source={scrollEnd ? Icons.scrollUp : Icons.scrollEnd} style={styles.scrollIcon} />
                            </TouchableOpacity>}
                        </View>
                        <View style={styles.checkboxWrapper}>
                            <BouncyCheckbox 
                                style={styles.checkbox}
                                onPress={() => setSelection(!isSaleSelected)}
                                innerIconStyle={{ borderColor: "#2bc6ff" }}
                                fillColor={"#2bc6ff"}
                                isChecked={isSaleSelected}
                                text={i18n.t('FilterCatalog.sale')}
                                textStyle={styles.checkBoxText}
                            />
                        </View>
                        <View>
                            <TouchableOpacity style={[Styles.submitButtonDark, filtredProducts.length === 0 ? [Styles.submitButtonLight, { borderColor: 'red', marginBottom: '5%' }] : undefined]} onPress={() => nav()}>
                                <Text style={[styles.buttonText, filtredProducts.length ? Colors.white : Colors.black]}>{`${i18n.t('FilterCatalog.showProducts')}(${filtredProducts.length})`}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </LayoutWrapper>
        </ImageBackground>
    );
};

export default CatalogFilter;

const styles = StyleSheet.create({
    cardWrapper: {
        width: '80%',
        height: '100%',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    selectOptionCardWrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: '#ffff',
        opacity: .87,
        borderRadius: 12 / PixelRatio.get(),
        padding: '1%',
        shadowColor: '#000',
        shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
        shadowOpacity: .16,
        shadowRadius: 4 / PixelRatio.get(),
        elevation: 1
    },
    scrollViewWrapper: {
        width: '100%',
        height: '85%',
        alignItems: 'center'
    },
    buttonText: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 12,
        fontWeight: '900',
        textAlign: "center",
        textTransform: 'uppercase'
    },
    selectionContent: {
        height: 'auto',
        width: '100%',
        marginTop: '1%',
        alignItems: 'center'
    },
    headerWrapper: {
        height: Platform.OS === 'web' ? '20%' : 27,
        width: '95%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: '#cacaca',
        borderBottomWidth: 2 / PixelRatio.get(),
        paddingBottom: Platform.OS === 'web' ? '.5%' : '0%',
    },
    optioncardWrapper: {
        width: '95%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    optionCard: {
        width: 'auto',
        height: 'auto',
        borderRadius: 5,
        borderWidth: 0.5,
        marginRight: '1%',
        marginTop: '1%',
        borderColor: 'black',
        alignContent: 'center',
        alignItems: 'center',
        padding: '1%',
    },
    optionCardActive: {
        width: 'auto',
        height: 'auto',
        borderRadius: 5,
        borderWidth: 0.5,
        marginRight: '1%',
        marginTop: '1%',
        borderColor: '#2bc6ff',
        alignContent: 'center',
        alignItems: 'center',
        padding: '1%',
    },
    optionCardCaption: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center'
    },
    optionCardCaptionActive: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center',
        color: '#2bc6ff'
    },
    checkboxWrapper: {
        height: Platform.OS === 'web' ? '8%' : 'auto',
        width: '30%',
        alignItems: 'center',
        flexDirection: 'row',
        alignContent: "center",
    },
    checkbox: {
        height: '50%',
        width: '8%',
        padding: 3,
        alignItems: 'center',
        alignContent: 'center',
    },
    leftHeaderWrapper: {
        width: Platform.OS === 'web' ? '25%' : '40%',
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'space-between'
    },
    loaderWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        height: '15%',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: '1%'
    },
    searchWrapper: {
        width: '70%',
        height: Platform.OS === 'web' ? '100%' : 28,
        padding: 5,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#cacaca',
        backgroundColor: 'white',
        zIndex: 10,
    },
    searchBar: {
        fontSize: 16,
        width: Platform.OS === 'web' ? '85%' : '75%',
        height: '100%',
        backgroundColor: 'white'
    },
    barCodeWrapper: {
        height: '100%',
        width: '12%',
    },
    barCodeIcon: {
        resizeMode: 'contain',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
    },
    checkBoxText: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 22,
        fontWeight: '100',
        textDecorationLine: "none",
    },
    loopIconWrapper: {
        width: '10%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loopIcon: {
        resizeMode: 'contain',
        height: '80%',
        width: '80%',
        alignSelf: 'center',
    },
    scrollIconWrapper: {
        width: '20%',
        height: '7%',
        position: 'absolute',
        bottom: -20,
        opacity: .6
    },
    scrollIcon: {
        resizeMode: 'contain',
        height: '100%',
        width: '100%',
    }
});
