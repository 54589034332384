import React from "react";
import { StyleSheet, Text, View } from "react-native";
import MeasurementCard from "./MeasurementCard";
import MeasurementScale from "./MeasurementScale";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";

type Props = {
  panelId: string;
  BasicMeasurements: {
    left: {
      length: number | undefined;
      width: number | undefined;
      widthHeel: number | undefined;
      heightInstepPoint: number | undefined;
      widthCategory: number | undefined;
    };
    right: {
      length: number | undefined;
      width: number | undefined;
      widthHeel: number | undefined;
      heightInstepPoint: number | undefined;
      widthCategory: number | undefined;
    };
  };
};

const MeasurementsPanel = ({ panelId, BasicMeasurements }: Props) => {
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [language] = useGlobalState("language");

  const i18n = I18N(language);
  const feetMeasurement: any = {};
  const feetMeasurementKeys = ["length", "width", "widthCategory"];
  const moreMeasurements: any = {};
  const moreMeasurementKeys = ["widthHeel", "heightInstepPoint"];

  feetMeasurementKeys.forEach((measure: any) => {
    if (measure !== "widthCategory")
      feetMeasurement[measure] = {
        name: i18n.t(`MeasurementScale.${measure}`),
        unit: i18n.t("MeasurementScale.cm"),
        values: { left: BasicMeasurements.left[measure], right: BasicMeasurements.right[measure] },
      };
  });

  moreMeasurementKeys.forEach((measurement: any) => {
    moreMeasurements[measurement] = {
      name: i18n.t(`MeasurementScale.${measurement}`),
      unit: i18n.t("MeasurementScale.cm"),
      values: { left: BasicMeasurements.left[measurement], right: BasicMeasurements.right[measurement] },
    };
  });

  const getValues = (key: string) => {
    return {
      ...(currentMeasurements
        ? currentMeasurements.measurements[0].side === "Left"
          ? {
              left: currentMeasurements.measurements[0].categorized_measurements[key].category,
              right: currentMeasurements.measurements[1].categorized_measurements[key].category,
            }
          : {
              left: currentMeasurements.measurements[1].categorized_measurements[key].category,
              right: currentMeasurements.measurements[0].categorized_measurements[key].category,
            }
        : { left: null, right: null }),
    };
  };

  const archIndexPlantarValues = ["flat", "normal", "high"];
  const archHeightValues = ["flat", "normal", "high"];
  const heelAngleTendonValues = ["pronation", "normal", "supination"];

  const mapFootTypeCategories = () => {
    let values = { left: 0, right: 0 };
    // TODO: remove archHeightIndex & uncomment values once the changes are done in the algorithm.
    const archHeightIndexLeft = currentMeasurements && currentMeasurements.measurements[0].categorized_measurements.arch_height_index;
    const archHeightIndexRight = currentMeasurements && currentMeasurements.measurements[1].categorized_measurements.arch_height_index;
    // const archIndexPlantarLeft = currentMeasurements && currentMeasurements.measurements[0].categorized_measurements.arch_index_plantar;
    // const archIndexPlantarRight = currentMeasurements && currentMeasurements.measurements[1].categorized_measurements.arch_index_plantar;
    // const archIndexMeshLeft = currentMeasurements && currentMeasurements.measurements[0].categorized_measurements.arch_index_mesh;
    // const archIndexMeshRight = currentMeasurements && currentMeasurements.measurements[1].categorized_measurements.arch_index_mesh;

    if (currentMeasurements) {
      if (archHeightIndexLeft && archHeightIndexLeft.category && archHeightIndexLeft.category !== "") {
        archIndexPlantarValues.map((value: string) => {
          if (value === archHeightIndexLeft.category) {
            values.left = archIndexPlantarValues.indexOf(value);
          }
        });
      } 
      // else if (archIndexPlantarLeft && archIndexPlantarLeft.category && archIndexPlantarLeft.category !== "") {
      //   archIndexPlantarValues.map((value: string) => {
      //     if (value === archIndexPlantarLeft.category) {
      //       values.left = archIndexPlantarValues.indexOf(value);
      //     }
      //   });
      // } else if (archIndexMeshLeft && archIndexMeshLeft.category && archIndexMeshLeft.category !== "") {
      //   archIndexPlantarValues.map((value: string) => {
      //     if (value === archIndexMeshLeft.category) {
      //       values.left = archIndexPlantarValues.indexOf(value);
      //     }
      //   });
      // } 
      else {
        values.left = 1;
      }

      if (archHeightIndexRight && archHeightIndexRight.category && archHeightIndexRight.category !== "") {
        archIndexPlantarValues.map((value: string) => {
          if (value === archHeightIndexRight.category) {
            values.right = archIndexPlantarValues.indexOf(value);
          }
        });
      } 
      // else if (archIndexPlantarRight && archIndexPlantarRight.category && archIndexPlantarRight.category !== "") {
      //   archIndexPlantarValues.map((value: string) => {
      //     if (value === archIndexPlantarRight.category) {
      //       values.right = archIndexPlantarValues.indexOf(value);
      //     }
      //   });
      // } else if (archIndexMeshRight && archIndexMeshRight.category && archIndexMeshRight.category !== "") {
      //   archIndexPlantarValues.map((value: string) => {
      //     if (value === archIndexMeshRight.category) {
      //       values.right = archIndexPlantarValues.indexOf(value);
      //     }
      //   });
      // } 
      else {
        values.right = 1;
      }
    }

    return values;
  };

  const mapHeelAngleCategories = () => {
    let values = { left: 0, right: 0 };
    const heelAngleTendonLeft = currentMeasurements && currentMeasurements.measurements[0].categorized_measurements.heel_angle_tendon;
    const heelAngleTendonRight = currentMeasurements && currentMeasurements.measurements[1].categorized_measurements.heel_angle_tendon;

    if (currentMeasurements) {
      if (heelAngleTendonLeft && heelAngleTendonLeft.category !== "") {
        heelAngleTendonValues.map((value: string) => {
          if (value === heelAngleTendonLeft.category) {
            values.left = heelAngleTendonValues.indexOf(value);
          }
        });
      } else {
        // set 1 for neutral if value does not exist
        values.left = 1;
      }
      if (heelAngleTendonRight && heelAngleTendonRight.category !== "") {
        heelAngleTendonValues.map((value: string) => {
          if (value === heelAngleTendonRight.category) {
            values.right = heelAngleTendonValues.indexOf(value);
          }
        });
      } else {
        // set 1 for neutral if value does not exist
        values.right = 1;
      }
    }
    return values;
  };

  const mapArchHeightCategories = () => {
    let values = { left: 0, right: 0 };
    const archHeightLeft = currentMeasurements && currentMeasurements.measurements[0].categorized_measurements.arch_height;
    const archHeightRight = currentMeasurements && currentMeasurements.measurements[1].categorized_measurements.arch_height;

    if (currentMeasurements) {
      if (archHeightLeft && archHeightLeft.category !== "") {
        archHeightValues.map((value: string) => {
          if (value === archHeightLeft.category) {
            values.left = archHeightValues.indexOf(value);
          }
        });
      } else {
        // set 1 for neutral if value does not exist
        values.left = 1;
      }
      if (archHeightRight && archHeightRight.category !== "") {
        archHeightValues.map((value: string) => {
          if (value === archHeightRight.category) {
            values.right = archHeightValues.indexOf(value);
          }
        });
      } else {
        // set 1 for neutral if value does not exist
        values.right = 1;
      }
    }
    return values;
  };

  return (
    <View style={styles.panel}>
      {panelId && panelId === "analysis" && (
        <View style={styles.panelContent}>
          <MeasurementScale
            caption={i18n.t("MeasurementScale.foot_type")}
            valueCount={3}
            categories={[
              { name: i18n.t("MeasurementScale.flat"), value: 0 },
              { name: i18n.t("MeasurementScale.normal"), value: 1 },
              { name: i18n.t("MeasurementScale.high"), value: 2 },
            ]}
            values={mapFootTypeCategories()}
          />

          <MeasurementScale
            caption={i18n.t("MeasurementScale.heel_angle")}
            valueCount={3}
            categories={[
              { name: i18n.t("MeasurementScale.pronation"), value: 0 },
              { name: i18n.t("MeasurementScale.neutral"), value: 1 },
              { name: i18n.t("MeasurementScale.supination"), value: 2 },
            ]}
            values={mapHeelAngleCategories()}
          />

          <MeasurementScale
            caption={i18n.t("MeasurementScale.arch_height")}
            valueCount={3}
            categories={[
              { name: i18n.t("MeasurementScale.low"), value: 0 },
              { name: i18n.t("MeasurementScale.normal"), value: 1 },
              { name: i18n.t("MeasurementScale.high"), value: 2 },
            ]}
            values={mapArchHeightCategories()}
          />
        </View>
      )}
      {panelId && panelId === "measurements" && (
        <View style={styles.panelContent}>
          {feetMeasurement &&
            Object.keys(feetMeasurement).map((measure) => (
              <MeasurementCard key={measure} name={feetMeasurement[measure].name} unit={feetMeasurement[measure].unit} values={feetMeasurement[measure].values} />
            ))}
          <MeasurementScale
            caption={i18n.t("MeasurementScale.foot_width")}
            valueCount={5}
            categories={[
              { name: i18n.t("MeasurementScale.narrow"), value: 0 },
              { name: i18n.t("MeasurementScale.wide"), value: 4 },
            ]}
            values={{ left: BasicMeasurements.left.widthCategory - 1, right: BasicMeasurements.right.widthCategory - 1 }}
          />
        </View>
      )}
      {panelId && panelId === "more" && (
        <View style={styles.panelContent}>
          {moreMeasurements &&
            Object.keys(moreMeasurements).map((measure: any) => (
              <MeasurementCard key={measure} name={moreMeasurements[measure].name} unit={moreMeasurements[measure].unit} values={moreMeasurements[measure].values} />
            ))}
        </View>
      )}
    </View>
  );
};

export default MeasurementsPanel;

const styles = StyleSheet.create({
  panel: {
    height: "100%",
  },
  panelContent: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
});
