export const Theme = {
  onefidColors: {
    white: {
      color: "#ffffff"
    },
    black: {
      color: "#000000"
    },
    darkGray0B: {
      color: "#0b0b0b"
    },
    darkGray3a: {
      color: "#3a3a3a"
    },
    error: {
      color: "#ff3434"
    },
    mainColorLight: {
      color: "#2bc6ff"
    },
    mainColorDark: {
      color: "#041b3b"
    },
    mainColorDarkHover: {
      color: "#0f3259"
    },
    mainColorNavy: {
      color: "#041B3B"
    }
  },
  ssvColors: {
    white: {
      color: "#ffffff"
    },
    black: {
      color: "#000000"
    },
    darkGray0B: {
      color: "#0b0b0b"
    },
    darkGray3a: {
      color: "#3a3a3a"
    },
    error: {
      color: "#ff3434"
    },
    mainColorLight: {
      color: "#e20031"
    },
    mainColorDark: {
      color: "#4b4b4d"
    },
    mainColorDarkHover: {
      color: "#4d4d50"
    },
    mainColorNavy: {
      color: "#041B3B"
    },
  },
  steitzColors: {
    white: {
      color: "#ffffff"
    },
    black: {
      color: "#000000"
    },
    darkGray0B: {
      color: "#0b0b0b"
    },
    darkGray3a: {
      color: "#4b4b4e"
    },
    error: {
      color: "#ff3434"
    },
    mainColorLight: {
      color: "#bc0f43"
    },
    mainColorDark: {
      color: "#4b4b4d"
    },
    mainColorDarkHover: {
      color: "#4d4d50"
    },
    mainColorNavy: {
      color: "#041B3B"
    }
  },
  eltenColors: {
    white: {
      color: "#ffffff"
    },
    black: {
      color: "#000000"
    },
    darkGray0B: {
      color: "#0b0b0b"
    },
    darkGray3a: {
      color: "#3a3a3a"
    },
    error: {
      color: "#ff3434"
    },
    mainColorLight: {
      color: "#dd0b2e"
    },
    mainColorDark: {
      color: "#000000"
    },
    mainColorDarkHover: {
      color: "#dd0b2e"
    },
    mainColorNavy: {
      color: "#dd0b2e"
    },
  },
  hofmannColors: {
    white: {
      color: "#ffffff"
    },
    black: {
      color: "#000000"
    },
    darkGray0B: {
      color: "#0b0b0b"
    },
    darkGray3a: {
      color: "#3a3a3a"
    },
    error: {
      color: "#ff3434"
    },
    mainColorLight: {
      color: "#FF7000"
    },
    mainColorDark: {
      color: "#323232"
    },
    mainColorDarkHover: {
      color: "#FF7000"
    },
    mainColorNavy: {
      color: "#FF7000"
    },
  }
};
