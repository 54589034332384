import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { Icons, background } from "../../assets";
import { Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import TextTitle from "../../components/Text/TextTitle";
import _ from "lodash";
import { addItemToCart, doesCartTypeExist, getCartIdByType } from "../Order/cartFunctions";
import { Carts } from "../../shared/model/types";

const OrthopedicShoesOptionsModal: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  // State variables
  const [Colors] = useGlobalState("colors");
  const [legLengthCompensation15LeftCheckBox, setLegLengthCompensation15LeftCheckBox] = useState<boolean>(false);
  const [legLengthCompensation15RightCheckBox, setLegLengthCompensation15RightCheckBox] = useState<boolean>(false);
  const [legLengthCompensation15LeftTextInput, setLegLengthCompensation15LeftTextInput] = useState<string>("");
  const [legLengthCompensation15RightTextInput, setLegLengthCompensation15RightTextInput] = useState<string>("");
  const [legLengthCompensation3LeftCheckBox, setLegLengthCompensation3LeftCheckBox] = useState<boolean>(false);
  const [legLengthCompensation3RightCheckBox, setLegLengthCompensation3RightCheckBox] = useState<boolean>(false);
  const [legLengthCompensation3LeftTextInput, setLegLengthCompensation3LeftTextInput] = useState<string>("");
  const [legLengthCompensation3RightTextInput, setLegLengthCompensation3RightTextInput] = useState<string>("");
  const [inOutEdgeElevationLeftCheckBox, setInOutEdgeElevationLeftCheckBox] = useState<boolean>(false);
  const [inOutEdgeElevationRightCheckBox, setInOutEdgeElevationRightCheckBox] = useState<boolean>(false);
  const [inOutEdgeElevationLeftTextInput, setInOutEdgeElevationLeftTextInput] = useState<string>("");
  const [inOutEdgeElevationRightTextInput, setInOutEdgeElevationRightTextInput] = useState<string>("");
  const [corkWedgeLeftCheckBox, setCorkWedgeLeftCheckBox] = useState<boolean>(false);
  const [corkWedgeRightCheckBox, setCorkWedgeRightCheckBox] = useState<boolean>(false);
  const [corkWedgeLeftTextInput, setCorkWedgeLeftTextInput] = useState<string>("");
  const [corkWedgeRightTextInput, setCorkWedgeRightTextInput] = useState<string>("");
  const [haglundFerseLeftCheckBox, setHaglundFerseLeftCheckBox] = useState<boolean>(false);
  const [haglundFerseRightCheckBox, setHaglundFerseRightCheckBox] = useState<boolean>(false);
  const [haglundFerseLeftTextInput, setHaglundFerseLeftTextInput] = useState<string>("");
  const [haglundFerseRightTextInput, setHaglundFerseRightTextInput] = useState<string>("");
  const [wheelingSoleCheckBox, setWheelingSoleCheckBox] = useState<boolean>(false);
  const [butterflyRollCheckBox, setButterflyRollCheckBox] = useState<boolean>(false);
  const [wheelingSoleTextInput, setWheelingSoleTextInput] = useState<string>("");
  const [butterflyRollTextInput, setButterflyRollTextInput] = useState<string>("");

  // Other necessary state and functions
  const [language] = useGlobalState("language");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const [carts, setCarts] = useGlobalState("carts");
  const i18n = I18N(language);
  const navigation = useNavigation();

  const shoeOptions: any = [
    {
      value: "legLengthCompensation1,5cm",
      checkBoxLeft: legLengthCompensation15LeftCheckBox,
      checkBoxRight: legLengthCompensation15RightCheckBox,
      setCheckBoxLeft: setLegLengthCompensation15LeftCheckBox,
      setCheckBoxRight: setLegLengthCompensation15RightCheckBox,
      setTextInputLeft: setLegLengthCompensation15LeftTextInput,
      setTextInputRight: setLegLengthCompensation15RightTextInput,
    },
    {
      value: "legLengthCompensation3cm",
      checkBoxLeft: legLengthCompensation3LeftCheckBox,
      checkBoxRight: legLengthCompensation3RightCheckBox,
      setCheckBoxLeft: setLegLengthCompensation3LeftCheckBox,
      setCheckBoxRight: setLegLengthCompensation3RightCheckBox,
      setTextInputLeft: setLegLengthCompensation3LeftTextInput,
      setTextInputRight: setLegLengthCompensation3RightTextInput,
    },
    {
      value: "inOutEdgeElevation",
      checkBoxLeft: inOutEdgeElevationLeftCheckBox,
      checkBoxRight: inOutEdgeElevationRightCheckBox,
      setCheckBoxLeft: setInOutEdgeElevationLeftCheckBox,
      setCheckBoxRight: setInOutEdgeElevationRightCheckBox,
      setTextInputLeft: setInOutEdgeElevationLeftTextInput,
      setTextInputRight: setInOutEdgeElevationRightTextInput,
    },
    {
      value: "corkWedge",
      checkBoxLeft: corkWedgeLeftCheckBox,
      checkBoxRight: corkWedgeRightCheckBox,
      setCheckBoxLeft: setCorkWedgeLeftCheckBox,
      setCheckBoxRight: setCorkWedgeRightCheckBox,
      setTextInputLeft: setCorkWedgeLeftTextInput,
      setTextInputRight: setCorkWedgeRightTextInput,
    },
    {
      value: "haglundFerse",
      checkBoxLeft: haglundFerseLeftCheckBox,
      checkBoxRight: haglundFerseRightCheckBox,
      setCheckBoxLeft: setHaglundFerseLeftCheckBox,
      setCheckBoxRight: setHaglundFerseRightCheckBox,
      setTextInputLeft: setHaglundFerseLeftTextInput,
      setTextInputRight: setHaglundFerseRightTextInput,
    },
  ];

  function getAppliancesIdFromShoe(carts: Carts): string | undefined {
    // Loop through all the carts in the object
    for (const cartId in carts) {
      const cart = carts[cartId];

      // Check if the cart's type is "elten-ortho"
      if (cart.type === "elten-ortho" && cart.items) {
        // Loop through the items in the cart to find the one with type "shoe"
        for (const item of cart.items) {
          if (item.type === "shoe" && item.appliances_id) {
            return item.appliances_id;
          }
        }
      }
    }

    // If no matching cart/item is found, return undefined
    return undefined;
  }

  const handleSubmission = () => {
    const conditions = [
      { check: legLengthCompensation15LeftCheckBox, code: "4030839382899", text: legLengthCompensation15LeftTextInput, value: "legLengthCompensation1,5cm", side: "left" },
      { check: legLengthCompensation15RightCheckBox, code: "4030839382882", text: legLengthCompensation15RightTextInput, value: "legLengthCompensation1,5cm", side: "right" },
      { check: legLengthCompensation3LeftCheckBox, code: "4030839382912", text: legLengthCompensation3LeftTextInput, value: "legLengthCompensation3cm", side: "left" },
      { check: legLengthCompensation3RightCheckBox, code: "4030839382905", text: legLengthCompensation3RightTextInput, value: "legLengthCompensation3cm", side: "right" },
      {
        check: inOutEdgeElevationLeftCheckBox && inOutEdgeElevationRightCheckBox,
        code: "4030839382943",
        text: `Links: ${inOutEdgeElevationLeftTextInput} , Rechts: ${inOutEdgeElevationRightTextInput}`,
        value: "inOutEdgeElevation",
        side: "Both",
      },
      {
        check: inOutEdgeElevationLeftCheckBox && !inOutEdgeElevationRightCheckBox,
        code: "4030839382967",
        text: inOutEdgeElevationLeftTextInput,
        value: "inOutEdgeElevation",
        side: "left",
      },
      {
        check: !inOutEdgeElevationLeftCheckBox && inOutEdgeElevationRightCheckBox,
        code: "4030839382950",
        text: inOutEdgeElevationRightTextInput,
        value: "inOutEdgeElevation",
        side: "right",
      },
      { check: corkWedgeLeftCheckBox, code: "4030839382981", text: corkWedgeLeftTextInput, value: "corkWedge", side: "left" },
      { check: corkWedgeRightCheckBox, code: "4030839382974", text: corkWedgeRightTextInput, value: "corkWedge", side: "right" },
      {
        check: haglundFerseLeftCheckBox && haglundFerseRightCheckBox,
        code: "4030839382998",
        text: `Links: ${haglundFerseLeftTextInput} , Rechts: ${haglundFerseRightTextInput}`,
        value: "haglundFerse",
        side: "Both",
      },
      {
        check: haglundFerseLeftCheckBox && !haglundFerseRightCheckBox,
        code: "4030839383018",
        text: haglundFerseLeftTextInput,
        value: "haglundFerse",
        side: "left",
      },
      {
        check: !haglundFerseLeftCheckBox && haglundFerseRightCheckBox,
        code: "4030839383001",
        text: haglundFerseRightTextInput,
        value: "haglundFerse",
        side: "right",
      },
      { check: wheelingSoleCheckBox, code: "4030839382929", text: wheelingSoleTextInput, value: "wheelingSole", side: "Both" },
      { check: butterflyRollCheckBox, code: "4030839382936", text: butterflyRollTextInput, value: "butterflyRoll", side: "Both" },
    ];

    const relevantConditions = conditions.filter((condition) => condition.check);

    if (relevantConditions.length > 0) {
      if (doesCartTypeExist(carts, "elten-ortho")) {
        const orthoCartUUID = getCartIdByType(carts, "elten-ortho");
        addItemToCart(setCarts, orthoCartUUID, { type: "shoe", gtin: selectedShoe.gtin, itemName: selectedShoe.name, itemIcon: selectedShoe.image_url });

        relevantConditions.forEach((condition) => {
          let itemName: string;

          if (condition.side === "Both") {
            itemName = i18n.t(condition.value);
          } else {
            itemName = `${i18n.t(`MeasurementScale.${condition.side}`)}: ${i18n.t(condition.value)}`;
          }

          const data = {
            type: "shoe-adaption",
            additional_product_gtin: condition.code,
            comments: condition.text,
            appliances_id: getAppliancesIdFromShoe(carts),
            itemName,
            itemIcon: Icons.soleIcon,
          };

          addItemToCart(setCarts, orthoCartUUID, data);
          // setIsSuccessful(true);
          navigation.navigate("CartModal");
        });
      }
    } else {
      // setIsSuccessful(true);
      navigation.navigate("CartModal");
    }
  };

  // useEffect(() => {
  //   if (isSuccessful) {
  //     navigation.navigate("CartModal");
  //   }
  // }, [isSuccessful]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          <View style={styles.formWrapper}>
            <Text style={[styles.titleText, { marginBottom: "1%" }]}>{i18n.t("shoeAdaptionsModalTitle")}</Text>
            <View style={styles.leftRightTextWrapper}>
              <View style={{ width: "35%" }} />
              <View style={styles.leftRightText}>
                <Text style={styles.titleText}>{i18n.t("scan.left")}</Text>
                <Text style={styles.titleText}>{i18n.t("scan.right")}</Text>
              </View>
            </View>
            <View style={styles.optionsViewWrapper}>
              {shoeOptions.map((option) => {
                return (
                  <View style={styles.shoeOptionsWrapper} key={option.value}>
                    <View style={styles.infoView}>
                      <View style={styles.inputsWrapper}>
                        <TextTitle fontSize={12} text={i18n.t(option.value)} />
                      </View>
                    </View>
                    <View style={styles.checkboxView}>
                      <BouncyCheckbox
                        textComponent
                        innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                        fillColor={Colors.mainColorLight.color}
                        isChecked={option.checkBoxLeft}
                        onPress={() => option.setCheckBoxLeft(!option.checkBoxLeft)}
                        size={45}
                      />
                    </View>
                    <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                      <TextInput onChangeText={(text) => option.setTextInputLeft(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                    </View>
                    <View style={styles.checkboxView}>
                      <BouncyCheckbox
                        textComponent
                        innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                        fillColor={Colors.mainColorLight.color}
                        isChecked={option.checkBoxRight}
                        onPress={() => option.setCheckBoxRight(!option.checkBoxRight)}
                        size={45}
                      />
                    </View>
                    <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                      <TextInput onChangeText={(text) => option.setTextInputRight(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                    </View>
                  </View>
                );
              })}
              <View style={styles.shoeOptionsWrapper}>
                <View style={styles.infoView}>
                  <View style={styles.inputsWrapper}>
                    <TextTitle text={i18n.t("wheelingSole")} />
                  </View>
                </View>
                <View style={{ width: "15%", height: "100%" }} />
                <View style={styles.checkboxView}>
                  <BouncyCheckbox
                    textComponent
                    innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                    fillColor={Colors.mainColorLight.color}
                    isChecked={wheelingSoleCheckBox}
                    onPress={() => setWheelingSoleCheckBox(!wheelingSoleCheckBox)}
                    size={45}
                  />
                </View>
                <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                  <TextInput onChangeText={(text) => setWheelingSoleTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                </View>
                <View style={{ width: "15%", height: "100%" }} />
              </View>
              {selectedShoe && !["S1P", "S3"].includes(selectedShoe.safety_class) && (
                <View style={styles.shoeOptionsWrapper}>
                  <View style={styles.infoView}>
                    <View style={styles.inputsWrapper}>
                      <TextTitle text={i18n.t("butterflyRoll")} />
                    </View>
                  </View>
                  <View style={{ width: "15%", height: "100%" }} />
                  <View style={styles.checkboxView}>
                    <BouncyCheckbox
                      textComponent
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={butterflyRollCheckBox}
                      onPress={() => setButterflyRollCheckBox(!butterflyRollCheckBox)}
                      size={45}
                    />
                  </View>
                  <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                    <TextInput onChangeText={(text) => setButterflyRollTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                  </View>
                  <View style={{ width: "15%", height: "100%" }} />
                </View>
              )}
            </View>
            <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color, alignSelf: "flex-end" }]} onPress={handleSubmission}>
              <Text style={styles.buttonText}>{i18n.t("createProcess")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    width: "75%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "100%",
    height: "100%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  inputsWrapper: {
    width: "100%",
    height: "100%",
    paddingLeft: "1%",
    paddingRight: "1%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
    fontSize: 72,
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  optionsViewWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "85%",
  },
  infoView: {
    width: "25%",
    height: "90%",
  },
  textInput: {
    width: "99%",
    height: "99%",
  },
  checkboxView: {
    width: "7%",
    height: "auto",
    alignItems: "center",
  },
  leftRightTextWrapper: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "5%",
    flexDirection: "row",
  },
  leftRightText: {
    width: "65%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  shoeOptionsWrapper: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "14%",
    flexDirection: "row",
  },
});

export default OrthopedicShoesOptionsModal;
