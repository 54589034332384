import React from 'react';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';
import { background, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Fonts, Styles } from '../../styles';
import BelowForm from './BelowForm';

const RegisterConfirmation: React.FC = (): JSX.Element => {
  const [language] = useGlobalState('language');
  const [currentScanner] = useGlobalState('currentScanner');
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);
  
  const title: string = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion') ? 'ShoepassionID' : i18n.t('auth.register')
  const succesMessage: string = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion') ? i18n.t('auth.shoepassionSuccesRegisterMessage') : i18n.t('auth.succesRegisterMessage')
  const confirmRegisterMessage: string = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion') ? i18n.t('auth.shoepassionConfirmRegisterMessage') : i18n.t('auth.confirmRegisterMessage')
  const EmaiIcon: any = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion') ? Icons.confirmEmailShoepassion : Icons.confirmEmailIcon
  
  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo>
        <View style={styles.contentWrapper}>
          <Text style={styles.titeText}>{title}</Text>
          <Image style={styles.imageContainer} source={EmaiIcon} />

          <Text style={[styles.successText, Fonts.mLight, Colors.black, Fonts.center]}>
            {succesMessage[0]}
            <Text style={Fonts.mBlack}>{succesMessage[1]}</Text>{' '}
            {succesMessage[2]}
          </Text>

          <Text style={[styles.confirmText, Fonts.text, Colors.black, Fonts.center]}>
            {confirmRegisterMessage[0]}{' '}
            <Text style={Fonts.mBlack}>{confirmRegisterMessage[1]}</Text>{' '}
            {confirmRegisterMessage[2]}{' '}
            <Text style={Fonts.mBlack}>{confirmRegisterMessage[3]}</Text>{' '}
          </Text>
        </View>
        <BelowForm />
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default RegisterConfirmation;

const styles = StyleSheet.create({
  contentWrapper: {
    width: '45%',
    height: '75%',
    alignSelf: 'center',
    justifyContent: 'space-evenly'
  },
  imageContainer: {
    resizeMode: 'contain',
    height: 100,
    width: 150,
    alignSelf: 'center'
  },
  successText: {
    fontSize: 39,
    fontWeight: '300',
    lineHeight: 50,
  },
  confirmText: {
    lineHeight: 20,
    paddingLeft: 75,
    paddingRight: 75,
  },
  titeText: {
    fontFamily: 'Montserrat-Black',
    textAlign: 'center',
    fontSize: 21,
    fontWeight: '900',
    textTransform: 'uppercase'
  }
});
