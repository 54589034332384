import React from 'react';
import { PixelRatio, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Fonts } from '../../styles';
import { I18N } from '../../shared/util';
import { useGlobalState } from '../../shared/state';


type Props = {
  tabId: string
  caption: string
  isActive: boolean
  onClickHandler: (id: string) => void
}


const MeasurementsTab: React.FC<Props> = ({ caption, isActive, tabId, onClickHandler }: Props): JSX.Element => {
  const [language] = useGlobalState('language');

  const i18n = I18N(language);
  const style: any[] = [ styles.tabHeader ];
  if (isActive) style.push(styles.tabHeaderActive);

  return <TouchableOpacity onPress={() => onClickHandler(tabId)} style={style} activeOpacity={.6}>
    <Text style={styles.captionText}>{i18n.t(caption).toUpperCase()}</Text>
  </TouchableOpacity>;
};

export default MeasurementsTab;

const styles = StyleSheet.create({
  tabHeader: {
    flex: 1,
    flexGrow: 5,
    backgroundColor: 'rgba(255, 255, 255, .71)',
    height: '100%',
    width: '100%',
    borderBottomColor: '#cacaca',
    borderBottomWidth: 2 / PixelRatio.get(),
    borderLeftColor: '#cacaca',
    borderLeftWidth: 2 / PixelRatio.get(),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabHeaderActive: {
    backgroundColor: 'rgba(240, 240, 240, .71)',
  },
  captionText: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 8,
    textAlign: 'center',
    fontWeight: '600'
  }
});
