import React, { useState } from 'react';
import { Image, View, StyleSheet, TouchableOpacity, Text, TouchableWithoutFeedback, Keyboard, PixelRatio } from 'react-native';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Fonts, Styles } from '../../styles';


const CustomiseProductModal: React.FC = (route): JSX.Element => {
    const { params } = route.route;
    const [language] = useGlobalState('language');
    const [customiseProductProcess, setCustomiseProductProcess] = useState<"WEIGHT" | "ACTIVITY">();
    const [weightValue, setWeightValue] = useState<Number | undefined>(undefined);
    const [activityValue, setActivityValue] = useState<String | undefined>(undefined);
    const [Colors] = useGlobalState('colors');

    const i18n = I18N(language);
    const navigation = useNavigation();
    const gtin = params.GTIN;
    const weightOption = [
        { category: i18n.t('CustomiseProduct.weight.upto65Kg'), value: 60 },
        { category: '65-75 Kg', value: 70 }, { category: '75-85 Kg', value: 80 },
        { category: '85-95 Kg', value: 90 }, { category: '95-110 Kg', value: 100 },
        { category: i18n.t('CustomiseProduct.weight.from110Kg'), value: 111 }
    ]
    const activityOption = [
        { category: i18n.t('CustomiseProduct.activity.mostlyStanding'), value: 'mostly-standing' },
        { category: i18n.t('CustomiseProduct.activity.mostlyWalking'), value: 'mostly-walking' },
        { category: i18n.t('CustomiseProduct.activity.mostlySitting'), value: 'mostly-sitting' },
    ]

    const customiseProductConfirm = () => {
        return (
            <View style={styles.content}>
                <View style={styles.iconsContainer}>
                    <Image style={styles.iconWrapper} source={Icons.customizeIcon} />
                </View>
                <Text style={[Fonts.textBold, Fonts.center]}>{i18n.t('CustomiseProduct.confirmCustomisation')}</Text>
                <View style={{ width: '100%', height: '35%', justifyContent: 'space-evenly' }}>
                    <TouchableOpacity style={Styles.submitButtonDark} onPress={() => setCustomiseProductProcess('WEIGHT')} >
                        <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t('yes')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.replace('OrderModal', { GTIN: gtin })}>
                        <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('no')}</Text>
                    </TouchableOpacity>
                </View>
            </View>)
    }

    const selectWeight = () => {
        return (
            <View style={styles.selectionContent}>
                <View style={styles.headerWrapper}>
                    <Text style={Fonts.textHuge}>{i18n.t('weight')}</Text>
                    <View style={styles.selectioniconContainer}>
                        <Image style={styles.iconWrapper} source={Icons.balanceIcon} />
                    </View>
                </View>
                <View style={styles.weightcardWrapper}>
                    {weightOption.map((option: any, index: number) => (
                        <TouchableOpacity style={weightValue === option.value ? [styles.weightoptionCardActive, { borderColor: Colors.mainColorLight.color }] : styles.weightoptionCard} key={index} onPress={() => setWeightValue(option.value)}>
                            <Text style={weightValue === option.value ? [styles.optionCardCaptionActive, { color: Colors.mainColorLight.color }] : styles.optionCardCaption}>{option.category}</Text>
                        </TouchableOpacity>))}
                </View>
                {!weightValue && <TouchableOpacity style={Styles.submitButtonLight} onPress={() => setCustomiseProductProcess(undefined)}>
                    <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('back')}</Text>
                </TouchableOpacity>}
                {weightValue && <TouchableOpacity style={Styles.submitButtonDark} onPress={() => setCustomiseProductProcess('ACTIVITY')} >
                    <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t('next')}</Text>
                </TouchableOpacity>}
            </View >
        )
    }

    const selectActivity = () => {
        return (
            <View style={styles.selectionContent}>
                <View style={styles.headerWrapper}>
                    <Text style={Fonts.textHuge}>{i18n.t('activity')}</Text>
                    <View style={styles.selectioniconContainer}>
                        <Image style={styles.iconWrapper} source={Icons.activityIcon} />
                    </View>
                </View>
                <View style={styles.activitycardWrapper}>
                    {activityOption.map((option: any, index: number) => (
                        <TouchableOpacity style={activityValue === option.value ? [styles.activityoptionCardActive, { borderColor: Colors.mainColorLight.color }] : styles.activityoptionCard} key={index} onPress={() => setActivityValue(option.value)}>
                            <Text style={activityValue === option.value ? [styles.optionCardCaptionActive, { color: Colors.mainColorLight.color }] : styles.optionCardCaption}>{option.category}</Text>
                        </TouchableOpacity>
                    ))}
                </View>
                {!activityValue && <TouchableOpacity style={Styles.submitButtonLight} onPress={() => [setCustomiseProductProcess('WEIGHT'), setWeightValue(undefined)]}>
                    <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('back')}</Text>
                </TouchableOpacity>}

                {activityValue && <TouchableOpacity style={Styles.submitButtonDark} onPress={() => navigation.replace('OrderModal', { GTIN: gtin, weight: weightValue, activity: activityValue })} >
                    <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t('next')}</Text>
                </TouchableOpacity>}
            </View >
        )
    }

    return (
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
            <View style={styles.wrappercontainer}>
                <View style={styles.modalWrapper}>
                    <TouchableOpacity style={styles.closeButton} onPress={() => navigation.goBack(null)}>
                        <Image source={Icons.closeIconSquare} style={styles.iconContainer} />
                    </TouchableOpacity>
                    <View style={styles.container}>
                        {!customiseProductProcess && customiseProductConfirm()}
                        {customiseProductProcess === 'WEIGHT' && selectWeight()}
                        {customiseProductProcess === 'ACTIVITY' && selectActivity()}
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>);
};

export default CustomiseProductModal;

const styles = StyleSheet.create({
    wrappercontainer: {
        flex: 1,
        flexDirection: 'column',
        marginTop: '14%',
        marginRight: '11%',
    },
    modalWrapper: {
        width: '30%',
        height: '64%',
        alignSelf: 'flex-end',
        flexDirection: 'column',
        backgroundColor: 'white',
        justifyContent: 'space-evenly',
    },
    closeButton: {
        width: '15%',
        position: 'absolute',
        right: 0,
        top: '-19%',
        zIndex: 10
    },
    iconContainer: {
        width: '100%',
        resizeMode: 'contain'
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '70%',
        height: '100%',
        justifyContent: 'space-around',
        backgroundColor: 'white',
    },
    iconsContainer: {
        width: '50%',
        height: '25%',
        alignSelf: 'center'
    },
    iconWrapper: {
        flex: 1,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    button: {
        width: 150,
        height: 38,
        top: '5%',
        borderRadius: 17,
        borderColor: '#707070',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(255, 255, 255, .5)'
    },
    buttonText: {
        ...Fonts.mRegular,
        ...Fonts.size12,
        ...Fonts.center,
        textTransform: 'uppercase'
    },
    selectionContent: {
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        marginTop: '30%',
        alignItems: 'center'
    },
    headerWrapper: {
        height: '20%',
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: '#cacaca',
        borderBottomWidth: 2 / PixelRatio.get(),
        paddingTop: '10%',
    },
    selectioniconContainer: {
        width: '80%',
        height: '80%',
        left: "100%",
        alignItems: 'flex-end'
    },
    weightcardWrapper: {
        width: '90%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: '1%',
    },
    activitycardWrapper: {
        width: '90%',
        height: '60%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        paddingTop: '1%',
    },
    weightoptionCard: {
        width: '45%',
        height: '27%',
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: 'black',
        justifyContent: 'center',
        marginTop: '5%'
    },
    weightoptionCardActive: {
        width: '45%',
        height: '27%',
        borderRadius: 5,
        borderWidth: 0.5,
        justifyContent: 'center',
        marginTop: '5%'
    },
    activityoptionCard: {
        width: '70%',
        height: '20%',
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: 'black',
        justifyContent: 'center',
        marginTop: '5%'
    },
    activityoptionCardActive: {
        width: '70%',
        height: '20%',
        borderRadius: 5,
        borderWidth: 0.5,
        justifyContent: 'center',
        marginTop: '5%'
    },
    optionCardCaption: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center'
    },
    optionCardCaptionActive: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center'
    },
    headerTitle: {
        fontFamily: 'Montserrat-Black',
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
        alignSelf: 'center',
    },

});