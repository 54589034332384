import React from 'react';
import { ImageBackground, PixelRatio, StyleSheet, View } from 'react-native';
import { background } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import RadioButton from '../../components/RadioButtonGroup/RadioButton';

const contienetsList = [
    {
        key: 'north-america',
        text: 'North America',
    },
    {
        key: 'south-america',
        text: 'South America',
    },
    {
        key: 'europe',
        text: 'Europe',
    },
    {
        key: 'africa',
        text: 'Africa',
    },
    {
        key: 'asia',
        text: 'Asia',
    },
    {
        key: 'australia',
        text: 'Australia',
    },
];

const NewScanUserOriginSelection: React.FC = (): JSX.Element => {
    const [, setRoute] = useGlobalState('currentScreen');
    const [, setUserOrigin] = useGlobalState('userOrigin');
    const [language] = useGlobalState('language');

    const i18n = I18N(language);
    const navigation = useNavigation();

    const navigate = (route: string | undefined) => {
        if (route) {
            navigation.navigate(route);
            setRoute(route);
        }
    };

    const onSelect = (choice: any) => {
        setUserOrigin(choice)
        // navigate('NewScanModeSelection')
        navigate('NewScanUserBirthYear')
    };

    return <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} showStartButton={false} hideNewScanButton showBackButton={true} title={i18n.t('newScan.origin')}>
            <View style={styles.contentWrapper}>
                <View style={styles.cardWrapper}>
                    <RadioButton data={contienetsList} onPress={onSelect} />
                </View>
            </View>
        </LayoutWrapper>
    </ImageBackground>;
};

export default NewScanUserOriginSelection;

const styles = StyleSheet.create({
    backgroundImage: {
        resizeMode: 'cover',
        flex: 1,
        backgroundColor: 'white',
    },
    contentWrapper: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardWrapper: {
        flex: 1,
        width: '50%',
        backgroundColor: '#ffffff',
        opacity: .87,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '3%',
        borderRadius: 12 / PixelRatio.get(),
        margin: '1%',
        shadowColor: '#000',
        shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
        shadowOpacity: .16,
        shadowRadius: 4 / PixelRatio.get(),
        elevation: 1

    },
    selectionCaption: {
        fontFamily: 'Montserrat-Black',
        fontSize: 21,
        color: '#3a3a3a',
        marginTop: '3.5%'
    }
});
