import React from 'react';
import { View } from 'react-native';
import { GLView } from 'expo-gl';
import {
  Scene,
  GridHelper,
  PerspectiveCamera,
  WebGLRenderer,
  DirectionalLight,
  AmbientLight,
  PointsMaterial,
  Points,
  MeshPhysicalMaterial,
  Mesh,
  LineSegments
} from 'three';
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import ScannerCoreService from '../../shared/services/core-services';
import { useGlobalState } from '../../shared/state';

type Props = {
  leftPLY?: string,
  leftSole?: string,
  rightPLY?: string,
  rightSole?: string,
  email?: string,
}

const ThreeStage = ({ leftPLY, leftSole, rightPLY, rightSole, email }: Props): JSX.Element => {
  const [userToken] = useGlobalState('userToken');
  const [currentScanner] = useGlobalState('currentScanner');

  function onContextCreate(gl) {
    const scene = new Scene();
    const camera = new PerspectiveCamera(55, gl.drawingBufferWidth / gl.drawingBufferHeight, 0.5, 1500);
    let gridPlane = new GridHelper(500, 10)
    
    // Set up camera position
    camera.position.set(100, 100, 700);
    camera.quaternion.normalize();
    
    const renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas: gl.canvas });
    renderer.setSize(gl.drawingBufferWidth, gl.drawingBufferHeight);
    renderer.setClearColor( 0xffffff, 0 );
    
    const distance = 3000
    const light1 = new DirectionalLight(0xffffff, 1.5)
    light1.position.set(0, 0, distance)
    light1.castShadow = false
    light1.shadow.bias = -0.01;

    const light2 = new DirectionalLight(0xffffff, 1.5)
    light2.position.set(0, 0, -distance)
    light2.castShadow = false
    light2.shadow.bias = -0.01

    const light3 = new DirectionalLight(0xffffff, 1.5)
    light3.position.set(0, distance, 0)
    light3.castShadow = false
    light3.shadow.bias = -0.01;

    const light4 = new DirectionalLight(0xffffff, 1.5)
    light4.position.set(0, -distance, 0)
    light4.castShadow = false
    light4.shadow.bias = -0.01;

    const ambientLight = new AmbientLight(0x808080, 1)
    scene.add(light1)
    scene.add(light2)
    scene.add(light3)
    scene.add(light4)
    scene.add(ambientLight)

    //grid plane
    gridPlane.position.y = -101
    scene.add(gridPlane)

    // Create a loader for PLY files
    const loader = new PLYLoader();
  
    // Load and render left PLY and sole
    LoadPLY(scene, loader, 'left', leftSole, leftPLY);
  
    // Load and render right PLY and sole
    LoadPLY(scene, loader, 'right', rightSole, rightPLY);
    
    // Add OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // an animation loop is required when damping is enabled
    controls.dampingFactor = 0.25; // set to 0.0 to disable damping
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI;
    controls.enableZoom= true;
    controls.minDistance = 450.0
    controls.maxDistance = 950.0
  
    // Create a render loop
    const render = () => {
      requestAnimationFrame(render);
  
      // Render the scene with the camera
      renderer.render(scene, camera);
  
      // Flush the GL context
      gl.endFrameEXP();
    };
  
    // Start the render loop
    render();
  }
  
  // Helper function to load and render PLY files
  const LoadPLY = (scene, loader, side, soleURL, MeshURL) => {
    if (MeshURL) {
      loader.load(MeshURL, (Meshgeometry) => {
        Meshgeometry.computeVertexNormals();
        addMesh(scene, Meshgeometry, side, 'unprocessed');
        if (soleURL) {
          loader.load(soleURL, (Solegeometry) => {
            Solegeometry.computeVertexNormals();
            addSole(scene, Solegeometry, side);
          });
        }
      });
    } else if (email) {
      ScannerCoreService.fetchUserMesh(email, side, 'standing', currentScanner['scanner-token'], userToken)
        .then((data) => {
          loader.load(data.original_data_url, (Meshgeometry) => {
            Meshgeometry.computeVertexNormals()
            addMesh(scene, Meshgeometry, side, 'unprocessed')
            if (currentScanner && currentScanner['scanner-version'] && soleURL)
              loader.load(soleURL, (Solegeometry) => {
                Solegeometry.computeVertexNormals()
                addSole(scene, Solegeometry, side)
              })
          })
        })
        .catch(error => console.log('ERROR LoadPLY: ', error));
    }
  };
  
  // Helper function to add sole to the scene
  const addSole = (scene, Solegeometry: any, side: string) => {
    let SoleMaterial = {
      size: 8,
      fog: true,
      vertexColors: true,
    };

    let pointCloudSoleMaterial = new PointsMaterial(SoleMaterial);
    let meshSole = new Points(Solegeometry, pointCloudSoleMaterial)
    // unprocessed sole position

    if (currentScanner && currentScanner['scanner-version'] === 'new') {
      if(currentScanner && currentScanner['scanner-type'] === 'multi') {
        meshSole.position.z = 3;
        meshSole.position.x = side === 'left' ? - 16 : 16;
      } else {
        meshSole.position.z = -130;
        meshSole.position.x = side === 'left' ? 100 : - 100;
      }
    } else { meshSole.position.x = side === 'left' ? - 100 : 100; }

    meshSole.position.y = -101.5; // up
    meshSole.scale.multiplyScalar(1.3)
    meshSole.castShadow = true;
    meshSole.material.needsUpdate = true;
    meshSole.castShadow = true;
    meshSole.receiveShadow = true;

    scene.add(meshSole)
  }
  
  // Helper function to add mesh to the scene
  const addMesh = (scene, Meshgeometry: any, side: string, status: string) => {
    let MeshMaterial = new MeshPhysicalMaterial({
      color: "#ffffff",
      metalness: 0.3,
      roughness: 0.7,
      reflectivity: 1,

      opacity: 1
    });
    let mesh = new Mesh(Meshgeometry, MeshMaterial)
    if (status === 'unprocessed') {
      // unprocessed mesh position
      if (currentScanner && currentScanner['scanner-version'] === 'new') {
        if (currentScanner && currentScanner['scanner-type'] === 'multi') {
          mesh.position.x = side === 'left' ? - 30 : 30;
        } else {
          mesh.position.z = -150;
          mesh.position.x = side === 'left' ? 100 : - 100;
        }
      } else { 
        mesh.position.x = side === 'left' ? - 100 : 100;
      }

      mesh.position.y = -100; // up
      mesh.scale.multiplyScalar(1.4);
      mesh.castShadow = true;
      mesh.material.needsUpdate = true;
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    }
    else {
      mesh.position.x = side === 'left' ? - 100 : 100;
      mesh.position.y = -100;
      mesh.position.z = -30;
      mesh.rotateX(-Math.PI / 2);
      mesh.rotateZ(-Math.PI / 2);
      mesh.scale.multiplyScalar(1.4);
      mesh.castShadow = true;
      mesh.material.needsUpdate = true;
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    }

    let materialWireFrame = new MeshPhysicalMaterial({
      color: "#ffffff",
      wireframe: true,
      wireframeLinewidth: 10,
      metalness: 0.7,
      roughness: 1,
      opacity: 1,
    });

    var wireframe = new LineSegments(Meshgeometry, materialWireFrame);
    mesh.add(wireframe);
    scene.add(mesh)
  }

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <GLView style={{ width: "100%", height: "100%" }} onContextCreate={onContextCreate} />
    </View>
  );
};

export default ThreeStage;