import { PixelRatio, StyleSheet } from 'react-native';

export const Styles = StyleSheet.create({
  backgroundImage: {
    resizeMode: 'cover',
    flex: 1,
    backgroundColor: 'white',
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  contentWrapperFull: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  formWrapper: {
    width: '50%',
    height: '75%',
    alignSelf: 'center'
  },
  errorBorder: {
    borderColor: '#ff3434',
    borderWidth: .5 / PixelRatio.get(),
  },
  submitButtonLight: {
    width: 195,
    height: 42,
    top: '5%',
    borderRadius: 17,
    borderColor: '#707070',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'rgba(255, 255, 255, .5)',
  },
  submitButtonDark: {
    width: 195,
    height: 42,
    top: '5%',
    borderRadius: 17,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#041b3b'
  },
  card: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderColor: '#cacaca',
    borderWidth:0.5,
    borderRadius: 1,
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    shadowRadius: 4 / PixelRatio.get(),
    elevation: 1,
    backgroundColor: 'white',
  },

});
