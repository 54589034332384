import React, {  useEffect, useState } from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { I18N } from '../../shared/util';
import { useGlobalState } from '../../shared/state';

const { width } = Dimensions.get('window');


const OfflineNotice: React.FC = (): JSX.Element => {
  const [isConnected, setIsConnected] = useState<Boolean>(true);
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  
 useEffect(()=>{
  NetInfo.addEventListener(state => {
    handleConnectivityChange(state.isConnected);
  });
 })


  const handleConnectivityChange = (isConnected) =>     setIsConnected(isConnected)
  


    if (!isConnected) {
      return   ( <View style={styles.offlineContainer}>
      <Text style={styles.offlineText}>{i18n.t('noInternet')}</Text>
    </View>)
    }
    else return <></>;
  }


const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: '#b52424',
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width,
    position: 'absolute',
    top:0
  },
  offlineText: { color: '#fff' }
});

export default OfflineNotice;