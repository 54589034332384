import React from 'react';
import { StyleSheet, View, KeyboardAvoidingView, Platform } from 'react-native';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import I18n from '../../i18nSets/i18n';
import BelowForm from './BelowForm';
import Form from './Form';
import { useGlobalState } from '../../shared/state';


const RegisterModal: React.FC = (): JSX.Element => {
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentScanner] = useGlobalState('currentScanner');

  const title: string = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion')
    ? 'ShoepassionID'
    : currentMeasurements
      ? I18n.t('auth.oneFittingIDCreation')
      : I18n.t('auth.register')

  return (
    <ModalWrapper title={title}>
      <KeyboardAvoidingView behavior="padding" enabled keyboardVerticalOffset={Platform.OS === 'ios' ? -85 : 0} style={styles.container}>
        <View style={styles.container}>
          <View style={styles.formWrapper}>
            <Form />
          </View>
          <BelowForm />
        </View>
      </KeyboardAvoidingView>
    </ModalWrapper >);
}
export default RegisterModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  formWrapper: {
    width: '60%',
    height: '75%',
    alignSelf: 'center'
  }
});
