import { Platform, View } from "react-native";
import React, { ReactNode, useEffect } from "react";
import { StyleSheet, Text } from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from "../../shared/state";
import BackButton from "../Buttons/back-button/BackButton";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Fonts } from "../../styles";
import * as _ from "lodash";
import OfflineNotice from "../Header/OfflineNotice";
import { deleteCarts } from "../../screens/Order/cartFunctions";

type Props = {
  children?: ReactNode;
  showHeaderCloseButton: boolean;
  showHeaderLogo?: boolean;
  showMenuButtons?: boolean;
  showHomeButton?: boolean;
  showStartButton?: boolean;
  showBackButton?: boolean;
  showLanguageButton?: boolean;
  hideLeftButton?: boolean;
  hideNewScanButton?: boolean;
  title?: string | undefined;
  subtitle?: string | undefined;
};

const LayoutWrapper: React.FC<Props> = ({
  children,
  showHeaderCloseButton,
  showHeaderLogo,
  showMenuButtons = true,
  showStartButton = false,
  showBackButton = false,
  showHomeButton = false,
  showLanguageButton = false,
  hideLeftButton = false,
  hideNewScanButton = false,
  title = undefined,
  subtitle = undefined
}: Props): JSX.Element => {
  const [isUserActive] = useGlobalState("isUserActive");
  const [, setCurrentScreen] = useGlobalState("currentScreen");
  const [, setCurrentUser] = useGlobalState("currentUser");
  const [currentMeasurements, setCurrentMeasurements] = useGlobalState("currentMeasurements");
  const [, setMatchedProducts] = useGlobalState("matchedProducts");
  const [, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [, setAllProducts] = useGlobalState("allProducts");
  const [, setCurrentScan] = useGlobalState("currentScan");
  const [, setUserToken] = useGlobalState("userToken");
  const [carts, setCarts] = useGlobalState("carts");

  const [modalIsDisplayed, setModalIsDisplayed] = useGlobalState("modalIsDisplayed");
  const buttonConfig = { showStartButton, showHomeButton, showMenuButtons, showLanguageButton, hideLeftButton, hideNewScanButton, showBackButton };
  const navigation = useNavigation();
  const routeIndex = navigation.getState().index
  const routeName = navigation.getState().routes[routeIndex].name
  const headerProps = {
    closeButton: showHeaderCloseButton,
    logo: showHeaderLogo
  };

  const doLogout = () => {
    setCurrentUser(undefined);
    setUserToken(undefined);
    setCurrentMeasurements(undefined);
    setMatchedProducts(undefined);
    setInitialMatchedProducts(undefined);
    setCurrentScan(undefined);
    setAllProducts([]);
    deleteCarts(carts);
    setCarts([]);
    setCurrentScreen("Home");
    navigation.navigate("Home");
  };

  useEffect(() => {
    if (typeof routeName === "string" && routeName.includes("Modal")) {
      setModalIsDisplayed(true);
    } else {
      setModalIsDisplayed(false);
    }
    
  }, [routeName]);

  useEffect(() => {
    if (!isUserActive && currentMeasurements) doLogout();
  }, [isUserActive]);

  return (
    <View style={styles.container}>
      <OfflineNotice />
      <Header {...headerProps} />
      <View style={styles.content}>
        {title && <Text style={Fonts.pageTitle}>{modalIsDisplayed ? " " : title}</Text>}
        {subtitle && <Text style={Fonts.pageSubTitle}>{modalIsDisplayed ? " " : subtitle}</Text>}
        {showBackButton && (
          <View style={styles.backButtonWrapper}>
            <BackButton />
          </View>
        )}
        {children}
      </View>

      <Footer {...buttonConfig} />
    </View>
  );
};

export default LayoutWrapper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: "1%"
  },
  content: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "1%",
    paddingRight: "1%",
  },
  footer: {
    height: "13%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  backButtonWrapper: {
    height: Platform.OS === "web" ? "12.5%" : "2.5%",
    width: "5.8%",
    position: "absolute",
    zIndex: 2,
    marginTop: Platform.OS === "web" ? "5.5%" : "5.5%",
    marginLeft: "1.6%"
  }
});
