import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { Icons, background } from "../../assets";
import { Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";

const ChangeProductSizeModal: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  // State variables
  const [Colors] = useGlobalState("colors");
  const [isOrthoOrder] = useGlobalState('isOrthoOrder');
  const [selectedShoe, setSelectedShoe] = useGlobalState('selectedShoe');
  const [shoeSize, setShoeSize] = useState<string>("");
  const [sizes, setSizes] = useState<React.ReactNode[]>();
  const [availableSizes, setAvailableSizes] = useState<any>();

  // Other necessary state and functions
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const sizeRecommendation = params.sizeRecommendation;

  useEffect(() => {
    const fetchAvailableSizes = async () => {
      try {
        const response = await ProductService.getStoreProductsbyMpn(params.STORE_ID, params.MPN);
        const availableSizes = response[0].details;
        setAvailableSizes(availableSizes);
      } catch (error) {
        console.error("Error fetching insole contour IDs:", error);
      }
    };

    fetchAvailableSizes();
  }, [params.STORE_ID, params.MPN]);

  useEffect(() => {
    let sizesArray: JSX.Element[] = [];
    let newShoeSize = "";

    if (availableSizes && availableSizes.length > 0) {
      if (availableSizes.length === 1) {
        // If only one contour, directly iterate over its availableSizes
        availableSizes[0].availableSizes.forEach((size) => {
          sizesArray.push(<Picker.Item label={size.shoe_size} value={size.shoe_size} key={size.shoe_size} />);
          if (Object.values(size).includes(sizeRecommendation[availableSizes[0].eu].toString())) {
            newShoeSize = sizeRecommendation[availableSizes[0].eu];
          }
        });
      } else {
        availableSizes.forEach((size) => {
          sizesArray.push(<Picker.Item label={size.eu} value={size.gtin} key={size.eu} />);
          if (sizeRecommendation && Object.values(size).includes(sizeRecommendation[size.eu].toString())) {
            newShoeSize = sizeRecommendation[size.eu];
          }
        });
      }
    }
    setSizes(sizesArray);
    setShoeSize(newShoeSize);
  }, [availableSizes]);
  const handleSizeSelection = (selectedItem: any) => {
    const sizeObject = availableSizes.find((size: any) => size.gtin === selectedItem);
    setShoeSize(sizeObject.gtin);
    const stateSizeObject = selectedShoe.size
    stateSizeObject.eu = sizeObject.eu
    setSelectedShoe((prev: any) => ({ ...prev, size: stateSizeObject }));
  }

  const handleSubmission = () => {
    isOrthoOrder ?
      navigation.navigate("OrthopedicInsoleOptionsModal", { GTIN: shoeSize })
      : navigation.navigate("Order", { GTIN: shoeSize });
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          <View style={styles.formWrapper}>
            <Text style={styles.titleText}>{i18n.t("adjustSize")}</Text>
            <Image source={{ uri: Icons.soleIcon }} style={styles.icon} />
            <View style={{ height: "75%" }}>
              <View style={styles.inputsWrapper}>
                <Picker style={styles.picker} selectedValue={shoeSize} onValueChange={(itemValue) => handleSizeSelection(itemValue)}>
                  {sizes}
                </Picker>
              </View>
            </View>
            <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={handleSubmission}>
              <Text style={styles.buttonText}>{i18n.t("chooseButton")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  modalWrapper: {
    flex: 1,
    width: "57%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "90%",
    height: "90%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  inputsWrapper: {
    width: "65%",
    height: "10%",
    marginTop: "2%",
    backgroundColor: "#f0eff1",
    borderRadius: 4,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  picker: {
    width: "100%",
    height: "95%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
  },
  icon: {
    height: "12%",
    width: "12%",
    resizeMode: "contain",
    alignSelf: "center",
    marginTop: "1%",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
});

export default ChangeProductSizeModal;
