import React, { ReactNode } from 'react';
import { Image, StyleSheet, TouchableOpacity, View, TouchableWithoutFeedback, Keyboard, Text } from 'react-native';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { Fonts } from '../../styles/font.styles';

type Props = {
	children?: ReactNode;
	title?: string;
	shadow?: boolean
};

const ModalWrapper: React.FC<Props> = ({ children, title, shadow = true }: Props): JSX.Element => {
	const navigation = useNavigation();
	const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');
	const [Colors] = useGlobalState('colors');

	const modalStyles = [styles.modal]
	if (shadow) modalStyles.push(styles.modalShadow)

	const handleOnPressBackButton = () => {
		['DataProtectionModal' , 'InsoleDetailModal', 'CrossProductDetailModal'].includes(navigation.state.routeName)  
			? navigation.goBack(null)
			: [setModalIsDisplayed(false), navigation.goBack(null)]
	}

	return (
		<TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
			<View style={styles.container}>
				<View style={styles.titleStyle}>
					{title && <Text style={styles.titeText}> {title} </Text>}
				</View>
				<View style={styles.modalWrapper}>
					<View style={styles.backButtonWrapper}>
						<TouchableOpacity style={[styles.backButton,{backgroundColor: Colors.mainColorDark.color}]} onPress={() => handleOnPressBackButton()}>
							<Image source={Icons.backArrowIcon} style={styles.iconContainer} />
						</TouchableOpacity>
					</View>
					<View style={modalStyles}>{children}</View>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
};

export default ModalWrapper;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		paddingBottom: '10%',
		paddingTop: '8.5%'
	},
	modalWrapper: {
		flex: 1,
		width: '89%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: '1.6%'
	},
	modal: {
		width: '89.5%',
		height: '95%',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	modalShadow: {
		shadowColor: '#8e8f90',
		shadowOpacity: 0.8,
		shadowRadius: 2,
		shadowOffset: {
			height: 1,
			width: 0
		}
	},
	titleStyle: {
		marginBottom: '2%',
		height: '5%',
	},
	backButtonWrapper: {
		width: '7.5%',
		height: '11%',
	},
	backButton: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		shadowColor: '#8e8f90',
		opacity: 0.95,
		shadowOpacity: 0.8,
		shadowRadius: 2,
		shadowOffset: {
			height: 1,
			width: 0
		}
	},
	iconContainer: {
		height: '50%',
		width: '50%',
		resizeMode: 'contain'
	},
	titeText: {
		...Fonts.mBlack,
		...Fonts.center,
		fontSize: 21,
		fontWeight: '900',
		textTransform: 'uppercase'
	}
});
