import React, { useState } from 'react';
import { ImageBackground, PixelRatio, StyleSheet, View, Text, Platform } from 'react-native';
import { background } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Fonts, Styles } from '../../styles';
import { Picker } from '@react-native-picker/picker';

const NewScanUserBirthYear: React.FC = (): JSX.Element => {
    const [, setRoute] = useGlobalState('currentScreen');
    const [, setUserBirthDate] = useGlobalState('userBirthDate');
    const [language] = useGlobalState('language');

    const [value, setValue] = useState<string>('2020-01-01');

    const i18n = I18N(language);
    const navigation = useNavigation();

    const navigate = (route: string | undefined) => {
        if (route) {
            navigation.navigate(route);
            setRoute(route);
        } };

    const onclick = () => {
        setUserBirthDate(value)
        navigate('NewScanModeSelection')
    };

    return <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} showStartButton={false} hideNewScanButton showBackButton={true} title={i18n.t('newScan.birthdate')}>
            <View style={styles.contentWrapper}>
                <View style={styles.cardWrapper}>
                    {Platform.OS === 'web'&&
                           <Text style={Fonts.textTaller}>{i18n.t('newScan.birthdateQuestion')}</Text>
                            }
                         <Picker
                            style={styles.picker}
                            selectedValue={value}
                            onValueChange={value => { setValue(value) }}
                        >
                            <Picker.Item label={'1911'} value={'1911-01-01'} />
                            <Picker.Item label={'1912'} value={'1912-01-01'} />
                            <Picker.Item label={'1910'} value={'1910-01-01'} />
                            <Picker.Item label={'1913'} value={'1913-01-01'} />
                            <Picker.Item label={'1915'} value={'1915-01-01'} />
                            <Picker.Item label={'1916'} value={'1916-01-01'} />
                            <Picker.Item label={'1914'} value={'1914-01-01'} />
                            <Picker.Item label={'1917'} value={'1917-01-01'} />
                            <Picker.Item label={'1918'} value={'1918-01-01'} />
                            <Picker.Item label={'1919'} value={'1919-01-01'} />

                            <Picker.Item label={'1920'} value={'1920-01-01'} />
                            <Picker.Item label={'1921'} value={'1921-01-01'} />
                            <Picker.Item label={'1922'} value={'1922-01-01'} />
                            <Picker.Item label={'1923'} value={'1923-01-01'} />
                            <Picker.Item label={'1924'} value={'1924-01-01'} />
                            <Picker.Item label={'1925'} value={'1925-01-01'} />
                            <Picker.Item label={'1926'} value={'1926-01-01'} />
                            <Picker.Item label={'1927'} value={'1927-01-01'} />
                            <Picker.Item label={'1928'} value={'1928-01-01'} />
                            <Picker.Item label={'1929'} value={'1929-01-01'} />

                            <Picker.Item label={'1930'} value={'1930-01-01'} />
                            <Picker.Item label={'1931'} value={'1931-01-01'} />
                            <Picker.Item label={'1932'} value={'1932-01-01'} />
                            <Picker.Item label={'1933'} value={'1933-01-01'} />
                            <Picker.Item label={'1934'} value={'1934-01-01'} />
                            <Picker.Item label={'1935'} value={'1935-01-01'} />
                            <Picker.Item label={'1936'} value={'1936-01-01'} />
                            <Picker.Item label={'1937'} value={'1937-01-01'} />
                            <Picker.Item label={'1938'} value={'1938-01-01'} />
                            <Picker.Item label={'1939'} value={'1939-01-01'} />

                            <Picker.Item label={'1940'} value={'1940-01-01'} />
                            <Picker.Item label={'1941'} value={'1941-01-01'} />
                            <Picker.Item label={'1942'} value={'1942-01-01'} />
                            <Picker.Item label={'1943'} value={'1943-01-01'} />
                            <Picker.Item label={'1944'} value={'1944-01-01'} />
                            <Picker.Item label={'1945'} value={'1945-01-01'} />
                            <Picker.Item label={'1946'} value={'1946-01-01'} />
                            <Picker.Item label={'1947'} value={'1947-01-01'} />
                            <Picker.Item label={'1948'} value={'1948-01-01'} />
                            <Picker.Item label={'1949'} value={'1949-01-01'} />

                            <Picker.Item label={'1950'} value={'1950-01-01'} />
                            <Picker.Item label={'1951'} value={'1951-01-01'} />
                            <Picker.Item label={'1952'} value={'1952-01-01'} />
                            <Picker.Item label={'1953'} value={'1953-01-01'} />
                            <Picker.Item label={'1954'} value={'1954-01-01'} />
                            <Picker.Item label={'1955'} value={'1955-01-01'} />
                            <Picker.Item label={'1956'} value={'1956-01-01'} />
                            <Picker.Item label={'1957'} value={'1957-01-01'} />
                            <Picker.Item label={'1958'} value={'1958-01-01'} />
                            <Picker.Item label={'1959'} value={'1959-01-01'} />

                            <Picker.Item label={'1960'} value={'1960-01-01'} />
                            <Picker.Item label={'1961'} value={'1961-01-01'} />
                            <Picker.Item label={'1962'} value={'1962-01-01'} />
                            <Picker.Item label={'1963'} value={'1963-01-01'} />
                            <Picker.Item label={'1964'} value={'1964-01-01'} />
                            <Picker.Item label={'1965'} value={'1965-01-01'} />
                            <Picker.Item label={'1966'} value={'1966-01-01'} />
                            <Picker.Item label={'1967'} value={'1967-01-01'} />
                            <Picker.Item label={'1968'} value={'1968-01-01'} />
                            <Picker.Item label={'1969'} value={'1969-01-01'} />

                            <Picker.Item label={'1970'} value={'1970-01-01'} />
                            <Picker.Item label={'1971'} value={'1971-01-01'} />
                            <Picker.Item label={'1972'} value={'1972-01-01'} />
                            <Picker.Item label={'1973'} value={'1973-01-01'} />
                            <Picker.Item label={'1974'} value={'1974-01-01'} />
                            <Picker.Item label={'1975'} value={'1975-01-01'} />
                            <Picker.Item label={'1976'} value={'1976-01-01'} />
                            <Picker.Item label={'1977'} value={'1977-01-01'} />
                            <Picker.Item label={'1978'} value={'1978-01-01'} />
                            <Picker.Item label={'1979'} value={'1979-01-01'} />

                            <Picker.Item label={'1980'} value={'1980-01-01'} />
                            <Picker.Item label={'1981'} value={'1981-01-01'} />
                            <Picker.Item label={'1982'} value={'1982-01-01'} />
                            <Picker.Item label={'1983'} value={'1983-01-01'} />
                            <Picker.Item label={'1984'} value={'1984-01-01'} />
                            <Picker.Item label={'1985'} value={'1985-01-01'} />
                            <Picker.Item label={'1986'} value={'1986-01-01'} />
                            <Picker.Item label={'1987'} value={'1987-01-01'} />
                            <Picker.Item label={'1988'} value={'1988-01-01'} />
                            <Picker.Item label={'1989'} value={'1989-01-01'} />

                            <Picker.Item label={'1990'} value={'1990-01-01'} />
                            <Picker.Item label={'1991'} value={'1991-01-01'} />
                            <Picker.Item label={'1992'} value={'1992-01-01'} />
                            <Picker.Item label={'1993'} value={'1993-01-01'} />
                            <Picker.Item label={'1994'} value={'1994-01-01'} />
                            <Picker.Item label={'1995'} value={'1995-01-01'} />
                            <Picker.Item label={'1996'} value={'1996-01-01'} />
                            <Picker.Item label={'1997'} value={'1997-01-01'} />
                            <Picker.Item label={'1998'} value={'1998-01-01'} />
                            <Picker.Item label={'1999'} value={'1999-01-01'} />

                            <Picker.Item label={'2000'} value={'2000-01-01'} />
                            <Picker.Item label={'2001'} value={'2001-01-01'} />
                            <Picker.Item label={'2002'} value={'2002-01-01'} />
                            <Picker.Item label={'2003'} value={'2003-01-01'} />
                            <Picker.Item label={'2004'} value={'2004-01-01'} />
                            <Picker.Item label={'2005'} value={'2005-01-01'} />
                            <Picker.Item label={'2006'} value={'2006-01-01'} />
                            <Picker.Item label={'2007'} value={'2007-01-01'} />
                            <Picker.Item label={'2008'} value={'2008-01-01'} />
                            <Picker.Item label={'2009'} value={'2009-01-01'} />

                            <Picker.Item label={'2010'} value={'2010-01-01'} />
                            <Picker.Item label={'2011'} value={'2011-01-01'} />
                            <Picker.Item label={'2012'} value={'2012-01-01'} />
                            <Picker.Item label={'2013'} value={'2013-01-01'} />
                            <Picker.Item label={'2014'} value={'2014-01-01'} />
                            <Picker.Item label={'2015'} value={'2015-01-01'} />
                            <Picker.Item label={'2016'} value={'2016-01-01'} />
                            <Picker.Item label={'2017'} value={'2017-01-01'} />
                            <Picker.Item label={'2018'} value={'2018-01-01'} />
                            <Picker.Item label={'2019'} value={'2019-01-01'} />
                            <Picker.Item label={'2020'} value={'2020-01-01'} />

                            <Picker.Item label={'2021'} value={'2021-01-01'} />
                        </Picker>
                    <Text style={value != '2020-01-01' ? styles.optionCardCaptionActive : styles.optionCardCaption} onPress={() => value ? onclick() : undefined}>{i18n.t('next').toLocaleLowerCase()}</Text>
                </View>
            </View> 
        </LayoutWrapper>
    </ImageBackground>;
};

export default NewScanUserBirthYear;

const styles = StyleSheet.create({
    backgroundImage: {
        resizeMode: 'cover',
        flex: 1,
        backgroundColor: 'white',
    },
    contentWrapper: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardWrapper: {
        flex: 1,
        width: '50%',
        flexWrap: 'wrap',
        backgroundColor: '#ffff',
        opacity: .87,
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-evenly',
        maxWidth: '70%',
        marginTop: '3%',
        borderRadius: 12 / PixelRatio.get(),
        margin: '1%',
        shadowColor: '#000',
        shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
        shadowOpacity: .16,
        shadowRadius: 4 / PixelRatio.get(),
        elevation: 1
    },
    picker: {
        backgroundColor: 'white',
        width:  Platform.OS==='web' ? '60%' : '100%',
        height: Platform.OS==='web' ? '15' : '50%',
        marginBottom: Platform.OS==='web' ? '20%' : undefined,
    },
    optionCard: {
        width: 'auto',
        height: 'auto',
        borderRadius: 5,
        borderWidth: 0.5,
        marginRight: '1%',
        marginTop: '1%',
        borderColor: 'black',
        alignContent: 'center',
        alignItems: 'center',
        padding: '1%',
    },

    optionCardCaption: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center'
    },
    optionCardCaptionActive: {
        fontFamily: 'Montserrat-Light',
        fontSize: 16,
        textAlign: 'center',
        color: '#2bc6ff'
    },
    textInputStyle: {
        width: 250,
        backgroundColor: '#dde8c9',
        padding: 16,
      },
});
