import { View } from 'react-native';
import React from 'react';
import { StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import I18n from '../../i18nSets/i18n';
import BelowForm from '../Auth/BelowForm';
import Form from '../Auth/Form';
import MeasurementScreenModalWrapper from '../../components/ModalWrapper/MeasurementScreenModalWrapper';

const LoginMeasurementModal: React.FC = (): JSX.Element =>
  <MeasurementScreenModalWrapper title={I18n.t('auth.login')}>
      <View style={styles.formWrapper}>
        <Form isLogin />
      </View>
      <BelowForm isLogin />
  </MeasurementScreenModalWrapper>;

export default LoginMeasurementModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  formWrapper: {
    height: '70%',
    width:'70%',
    alignSelf: 'center'
  }
});
