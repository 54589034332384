import { View } from 'react-native';
import React from 'react';
import { StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import I18n from '../../i18nSets/i18n';
import BelowForm from '../Auth/BelowForm';
import Form from '../Auth/Form';
import MeasurementScreenModalWrapper from '../../components/ModalWrapper/MeasurementScreenModalWrapper';
import { useGlobalState } from '../../shared/state';


const RegisterMeasurementModal: React.FC = (): JSX.Element => {
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentScanner] = useGlobalState('currentScanner');

  const title: string = currentScanner.partner && currentScanner.partner.toLowerCase().includes('shoepassion')
  ? 'ShoepassionID'
  : currentMeasurements
    ? I18n.t('auth.oneFittingIDCreation')
    : I18n.t('auth.register')
  return (
    <MeasurementScreenModalWrapper title={title}>
    <KeyboardAvoidingView behavior="padding" enabled keyboardVerticalOffset={Platform.OS === 'ios' ? -85 : 0} style={styles.container}>
        <View style={styles.container}>
          <View style={styles.formWrapper}>
            <Form />
          </View>
          <BelowForm />
        </View>
      </KeyboardAvoidingView>
    </MeasurementScreenModalWrapper>
  )
}

export default RegisterMeasurementModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  formWrapper: {
    height: '70%',
    alignSelf: 'center'
  }
});
