import React from 'react';
import { PixelRatio, StyleSheet, Text, View } from 'react-native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';

type Props = {
  name: string
  unit: string
  values: {
    left: number
    right: number
  }
}

const MeasurementCard: React.FC<Props> = ({ name, unit, values }: Props): JSX.Element => {
  const [language] = useGlobalState('language');
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);
  return (<View style={styles.card}>
    <View>
      <Text style={styles.name}>{name.toUpperCase()}</Text>
      <Text style={styles.unit}>{unit.toUpperCase()}</Text>
    </View>
    <View style={styles.measurements}>
      {values && Object.keys(values).length > 0 && Object.keys(values).map(entry => {
        return <View style={styles.measurementsCard} key={`${name}_${entry}`}>
          <View style={styles.measurementsCaption}>
            <Text style={styles.measurementsCaptionText}>{i18n.t(`MeasurementScale.${entry}`).toUpperCase()}</Text>
          </View>
          <View style={styles.measurementsValues}>
            <View style={[styles.accentBar, { backgroundColor: Colors.mainColorLight.color }]} />
            <Text style={styles.measureValue}>{values[entry]}</Text>
          </View>
        </View>;
      })}
    </View>
  </View>);
}
export default MeasurementCard;

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: '6.5%',
    paddingBottom: '6.5%',
    paddingLeft: '8%',
    paddingRight: '8%',
    borderBottomColor: '#cacaca',
    borderBottomWidth: 2 / PixelRatio.get()
  },
  name: {
    marginBottom: '2%',
    letterSpacing: 1.125,
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 18
  },
  unit: {
    fontFamily: 'Montserrat-Light',
    fontSize: 9
  },
  measurements: {
    marginTop: '3.33%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  measurementsCard: {
    flex: 1,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '5%',
    paddingLeft: '5%',
  },
  measurementsCaption: {
    marginBottom: '2.66%'
  },
  measurementsCaptionText: {
    letterSpacing: 1.125,
    fontFamily: 'Montserrat-Bold',
    fontSize: 12,
  },
  measurementsValues: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  accentBar: {
    width: '4.33%',
    marginRight: '8%',
    paddingTop: '3%',
    paddingBottom: '3%',
    borderRadius: 4,
    height: '66%'
  },
  measureValue: {
    letterSpacing: 1.26,
    fontWeight: '600',
    marginTop: '-1%',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 20
  }
});
