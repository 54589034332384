import { Theme } from '../../styles';
import { Carts, Colors, FooterMenuDTO, Measurements, NewScanStateDTO, ScanDTO, Scanfile, ScannerConfigDTO, ScanningDATA, UserProfile } from '../model/types';
import createGlobalState from './globalState';

type AppState = {
  language: string
  currentScanner: ScannerConfigDTO | undefined
  currentMeasurements: Measurements | undefined
  currentUser: UserProfile | null | undefined
  userToken: string | undefined
  userOrigin: string | undefined
  userBirthDate: string | undefined
  currentScreen: string
  modalIsDisplayed: boolean
  newScanState: NewScanStateDTO | undefined
  footerMenu: FooterMenuDTO
  currentScan: ScanDTO | undefined
  scanfiles: Scanfile[],
  scanningDATA: ScanningDATA | undefined
  matchedProducts: any | undefined
  initialMatchedProducts: any | undefined
  allProducts: any[]
  insoleQuestionnaire: any
  colors: Partial<Colors>
  isUserActive:boolean
  authToken: string;
  customerStoreID: string;
  industryPartner: string;
  isOrthoOrder: boolean;
  selectedShoe: any;
  carts: Carts;
};

const initialState: AppState = {
  language: 'de',
  currentScanner: undefined,
  currentMeasurements: undefined,
  currentUser: undefined,
  userToken: undefined,
  userOrigin: undefined,
  userBirthDate: undefined,
  currentScreen: 'Home',
  modalIsDisplayed: false,
  footerMenu: { menuButtons: [] },
  newScanState: { gender: undefined, mode: undefined },
  currentScan: undefined,
  scanfiles: [],
  scanningDATA: undefined,
  matchedProducts: undefined,
  initialMatchedProducts: undefined,
  allProducts: [],
  insoleQuestionnaire: {},
  colors: Theme.onefidColors,
  isUserActive: true,
  authToken: "",
  customerStoreID: "",
  industryPartner: "",
  isOrthoOrder: false,
  selectedShoe: undefined,
  carts: {}
};

export const { GlobalStateProvider, useGlobalState } = createGlobalState(initialState);
