// @ts-ignore
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED, createContext, createElement, useCallback, useEffect, useState, } from 'react';
import { setEnvVariablesToGlobalState } from '../../environments/Environment';

// this has to be loaded here because loading it in App.tsx dose not work
setEnvVariablesToGlobalState();

const isFunction = (value: any) => (typeof value === 'function');

const updateValue = (oldValue: any, newValue: (arg: any) => void) => {
  if (isFunction(newValue)) {
    return newValue(oldValue);
  }

  return newValue;
};

const useUnstableContext = (Context: any, observedBits: number) => {
  const { ReactCurrentDispatcher } = __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
  const dispatcher = ReactCurrentDispatcher.current;

  if (!dispatcher) {
    throw new Error('Hooks can only be called inside the body of a function component. (https://fb.me/react-invalid-hook-call)');
  }

  return dispatcher.useContext(Context, observedBits);
};

const createGlobalStateCommon = (initialState: any) => {

  const keys = Object.keys(initialState);

  const calculateChangedBits = (a: { [ x: string ]: any; }, b: { [ x: string ]: any; }) => {
    let bits = 0;
    keys.forEach((k, i) => {
      if (a[ k ] !== b[ k ]) bits |= 1 << i;
    });

    return bits;
  };


  let _globalState = initialState;
  let _listener: { (value: any): void; (arg: any): void; } | null = null;

  const Context = createContext(initialState, calculateChangedBits);

  const GlobalStateProvider = ({ children }: any) => {
    const [ state, setState ] = useState(initialState);

    useEffect(() => {
      if (_listener) throw new Error('Only one <GlobalStateProvider> allowed.');

      _listener = setState;

      if (state !== initialState) {
        _globalState = state;
      } else if (state !== _globalState) {
        setState(_globalState);
      }

      return () => {
        _listener = null;
      };
    }, [ initialState ]); // trick for react-hot-loader
    return createElement(Context.Provider, { value: state }, children);
  };

  const validateKey = (name: string) => {
    if (!keys.includes(name)) {
      throw new Error(`Name not Found: '${name}'. It must be provided in initialState as a property key.`);
    }
  };

  const setGlobalState = (name: string, setter: (arg: any) => void) => {
    if (process.env.NODE_ENV !== 'production') {
      validateKey(name);
    }

    _globalState = {
      ..._globalState,
      [ name ]: updateValue(_globalState[ name ], setter),
    };

    if (_listener) {
      _listener(_globalState);
    }
  };

  const useGlobalState = (name: string) => {
    if (process.env.NODE_ENV !== 'production') {
      validateKey(name);
    }

    const index = keys.indexOf(name);
    const observedBits = 1 << index;

    const getter = useUnstableContext(Context, observedBits);
    const setter = useCallback(s => setGlobalState(name, s), [ name ]);

    return [ getter[ name ], setter ];
  };

  return { GlobalStateProvider, setGlobalState, useGlobalState };
};

const createGlobalState = (initialState: any) => {
  const {
    GlobalStateProvider,
    useGlobalState,
    setGlobalState,
  } = createGlobalStateCommon(initialState);

  return {
    GlobalStateProvider,
    useGlobalState,
    setGlobalState,
  };
};

export default createGlobalState;