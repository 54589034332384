import axios, { AxiosRequestConfig, CancelTokenSource, AxiosError } from 'axios';
import * as _ from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as Sentry from 'sentry-expo';
import { Platform } from 'react-native';


axios.defaults.baseURL = globalThis.env.ONEFID_CORE_API;

export const request = async (
	url: string,
	method: string,
	responseType: string,
	handleResponse: any,
	config?: AxiosRequestConfig
) => {
	const modifiedConfig = await rewriteConfig(config);

	return axios({
		...modifiedConfig,
		url: url,
		method: method,
		responseType: responseType
	})
		.then(handleResponse)
		.catch((e) => handleException(e, url));
};

export const get = async (url: string, config?: AxiosRequestConfig) => {
	const modifiedConfig = await rewriteConfig(config);

	// if we do not have a standard params serializer, we will add this default one
	// it will handle brackets inside JSON strings correctly when URL encoding them
	if (!modifiedConfig.paramsSerializer) {
		modifiedConfig.paramsSerializer = (params: any) => {
			return _.reduce(
				_.mapValues(params, value => encodeURI(JSON.stringify(value))),
				(result, value, key) => {
					return (result ? result + '&' : '') + key + '=' + value;
				},
				''
			);
		};
	}

	return axios
		.get(url, modifiedConfig)
		.then(handleResponse)
		.catch((e) => handleException(e, url));
};

export const post = async (url: string, data: any, config?: AxiosRequestConfig) => {
	try {
		const modifiedConfig = await rewriteConfig(config);

		return axios
			.post(url, data, modifiedConfig)
			.then(handleResponse)
			.catch((e) => handleException(e, url));
	} catch (error) {
		// Handle any errors that may occur during the process
		console.error("Error:", error);
	  }
};

export const put = async (url: string, data: any, config?: AxiosRequestConfig) => {
	const modifiedConfig = await rewriteConfig(config);

	return axios
		.put(url, data, modifiedConfig)
		.then(handleResponse)
		.catch((e) => handleException(e, url));
};
export const patch = async (url: string, data: any, config?: AxiosRequestConfig) => {
	const modifiedConfig = await rewriteConfig(config);

	return axios
		.patch(url, data, modifiedConfig)
		.then(handleResponse)
		.catch((e) => handleException(e, url));
};

export const deleteRequest = async (url: string, config?: AxiosRequestConfig) => {
	const modifiedConfig = await rewriteConfig(config);

	return axios.delete(url, modifiedConfig).catch((e) => handleException(e, url));

};

export const newCancelToken = (): CancelTokenSource => {
	return axios.CancelToken.source();
};

const defaultConfig = {
	timeout: 50000
};

export const rewriteConfig = async (config?: AxiosRequestConfig): Promise<any> => {
	const partnerToken = await AsyncStorage.getItem('authtoken'); // Wait for the Promise to resolve
  
	return {
	  headers: {
		Authorization: `Token ${partnerToken}`
	  },
	  ...defaultConfig,
	  ...config,
	  withCredentials: false,
	};
  };
  

const handleResponse = (response: any) => {
	// even if we receive an error, the backend will send as an ApiErrorDto
	return response.data;
};

const handleException = async (e: AxiosError, url: string): Promise<any> => {
	if (!url.includes('/api/v1/host') || !url.includes('/api/v2/users/by-employee-number')) {
		if(Platform.OS !== 'web') Sentry.Native.captureException(e)
	}
	// maybe we got an ApiErrorDto
	if (e.response && e.response.data) {
		return Promise.reject(e.response.data);
	}

	// if not, we will create one to streamline the handleException API
	return Promise.reject({
		message: e.toString(),
		errors: [e.toString()]
	});
};

const API = { get, post, put, patch, deleteRequest };
export default API;
