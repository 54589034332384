import React from 'react';
import { StyleSheet, View } from 'react-native';
import MeasurementsTab from './MeasurementsTab';

type Props = {
  tabs: {
    tabId: string
    caption: string
  }[]
  activeTab: string
  onClickHandler: (id: string) => void
}

const MeasurementsTabs: React.FC<Props> = ({ tabs, activeTab, onClickHandler }: Props): JSX.Element =>
  <View style={styles.tabsContainer}>
    {tabs && tabs.length > 0 && tabs.map(
      tab => <MeasurementsTab key={tab.tabId}
                              isActive={tab.tabId != activeTab}
                              tabId={tab.tabId}
                              caption={tab.caption}
                              onClickHandler={onClickHandler}/>
    )}
  </View>;

export default MeasurementsTabs;

const styles = StyleSheet.create({
  tabsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    margin: 0
  },
});