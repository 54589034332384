import { View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { greenDot, Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import MeasurementScreenModalWrapper from '../../components/ModalWrapper/MeasurementScreenModalWrapper';


const LogoutMeasurementModal: React.FC = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser');
  const [userToken, setUserToken] = useGlobalState('userToken');
  const [, setCurrentMeasurements] = useGlobalState('currentMeasurements');
  const [, setMatchedProducts] = useGlobalState('matchedProducts');
  const [, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [, setAllProducts] = useGlobalState('allProducts');
  const [, setCurrentScan] = useGlobalState('currentScan');
  const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');
  const [, setCurrentScreen] = useGlobalState('currentScreen');
  const [language] = useGlobalState('language');
  const [userInfo, setUserInfo] = useState<string | undefined>(undefined);

  const i18n = I18N(language);
  const navigation = useNavigation();

  const onLogout = () => {
    setCurrentUser(undefined);
    setUserToken(undefined)
    setUserToken(undefined)
    setCurrentMeasurements(undefined)
    setMatchedProducts(undefined)
    setInitialMatchedProducts(undefined)
    setCurrentScan(undefined)
    setAllProducts([])
    setCurrentScreen('Home');
    navigation.navigate('Home');
  };

  useEffect(() => {
    setUserInfo(userToken
      ? currentUser.firstName.length > 0 && currentUser.lastName.length > 0
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : currentUser.email
      : currentUser && currentUser.employeeNumber
        ? currentUser.employeeNumber
        : undefined)
  }, [])

  return (
    <MeasurementScreenModalWrapper title={i18n.t('auth.logOut')}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.iconsContainer}>
            <Image style={styles.greenDotWrapper} source={greenDot} />
            <Image style={styles.iconWrapper} source={Icons.loginIconOutlined} />
          </View>
          <Text style={styles.userInfoText}> {userInfo} </Text>
          <View style={styles.divider} />
          <Text style={styles.logoutModalText}>
            {i18n.t('auth.logoutMessage')}
          </Text>
          <TouchableOpacity style={Styles.submitButtonDark} onPress={() => onLogout()}>
            <Text style={[styles.buttonText, { color: '#ffffff' }]}>{i18n.t('auth.logOut')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={Styles.submitButtonLight} onPress={() => [setModalIsDisplayed(false), navigation.goBack(null)]}>
            <Text style={[styles.buttonText, { color: '#000000' }]}>{i18n.t('auth.stayConnected')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </MeasurementScreenModalWrapper>
  );
};

export default LogoutMeasurementModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '50%',
    height: '70%',
    justifyContent: 'space-evenly'
  },
  iconsContainer: {
    width: '45%',
    height: '25%',
    alignSelf: 'center'
  },
  greenDotWrapper: {
    alignSelf: 'flex-end',
    width: '15%',
    height: '15%',
    resizeMode: 'contain'
  },
  iconWrapper: {
    width: '75%',
    height: '70%',
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  divider: {
    width: '90%',
    alignSelf: 'center',
    borderTopColor: '#707070',
    borderTopWidth: 1
  },
  logoutModalText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: "normal"
  },
  userInfoText: {
    textAlign: 'center',
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
    fontWeight: "normal",
  },
  buttonText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
});
