import React, { useEffect, useRef, useState } from "react";
import { PixelRatio, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";

type Props = {
  onScanAgain: any;
  onCancel: any;
  errorCode: string;
};

const ScanFailedModal: React.FC<Props> = ({ onScanAgain, onCancel, errorCode }: Props): JSX.Element => {
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const [time, setTime] = useState(20);
  const timerRef = useRef(time);
  const [showCountdown, setShowCountdown] = useState(false)

  const countDownTimer = () => {
    useEffect(() => {
      setShowCountdown(true);
      const timerId = setInterval(() => {
        timerRef.current -= 1;
        if (timerRef.current <= 0) {
          setShowCountdown(false);
          clearInterval(timerId);
        } else {
          setTime(timerRef.current);
        }
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }, [time]);
  }

  if (errorCode === "camera_initialization_fails" || errorCode === "camera_provides_no_frames") countDownTimer();

  const handleShowCallSupport = () => {
    if (errorCode === "make_missing_target") {
      return <></>
    } else if (errorCode === "incompatible_configuration_file"){
      return <></>
    } else {
      return (
        <View style={styles.subMessageContainer}>
          <Text style={styles.subMessage}>{i18n.t("scan.error.callSupport")}</Text>
        </View>
      )
    }
  }

  return (
    <>
      <View style={styles.contentBox}>
        <View style={styles.captionContainer}>
          <Text style={styles.caption}>{i18n.t("scan.error.title")}</Text>
        </View>
        <View style={styles.mainMessageContainer}>
          <Text style={styles.mainMessage}>{i18n.t(`scan.error.${errorCode}`)}</Text>
        </View>
        {handleShowCallSupport()}
        {showCountdown ?
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text style={styles.countDownText}> {time} </Text>
          </View>
        : 
          <View style={styles.buttonRowContainer}>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.button} activeOpacity={0.6} onPress={onScanAgain}>
                <Text style={styles.buttonText}>{i18n.t("scan.faildScanMessage")[5]}</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.button} activeOpacity={0.6} onPress={onCancel}>
                <Text style={styles.buttonText}>{i18n.t("scan.faildScanMessage")[6]}</Text>
              </TouchableOpacity>
            </View>
          </View>
        }
      </View>
    </>
  );
};

export default ScanFailedModal;

const styles = StyleSheet.create({
  contentBox: {
    backgroundColor: "#ffffff",
    opacity: 0.9,
    width: "80%",
    minHeight: "90%",
    maxHeight: "90%",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "1.5%",
    marginTop: Platform.OS === "web" ? "2%" : "5%",
    elevation: 1
  },
  captionContainer: {
    height: "10%",
    marginTop: "5%"
  },
  caption: {
    fontFamily: "Montserrat-Black",
    fontSize: 24,
    color: "#000000"
  },
  mainMessageContainer: {
    height: "35%",
    maxWidth: "70%"
  },
  mainMessage: {
    fontFamily: "Montserrat-Regular",
    fontSize: 40,
    textAlign: "center",
    color: "#000000"
  },
  subMessageContainer: {
    height: "20%"
  },
  subMessage: {
    fontFamily: "Montserrat-Regular",
    fontSize: 24,
    color: "#000000"
  },
  buttonRowContainer: {
    width: "80%",
    height: "20%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  buttonContainer: {
    minWidth: "35%",
    maxWidth: "35%",
    height: "35%",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    minWidth: "70%",
    maxWidth: "70%",
    borderWidth: 1 / PixelRatio.get(),
    borderColor: "#000000",
    borderRadius: 30 / PixelRatio.get(),
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 13,
    color: "#000000"
  },
  countDownText: {
    fontFamily: "Montserrat-Bold",
    fontSize: 40,
    color: "#000000"
  }
});
