import React from "react";
import { Image, StyleSheet, TouchableOpacity, View, Text, PixelRatio, Platform } from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
export type CrossProduct = {
  name?: string;
  image?: string;
  gtin?: string;
  description?: string[];
  mpn?: string;
  price?: number;
};

interface Props {
  crossProduct: CrossProduct;
}

const CrossProductCard = ({ crossProduct }: Props): JSX.Element => {
  const [, setModalIsDisplayed] = useGlobalState("modalIsDisplayed");

  const [language] = useGlobalState("language");
  const navigation = useNavigation();
  const i18n = I18N(language);

  return (
    <TouchableOpacity
      style={styles.touchContainer}
      activeOpacity={0.6}
      onPress={() => [navigation.navigate(Platform.OS === "web" ? "CrossProductDetail" : "CrossProductDetailModal", { crossProduct, modelIsDisplayed: true }), setModalIsDisplayed(true)]}
    >
      <View style={styles.card}>
        <View style={styles.productCardImage}>
          <Image source={{ uri: crossProduct.image }} style={styles.imageContainer} />
        </View>
        <View style={styles.productCardDetails}>
          {crossProduct.name && (
            <Text adjustsFontSizeToFit style={styles.productCardNameText}>
              {crossProduct.name}
            </Text>
          )}
          {crossProduct.mpn && <Text style={styles.productCardPriceText}>{crossProduct.mpn}</Text>}
          {crossProduct.price && <Text style={styles.productCardPriceText}>{`${i18n.t("price")}: ${crossProduct.price}€`}</Text>}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CrossProductCard;

const styles = StyleSheet.create({
  touchContainer: {
    height: "49%",
    width: "33%",
    backgroundColor: "transparent",
    paddingTop: "0.5%",
    paddingLeft: "0.5%"
  },
  card: {
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    borderColor: "#cacaca",
    borderWidth: 0.5,
    borderRadius: 1,
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    shadowRadius: 4 / PixelRatio.get(),
    elevation: 1,
    backgroundColor: "white"
  },
  productCardImage: {
    height: "60%",
    backgroundColor: "white"
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    resizeMode: "contain"
  },
  productCardDetails: {
    height: "40%",
    width: "100%",
    justifyContent: "space-evenly",
    padding: "3%"
  },
  productCardNameText: {
    flexWrap: "wrap",
    fontFamily: "Montserrat-Black"
  },
  productCardPriceText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 14,
    fontWeight: "100"
  }
});
