import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { defaultLogo } from '../../assets';
import { useGlobalState } from '../../shared/state';

const Logo: React.FC = (): JSX.Element => {
  const [ currentScanner ] = useGlobalState('currentScanner');
  const logo = currentScanner && currentScanner['scanner-logo'] ? currentScanner['scanner-logo'] : undefined;
  return <Image source={logo ? { uri: logo } : defaultLogo} style={styles.logoWrapper}/>;
};

export default Logo;

const styles = StyleSheet.create({
  logoWrapper: {
    width: '100%',
    height: '80%',
    resizeMode: 'contain'
  }
});
