import React, { useEffect } from "react";
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Icons } from "../../assets";
import { Fonts } from "../../styles";
import { useGlobalState } from "../../shared/state";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { I18N } from "../../shared/util";
import { CrossProduct } from "./CrossProductCard";
import { ListEntry } from "../InsoleRecommendation/RecommendationModal";

const CrossProductDetailModal = (route): JSX.Element => {
  const { params } = route.route;
  const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const crossProduct: CrossProduct = params.crossProduct || undefined;
  const modelIsDisplayed: any = params.modelIsDisplayed || false;

  useEffect(() => {

    return () => {
        if(modelIsDisplayed) setModalIsDisplayed(false)
    };
}, []);
  return (
    <ModalWrapper>
      <View style={styles.modalWrapper}>
        <View style={styles.contentWrapper}>
          <>
            <View style={styles.imageContainer}>{crossProduct.image && <Image source={{ uri: crossProduct.image }} style={styles.insoleImage} />}</View>
            <View style={styles.descriptionWrapper}>
              {crossProduct.description && crossProduct.description.map((description: string, index: number) => <ListEntry key={index} text={description} />)}
            </View>
            <View style={styles.detailsWrapper}>
              {crossProduct.name && <Text style={[Fonts.mBlack, Fonts.size18]}>{crossProduct.name}</Text>}
              {crossProduct.mpn && <Text style={[Fonts.text, Fonts.mBold, { marginTop: ".5%" }]}>{crossProduct.mpn}</Text>}
              {crossProduct.price && <Text style={[Fonts.mRegular, Fonts.size16, { marginTop: "1%" }]}>{`${i18n.t("price")}: ${crossProduct.price}€`}</Text>}
            </View>
          </>
        </View>
        <View style={styles.extensionWrapper}>
          <View style={styles.extensionCardContentWrapper}>
            <View style={styles.extensionCardSpacer} />
            <View style={styles.extensionCardContent}>
              <Text style={[Fonts.mRegular, Fonts.size18, { lineHeight: 25 }]}>{i18n.t("insole.marketingText")}</Text>
            </View>
            <View style={styles.extensionCardSpacer} />
          </View>
          <TouchableOpacity style={styles.feedbackButton} activeOpacity={0.6}>
            <Image style={styles.feedbackIcon} source={Icons.feedbackIcon} />
            <Text style={[Fonts.mBlack, Fonts.size14]}>FEEDBACK</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ModalWrapper>
  );
};

export default CrossProductDetailModal;

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  contentWrapper: {
    width: "70%",
    height: "100%",
    padding: "2%",
    backgroundColor: "#ffffff",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  insoleImage: {
    width: "80%",
    height: "100%",
    resizeMode: "contain"
  },
  descriptionWrapper: {
    height: "20%",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  detailsWrapper: {
    maxHeight: "9%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  extensionWrapper: {
    width: "30%",
    maxWidth: "30%",
    height: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  extensionCardContentWrapper: {
    minHeight: "86%",
    maxHeight: "86%",
    width: "100%",
    paddingLeft: "8%",
    backgroundColor: "#f2f2f2",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly"
  },
  extensionCardSpacer: {
    height: "2%",
    width: "60%",
    backgroundColor: "#000000"
  },
  extensionCardContent: {
    width: "90%",
    height: "60%"
  },
  feedbackButton: {
    width: "100%",
    height: "14%",
    backgroundColor: "#ffffff",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1
  },
  feedbackIcon: {
    width: "7%",
    resizeMode: "contain",
    marginRight: "1.5%"
  },
  listEntry: {
    minWidth: "50%",
    maxWidth: "50%",
    marginTop: "1%",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  checkMark: {
    resizeMode: "contain",
    maxHeight: 10,
    maxWidth: 10,
    marginTop: 2,
    marginRight: "1%"
  },
  imageContainer: {
    width: "100%",
    height: Platform.OS === "web" ? "65%" : "100%",
    maxHeight: "70%",
    maxWidth: "100%",
    alignItems: "center"
  }
});
