import React from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { background, modeBarefoot, modeDarkSocks, modeBrightSocks } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { Mode } from '../../shared/model/enums';
import { NewScanStateDTO } from '../../shared/model/types';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import SelectionCard from './SelectionCard';

const NewScanModeSelection: React.FC = (): JSX.Element => {
  const [currentScanner] = useGlobalState('currentScanner');
  const [, setState] = useGlobalState('newScanState');
  const [, setRoute] = useGlobalState('currentScreen');
  const [, setCurrentScan] = useGlobalState('currentScan');
  const [, setCurrentMeasurements] = useGlobalState('currentMeasurements');
  const [, setScanfiles] = useGlobalState('scanfiles');
  const [, setScanningDATA] = useGlobalState('scanningDATA');
  const [, setMatchedProducts] = useGlobalState('matchedProducts');
  const [, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [language] = useGlobalState('language');

  const i18n = I18N(language);
  const navigation = useNavigation();

  const navigate = (route: string | undefined) => {
    if (route) {
      navigation.navigate(route);
      setRoute(route);
    }
  };

  const onSelect = (choice: Mode) => {
    const route = currentScanner && currentScanner['scanner-type'] === 'single' ? 'ScanLeftFoot' : 'ScanBothFeet';
    setState((prevState: NewScanStateDTO) => ({ ...prevState, mode: choice }));
    setCurrentScan(undefined)
    setCurrentMeasurements(undefined)
    setScanfiles([])
    setScanningDATA(undefined)
    setMatchedProducts(undefined)
    setInitialMatchedProducts(undefined)
    navigate(route);
  };

  return <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
    <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} showStartButton={false} hideNewScanButton showBackButton={true} title={i18n.t('newScan.headlines.mode')}>
      <View style={styles.contentWrapper}>
        <View style={styles.cardWrapper}>
          <SelectionCard value={i18n.t(`mode.${Mode.SOCKS}`).toUpperCase()}
            image={modeDarkSocks}
            onPress={() => onSelect(Mode.SOCKS)} />
          <SelectionCard value={i18n.t(`mode.${Mode.BAREFOOT}`).toUpperCase()}
            image={modeBarefoot}
            onPress={() => onSelect(Mode.BAREFOOT)} />
        </View>
      </View>
    </LayoutWrapper>
  </ImageBackground>;
};

export default NewScanModeSelection;

const styles = StyleSheet.create({
  backgroundImage: {
    resizeMode: 'cover',
    flex: 1,
    backgroundColor: 'white',
  },
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardWrapper: {
    flex: 1,
    width: '70%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '70%',
    marginTop: '3%',

  },
  selectionCaption: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    color: '#3a3a3a',
    marginTop: '3.5%'
  }
});
