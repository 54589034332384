import React from 'react';
import { StyleSheet, Text } from 'react-native';

type Props = {
  text: string;
  color?: string;
  fontWeight?: any;
  fontSize?: number;
  fontFamily?: string;
  uppercase?: boolean;
};

const TextTitle: React.FC<Props> = ({
                                      text,
                                      color,
                                      fontWeight,
                                      fontSize,
                                      fontFamily,
                                      uppercase = true
                                    }: Props): JSX.Element =>
  <Text
    style={[
      styles.TextTitle,
      {
        color,
        fontWeight,
        fontSize,
        fontFamily,
        textTransform: uppercase ? 'uppercase' : 'none'
      }
    ]}
  >
    {text}
  </Text>;

export default TextTitle;

const styles = StyleSheet.create({
  TextTitle: {
    textAlign: 'center',
  }
});
