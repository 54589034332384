import React, { useEffect, useState } from "react";
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Icons } from "../../assets";
import { Fonts } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { insoleCategories } from "./InsoleRecommendation";
import { I18N } from "../../shared/util";
import ProductService from "../../shared/services/products-service";
import { useNavigation } from "@react-navigation/native";

type Props = {
  text: string;
};

export const ListEntry: React.FC<Props> = ({ text }): JSX.Element => (
  <View style={styles.listEntry}>
    <Image source={Icons.checkmarkIcon} style={styles.checkMark} />
    <Text style={[Fonts.textSmaller]}>{text}</Text>
  </View>
);

export const insoleDetail: React.FC<any> = (recomendation: any): JSX.Element => (
  <>
    <View style={styles.imageContainer}>
      <Image source={{ uri: recomendation.image }} style={styles.insoleImage} />
    </View>
    <View style={styles.descriptionWrapper}>
      {recomendation.description.map((description: string, index: number) => (
        <ListEntry key={index} text={description} />
      ))}
    </View>
    <View style={styles.detailsWrapper}>
      <Text style={[Fonts.mBlack, Fonts.size18]}>{recomendation.name}</Text>
      {recomendation.price != undefined && <Text style={[Fonts.mRegular, Fonts.size16, { marginTop: ".5%" }]}>{`Price: ${recomendation.price}€`}</Text>}
      {/* <Text style={[ Fonts.text, Fonts.mBold, { marginTop: '.5%' } ]}>12345678 | In stock: 365</Text>  */}
    </View>
  </>
);

const RecommendationModal: React.FC = (): JSX.Element => {
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentScan] = useGlobalState("currentScan");
  const [currentUser] = useGlobalState("currentUser");
  const [newScanState] = useGlobalState("newScanState");
  const navigation = useNavigation();

  const [insole, setInsole] = useState<string>("");
  const [recomendation, setRecommendation] = useState<any>();

  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const gender = currentUser ? currentUser.gender : newScanState.gender === "male" ? "m" : "f";
  const genderForInsoleFilter = gender === "f" ? "female" : "unisex";
  var newInsoleDataStructure: any = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter]

  const lowInsole = newInsoleDataStructure ? newInsoleDataStructure["low"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === 'low');
  const midInsole = newInsoleDataStructure ? newInsoleDataStructure["mid"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === 'mid');
  const highInsole = newInsoleDataStructure ? newInsoleDataStructure["high"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === 'high');

  useEffect(() => {
    if (currentMeasurements) {
      var insoleCategoryIndex = Math.min(
        insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation),
        insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)
      );
      setInsole(insoleCategories[insoleCategoryIndex]);
    }
  }, [currentMeasurements]);

  useEffect(() => {
    if (insole) {
      if (insole === "low") setRecommendation(lowInsole);
      if (insole === "mid") setRecommendation(midInsole);
      if (insole === "high") setRecommendation(highInsole);
    }
  }, [insole]);

  const handleInsoleCreationButton = async () => {
    if (currentUser) {
      navigation.navigate("CreationModal");
    }
    else {
      navigation.navigate("OrthoRegisterModal", { isInsoleCreation: true });
    }
  };

  if (recomendation)
    return (
      <View style={styles.modalWrapper}>
        <View style={styles.contentWrapper}>{insoleDetail(recomendation)}</View>
        <View style={styles.extensionWrapper}>
          <View style={styles.extensionCardContentWrapper}>
            <View style={styles.extensionCardSpacer}></View>
            <View style={styles.extensionCardContent}>
              <Text style={[Fonts.mRegular, Fonts.size18, { lineHeight: 25 }]}>{i18n.t("insole.marketingText")}</Text>
            </View>
            <View style={styles.extensionCardSpacer}></View>
          </View>
          {currentScanner.enableInsoleCreation && (
            <TouchableOpacity style={styles.feedbackButton} activeOpacity={0.6} onPress={() => handleInsoleCreationButton()}>
              <Image style={styles.feedbackIcon} source={Icons.feedbackIcon} />
              <Text style={[Fonts.mBlack, Fonts.size14]}>Create Insole</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  else return <></>;
};

export default RecommendationModal;

const styles = StyleSheet.create({
  modalWrapper: {
    height: Platform.OS === "web" ? "100%" : "90%",
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-between",
    shadowColor: "#8e8f90",
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },
  contentWrapper: {
    width: "70%",
    height: "97%",
    padding: "2%",
    backgroundColor: "#ffffff",
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  imageContainer: {
    width: "100%",
    height: Platform.OS === "web" ? "65%" : "70%",
    maxHeight: "70%",
    maxWidth: "100%",
    alignItems: "center",
  },
  insoleImage: {
    width: "80%",
    height: "100%",
    resizeMode: "contain",
  },
  descriptionWrapper: {
    height: "20%",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  detailsWrapper: {
    maxHeight: "9%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  extensionWrapper: {
    width: "30%",
    maxWidth: "30%",
    height: "97%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  extensionCardContentWrapper: {
    minHeight: "86%",
    maxHeight: "86%",
    width: "100%",
    paddingLeft: "8%",
    backgroundColor: "#f2f2f2",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  extensionCardSpacer: {
    height: "2%",
    width: "60%",
    backgroundColor: "#000000",
  },
  extensionCardContent: {
    width: "90%",
    height: "60%",
  },
  feedbackButton: {
    width: "100%",
    height: "14%",
    backgroundColor: "#ffffff",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
  },
  feedbackIcon: {
    width: "7%",
    resizeMode: "contain",
    marginRight: "1.5%",
  },
  listEntry: {
    minWidth: "50%",
    maxWidth: "50%",
    marginTop: "1%",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  checkMark: {
    height: 10,
    width: 10,
    resizeMode: "contain",
    maxHeight: 10,
    maxWidth: 10,
    marginTop: 2,
    marginRight: "1%",
  },
});
