import React from 'react';
import { Image, PixelRatio, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Icons } from '../../assets';

type Props = {
  caption?: string
  value?: string
  checked?: boolean
  route?: string,
  image: string
  onPress(route?: any): void
}

const SelectionCard: React.FC<Props> = ({ caption = undefined, value = undefined, image, route = undefined, checked = false, onPress }: Props): JSX.Element =>
  <TouchableOpacity style={styles.card} activeOpacity={.6} onPress={() => onPress(route ? route : undefined)}>
    <View style={styles.captionContainer}>
      {caption && caption.length > 0 && <Text style={styles.caption}>{caption.toUpperCase()}</Text>}
    </View>
    <View style={styles.imageContainer}>
      <Image source={typeof image === 'number' ? image : { uri: image }} style={styles.image} />
    </View>
    <View style={styles.valueContainer}>
      <View style={{ width: '20%'}} />
      {value && <Text style={styles.value}>{value.toUpperCase()}</Text>}
      <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>{checked && <Image source={Icons.checkmarkIcon} style={styles.checkmark} />}</View>
    </View>
  </TouchableOpacity>

export default SelectionCard;

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#ffffff',
    opacity: .87,
    alignSelf: 'flex-start',
    maxWidth: '40%',
    height: '90%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12 / PixelRatio.get(),
    margin: '1%',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    shadowRadius: 4 / PixelRatio.get(),
    elevation: 1
  },
  imageContainer: {
    width: '65%',
    height: '65%',
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: '75%',
    height: '75%',
    // maxHeight: '75%',
    // maxWidth: '75%',
    resizeMode: 'contain',
    marginTop: '10%'
  },
  captionContainer: {
    height: '10%',
    marginTop: '7%'
  },
  caption: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 18,
    color: '#3a3a3a'
  },
  valueContainer: {
    marginTop: '-2%',
    width: '100%',
    height: '15%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  value: {
     fontFamily: 'Montserrat-Black',
fontSize: 21,
fontWeight: '900',
    fontSize: 16,
    textAlign: 'center',
    color: '#3a3a3a',   
    width: '60%',
  },
  checkmark: {
    maxHeight: 50 / PixelRatio.get(),
    resizeMode: 'contain',
  }
});
