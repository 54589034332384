import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { scannerPlaceholder } from '../../assets';
import { ScannerConfigDTO } from '../../shared/model/types';
import { Styles } from '../../styles/common.styles';

type Props = {
  scannerInfo: ScannerConfigDTO
  onPress: (scanner: ScannerConfigDTO) => void
}

const DetectedScannerCard: React.FC<Props> = ({ scannerInfo, onPress }: Props): JSX.Element =>
  <TouchableOpacity style={styles.touchContainer} activeOpacity={.6} onPress={() => onPress(scannerInfo)}>
    <View style={Styles.card}>
      <View style={styles.imageCard}>
        <Image source={scannerPlaceholder} style={styles.imageContainer} />
      </View>
      <View style={styles.textCard}>
        <Text style={styles.scannerName}>{scannerInfo["scanner-name"]}</Text>
      </View>
    </View>
  </TouchableOpacity>;

export default DetectedScannerCard;

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: 'contain'
  },
  touchContainer: {
    height: '75%',
    width: '25%',
    backgroundColor: 'transparent'
  },
  imageCard: {
    height: '80%',
    backgroundColor: 'white'
  },
  textCard: {
    height: '20%',
    marginTop: 5,
    backgroundColor: 'rgba(255, 255, 255, .7)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scannerName: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    textAlign: 'center',
    color: '#0b0b0b',
    margin: 'auto'
  }
});
