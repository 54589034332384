import Constants from "expo-constants";

export const setEnvVariablesToGlobalState = () => {
  globalThis.env = globalThis.env || {};

  const env = {
    ONEFID_CORE_API: globalThis.env.ONEFID_CORE_API || Constants.manifest.extra.ONEFID_CORE_API || process.env.REACT_NATIVE_ONEFID_CORE_API,
    SENDGRID_KEY: globalThis.env.SENDGRID_KEY || Constants.manifest.extra.SENDGRID_KEY || process.env.REACT_NATIVE_SENDGRID_KEY,
    SENTRY_DSN: globalThis.env.SENTRY_DSN || Constants.manifest.extra.SENTRY_DSN || process.env.REACT_NATIVE_SENTRY_DSN,
    MOCK_PARTNER_TOKEN: globalThis.env.MOCK_PARTNER_TOKEN || Constants.manifest.extra.MOCK_PARTNER_TOKEN || process.env.REACT_NATIVE_MOCK_PARTNER_TOKEN,
  };

  globalThis.env = env;
  
};
