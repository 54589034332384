import React, { useState } from 'react';
import { Image, View, StyleSheet, TouchableOpacity, Text, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Fonts, Styles } from '../../styles';
import ProductService from '../../shared/services/products-service';
import ScannerCoreService from '../../shared/services/core-services';


const OrderModal: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  const [currentUser, setCurrentUser] = useGlobalState('currentUser');
  const [userToken] = useGlobalState('userToken');
  const [language] = useGlobalState('language');
  const [Colors] = useGlobalState('colors');

  const [orderMessage, setOrderMessage] = useState<string>('');
  const [orderProcessResult, setOrderProcessResult] = useState<"SUCCESS" | "FAILURE">();

  const i18n = I18N(language);
  const navigation = useNavigation();
  const gtin = params.GTIN;
  const weight = params.weigh;
  const activity = params.activit;

  const getUserAccount = (email: string, userToken?: string) => {
    ScannerCoreService.fetchUserAccount(email, userToken).then((response: any) => {
      setCurrentUser((prevState: any) => ({
        ...prevState,
        quotaRemaining: response.quota_remaining,
      }));

    })
      .catch(e => {
        console.log('Error getUserAccount:: ', e)
      })
  }

  const getUserProfile = (userToken: string) => {
    ScannerCoreService.fetchUserProfile(userToken)
      .then((response: any) => {
        setCurrentUser((prevState: any) => ({
          ...prevState,
          quotaRemaining: response.quota_remaining,
        }));
      }).catch(error => {
        console.log('ERROR getUserProfile:: ', error)
      })
  }

  const processOrder = () => {
    currentUser.quotaRemaining > 0 || currentUser.quota === null
      ? ProductService.postProductOrder(
        {
          ...currentUser.employeeNumber ? { employee_number: currentUser.employeeNumber } : { email: currentUser.email },
          gtin: gtin,
          ...weight && { weight },
          ...activity && { activity }
        })
        .then((response: any) => {
          setOrderProcessResult('SUCCESS')
          setOrderMessage(i18n.t('Order.successMessage'))
          userToken ? getUserProfile(userToken) : getUserAccount(currentUser.employeeNumber)
        })
        .catch(e => {
          console.log('ERROR processOrder ', e)
          setOrderProcessResult('FAILURE')
          setOrderMessage(i18n.t('Order.failureMessage'))
        })
      : [
        userToken ? getUserProfile(userToken) : getUserAccount(currentUser.employeeNumber),
        setOrderProcessResult('FAILURE'),
        setOrderMessage(i18n.t('Order.cotaFailureMessage'))
      ]
  }

  const orderConfirm = () => {
    return (
      <View style={styles.content}>
        <View style={styles.iconsContainer}>
          <Image style={styles.iconWrapper} source={Icons.askIcon} />
        </View>
        <Text style={[Fonts.textBold, Fonts.center]}>{i18n.t('Order.confirmMessage')}</Text>
        <View style={{ width: '100%', height: '35%', justifyContent: 'space-evenly' }}>
          <TouchableOpacity style={Styles.submitButtonDark} onPress={() => processOrder()} >
            <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t('yes')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.goBack(null)}>
            <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('no')}</Text>
          </TouchableOpacity>
        </View>
      </View>)
  }

  const orderSuccess = () => {
    return (
      <View style={styles.content}>
        <View style={styles.iconsContainer}>
          <Image style={styles.iconWrapper} source={Icons.checkmarkIconCircle} />
        </View>
        <Text style={[Fonts.pageSubTitle, Fonts.center]}>{i18n.t('Order.success')}!</Text>
        <Text style={[Fonts.textBold, Fonts.center]}>{orderMessage}</Text>
        <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.goBack(null)}>
          <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('goBack')}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  const orderFailure = () => {
    return (
      <View style={styles.content}>
        <View style={styles.iconsContainer}>
          <Image style={styles.iconWrapper} source={Icons.errorIcon} />
        </View>
        <Text style={[Fonts.pageSubTitle, Fonts.center]}>Oops!</Text>
        <Text style={[Fonts.textBold, Fonts.center]}>{orderMessage}</Text>
        <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.goBack(null)}>
          <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('goBack')}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <View style={styles.wrappercontainer}>
        <View style={styles.modalWrapper}>
          <TouchableOpacity style={styles.closeButton} onPress={() => navigation.goBack(null)}>
            <Image source={Icons.closeIconSquare} style={styles.iconContainer} />
          </TouchableOpacity>
          <View style={styles.container}>
            {!orderProcessResult && orderConfirm()}
            {orderProcessResult === 'SUCCESS' && orderSuccess()}
            {orderProcessResult === 'FAILURE' && orderFailure()}
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>);
};

export default OrderModal;

const styles = StyleSheet.create({
  wrappercontainer: {
    flex: 1,
    flexDirection: 'column',
    marginTop: '14%',
    marginRight: '11%',
  },
  modalWrapper: {
    width: '30%',
    height: '64%',
    alignSelf: 'flex-end',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'space-evenly',
  },
  closeButton: {
    width: '15%',
    position: 'absolute',
    right: 0,
    top: '-20.5%',
    zIndex: 10
  },
  iconContainer: {
    width: '100%',
    resizeMode: 'contain'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '70%',
    height: '100%',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  iconsContainer: {
    width: '50%',
    height: '25%',
    alignSelf: 'center'
  },
  iconWrapper: {
    flex: 1,
    resizeMode: 'contain',
    alignSelf: 'center',
    //top:'-10%'
  },
  button: {
    width: 150,
    height: 38,
    top: '5%',
    borderRadius: 17,
    borderColor: '#707070',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'rgba(255, 255, 255, .5)'
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: 'uppercase'
  },

});
