import * as _ from 'lodash';
import { SCANNER_HOST_PORT } from '../configuration';
import * as Network from 'expo-network';
import { ScannerConfigDTO } from '../model/types';
import Axios from 'axios';


export const getLocalIP = async () => {
  return await Network.getIpAddressAsync()
};

export const scanNetwork = (ip: string, setScanners?: (scanners: any) => void, setProgress?: (progress: any) => void): Promise<any> => {
  const foundDevices: ScannerConfigDTO[] = [];
  const promises: Promise<any>[] = [];

  if (ip === 'localhost')
    promises.push(
      Axios.get('http://localhost:3000/api/v1/host', { timeout: 5000 })
        .then(response => {
          response.data['scanner-address'] = `http://localhost`
          foundDevices.push(response.data);
          if (setScanners) setScanners((scanners: ScannerConfigDTO[]) => [...scanners, response.data]);
        })
        .catch(error => console.log('ERROR: ', error.message))
    )
  else {
    const ipParts = `${ip}`.split('.');
    // const keys = [...Array(255).keys()];
    const scannedUrls: string[] = [];

    [...Array(255).keys()].forEach((i: number, index: number) => {
      const url = `http://${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.${i}:${SCANNER_HOST_PORT}/api/v1/host`;
      if (i > 1 && !_.includes(scannedUrls, url)) {
        promises.push(
          Axios.get(url, { timeout: 5000 })
            .then(response => {
              response.data['scanner-address'] = `http://${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.${i}`
              console.log('Found Scanner: ', response.data);
              foundDevices.push(response.data);
              if (setScanners) setScanners((scanners: ScannerConfigDTO[]) => [...scanners, response.data]);
              return response.data;
            })
            .catch(error => console.log('ERROR: ', url, error.message))

        );
      }
      scannedUrls.push(url);
    });
  }
  return Promise.all(promises).then(() => { return foundDevices });
};
