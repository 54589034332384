import React from 'react';
import { Image, View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { CrossProduct } from './CrossProductCard';
import { I18N } from '../../shared/util';
import { useGlobalState } from '../../shared/state';

interface Props {
    crossProduct: CrossProduct;
  }

const CrossProductExtension = ({crossProduct}:Props): JSX.Element => {
    const navigation = useNavigation();
    const [language] = useGlobalState("language");
    const i18n = I18N(language);

    return (
        <View style={styles.recommendationCrad} >
            <View style={styles.recommendationHeaderContainer}>
                <Text style={styles.recommendationCardNameText}>{i18n.t('recommendedForYou')}</Text>
                <View style={styles.seperator}></View>
            </View>
            <View style={styles.recommendationDetailContainer}>
            <View style={styles.imageWrapper}>
                <Image source={{ uri: crossProduct.image }} style={styles.imageContainer} />
                </View>
                {crossProduct.name && <Text style={styles.productCardNameText}>{crossProduct.name}</Text>}
                {crossProduct.mpn && <Text style={styles.productCardPriceText}>{crossProduct.mpn}</Text>}
                {crossProduct.price && <Text style={styles.productCardPriceText}>{`${i18n.t('price')}: ${crossProduct.price}€`}</Text>}
            </View>
            <TouchableOpacity style={styles.basketContainer} onPress={() => Platform.OS === 'web' ? navigation.navigate('CrossProductDetail', { crossProduct }): navigation.navigate('CrossProductDetailModal', {crossProduct})}>
            <View style={styles.basketContainerView}>
                <Image source={Icons.feedbackIcon} style={styles.feedbackIcon}></Image>
                <Text style={styles.basketContainerText}>{i18n.t('moreDetails')}</Text>
            </View>
            </TouchableOpacity>

        </View >)
}

export default CrossProductExtension;

const styles = StyleSheet.create({
    recommendationCrad: {
        height: '98.5%',
        borderWidth: 0,
    },
    recommendationHeaderContainer: {
        height: '15%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: 10,
        width: '100%'
    },
    seperator: {
        width: '50%',
        height: '20%',
        backgroundColor: 'black',
        alignSelf: "flex-start"
    },
    recommendationDetailContainer: {
        height: '69%',
        flexWrap:'wrap',
        padding: 10
    },
    recommendationCardNameText: {
        fontFamily: 'Montserrat-Black',
        fontSize: 18,
        fontWeight: '900',
    },

    feedbackIcon: {
        width: '7%',
        height: '20%',
        marginRight: '2%'
    },
    imageWrapper:{
        width:'100%',
        height:"80%",
        alignContent:'center',
        flexWrap:'wrap',
        // backgroundColor:'white'
    },
    imageContainer: {
        width:Platform.OS==='web'? "80%" :'100%',
        height:Platform.OS==='web'? "80%" :'100%',
        resizeMode: 'contain',
        transform: [{ rotate: '-90deg' }]

    },
    productCardNameText: {
        textAlign: "left",
        fontFamily: 'Montserrat-Black',
        fontSize: 16,
    },
    productCardPriceText: {
        fontFamily: 'Montserrat-SemiBold',
        fontSize: 14
    },
    basketContainer: {
        position: 'absolute',
        right: 0,
        bottom: -7,
        borderWidth: 0.5,
        borderColor: '#d6d7da',
        shadowColor: "#000",
        shadowOffset: {
            width: 0.2,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 1,
        elevation: 1,
        zIndex: -10,
        height: '17%',
        width: '100%',
        backgroundColor: 'transparent'
    },

    basketContainerView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    basketContainerText: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 16,
        fontWeight: '900'
    },
})