import { View, ImageBackground } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { greenDot, Icons, background } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import ScannerCoreService from '../../shared/services/core-services';
import CustomTextInput from '../../components/Text/CustomTextInput';
import validate from '../Auth/FormValidation';
import CustomCheckBox from '../../components/Checkbox/CustomCheckBox';

const AddEmailScreen: React.FC = (): JSX.Element => {
	const [currentUser] = useGlobalState('currentUser');

	const [currentScanner] = useGlobalState('currentScanner');
	const [language] = useGlobalState('language');
	const [Colors] = useGlobalState('colors');
	const [offlineErrorMessage, setOfflineErrorMessage] = useState({ email: '', employeeNumber: '', password: '' });
	const [form, setValues] = useState({ email: '', employeeNumber: '', password: '' });
	const [userInfo, setUserInfo] = useState<string | undefined>(undefined);
	const [submitted, setSubmitted] = useState(false);
	const [emailIsSaved, setEmailIsSaved] = useState<boolean>(false);
	const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
	const [userToken] = useGlobalState('userToken');
	const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
	const i18n = I18N(language);
	const navigation = useNavigation();

	const onAddEmail = () => {
		setSubmitted(true);
		if (form.email && termsAndConditions) {
			setErrorCode(undefined)
			ScannerCoreService.patchUserEmailByEmployeeNumber(currentUser.employeeNumber, form.email, termsAndConditions, currentScanner['scanner-token']).then(response => {
				if(response.email === form.email) {
					setErrorCode(undefined);
					setEmailIsSaved(true);
				}
			}).catch(error => {
				for (var prop in error) {
					error[prop][0].code === 'unique' ? setErrorCode(error[prop][0].code) : setErrorCode('genericError')
				}
			})
		} else {
			setErrorCode('provideAllFields')
		}
	}

	const getCheckBoxValue = () => {
		setTermsAndConditions(!termsAndConditions);
	};

	const getInputValue = (text: string, type: string) => {
		return type === 'password'
			? setValues({ email: form.email, employeeNumber: form.employeeNumber, password: text })
			: type === 'employeeNumber'
				? setValues({ email: form.email, employeeNumber: text, password: form.password })
				: setValues({ email: text, employeeNumber: form.employeeNumber, password: text });
	};

	useEffect(() => {
		setOfflineErrorMessage(validate(form));
		setUserInfo(userToken
			? currentUser.firstName.length > 0 && currentUser.lastName.length > 0
				? `${currentUser.firstName} ${currentUser.lastName}`
				: currentUser.email
			: currentUser && currentUser.employeeNumber
				? currentUser.employeeNumber
				: undefined)
		if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv" && currentUser) setUserInfo(`${currentUser.firstName} ${currentUser.lastName}`);
	}, [form])

	return (
		<ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
			<LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
				<View style={styles.container}>
					<View style={styles.content}>
						<View style={styles.iconsContainer}>
							<Image style={styles.greenDotWrapper} source={greenDot} />
							<Image style={styles.iconWrapper} source={Icons.loginIconOutlined} />
						</View>
						<Text style={styles.userInfoText}> {userInfo} </Text> 
						<View style={styles.divider} />
						{!emailIsSaved ? (
							<>
								<Text style={styles.logoutModalText}>{i18n.t('auth.addEmailInfo')}</Text>						
								<View style={styles.inputWrapper}>
									<CustomTextInput parentCallback={getInputValue} errorValidation={offlineErrorMessage.email} submitted={submitted} placeholder={i18n.t('auth.emailPlaceholder')} />
									<CustomCheckBox text={i18n.t("auth.termsAndConditions")[0]} submitted={submitted} parentCallback={getCheckBoxValue} termsAndConditions={termsAndConditions} />
								</View>
								<TouchableOpacity style={[styles.submitButton, { backgroundColor: Colors.mainColorDark.color,  }]} onPress={() => onAddEmail()}>
										<Text style={[styles.buttonText, { color: '#ffffff' }]}>{i18n.t('auth.confirm')}</Text>
								</TouchableOpacity>
							</>
							) : (
								<View style={styles.content}>
									<Text style={styles.userInfoText}> {i18n.t('auth.confirmEmailAddedMessage')} </Text> 
									<Text style={styles.userInfoText}> {i18n.t('auth.emailAddedInstructions')} </Text> 
									<TouchableOpacity style={[styles.submitButton, { backgroundColor: Colors.mainColorDark.color,  }]} onPress={() => navigation.goBack()}>
										<Text style={[styles.buttonText, { color: '#ffffff' }]}>{i18n.t('goBack')}</Text>
									</TouchableOpacity>
								</View>
							)
						}
						{errorCode && <Text style={styles.errorText}>{i18n.t(`auth.${errorCode}`)}</Text>}
					</View>					
				</View>
			</LayoutWrapper>
		</ImageBackground>
	);
};

export default AddEmailScreen;

const styles = StyleSheet.create({
	container: {
		width: '70%',
		height: '90%',
		alignSelf: 'center',
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	content: {
		width: '50%',
		height: '80%',
		justifyContent: 'space-evenly',
		alignSelf: 'center',
	},
	iconsContainer: {
		width: '45%',
		height: '25%',
		alignSelf: 'center',
	},
	greenDotWrapper: {
		alignSelf: 'flex-end',
		width: '15%',
		height: '15%',
		resizeMode: 'contain',
	},
	iconWrapper: {
		width: '75%',
		height: '70%',
		flex: 1,
		resizeMode: 'contain',
		alignSelf: 'center',
	},
	divider: {
		width: '90%',
		alignSelf: 'center',
		borderTopColor: '#707070',
		borderTopWidth: 1,
	},
	logoutModalText: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 16,
		textAlign: 'center',
		fontWeight: 'normal',
	},
	userInfoText: {
		textAlign: 'center',
		fontFamily: 'Montserrat-Regular',
		fontSize: 16,
		fontWeight: 'normal',
	},
	buttonText: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 12,
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	errorText: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 12,
		textAlign: 'center',
		textTransform: 'uppercase',
		color: 'red',
		marginTop: '4%'
	},
	inputWrapper: {
		height: '25%',
		justifyContent: 'center',
	},
	submitButton: {
		width: 195,
		height: 42,
		borderRadius: 17,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		backgroundColor: '#041b3b'
	},
});
