import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ImageBackground, Image } from "react-native";
import { useGlobalState } from "../../shared/state";
import CustomTextInput from "../../components/Text/CustomTextInput";
import { getValidIndex, I18N, validateEmail } from "../../shared/util";
import { useNavigation } from "@react-navigation/native";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { Icons, background } from "../../assets";
import { Styles } from "../../styles";
import { DatePickerModal } from "react-native-paper-dates";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ScannerCoreService from "../../shared/services/core-services";
import { addItemToCart, addNewCart, findProductBySize, findProductImageBySize, getStandardCartId } from "../Order/cartFunctions";
import ProductService from "../../shared/services/products-service";
import _ from "lodash";
import { Cart } from "../../shared/model/types";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";

const OrthoRegisterModal: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [currentScan, setCurrentScan] = useGlobalState("currentScan");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [, setCurrentUser] = useGlobalState("currentUser");
  const [language] = useGlobalState("language");
  const [Colors] = useGlobalState("colors");
  const [isOrthoOrder] = useGlobalState("isOrthoOrder");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const [carts, setCarts] = useGlobalState("carts");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [firstName, setfirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [employeeNumber, setEmployeeNumber] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [dateOfBirth, setdateOfBirth] = useState<Date>();
  const [date, setDate] = useState();
  const [open, setOpen] = useState(false);
  const today = new Date();
  const isInsoleCreation = params && params.isInsoleCreation;
  const includeInsole = params && params.INCLUDE_INSOLE;
  const isInsoleOrder = params && params.isInsoleOrder;
  const insoleGtin = params && params.insoleGtin;
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [submitError, setSubmitError] = useState<boolean>(false);
  const isElten = currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten";
  const storeID = customerStoreID || currentScanner.storeid;

  const insoleCategoryIndex = currentMeasurements
    ? Math.min(
        getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
        getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
      ) === Infinity
      ? 1 // If both indices are -1, return 1
      : Math.min(
          getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
          getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
        )
    : 1;

  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      setdateOfBirth(params.date);
    },
    [setOpen, setDate]
  );

  const cartData: Cart = {
    type: "standard",
    store_id: storeID,
    ...(employeeNumber
      ? { employee_number: employeeNumber }
      : email
      ? { email: email }
      : { anonymous_user_uuid: currentScan && currentScan.customer_uuid }),
    items: [],
  };

  const cartItemData = {
    type: "shoe",
    gtin: selectedShoe.gtin,
    itemName: selectedShoe.name,
    itemIcon: selectedShoe.image_url
  };

  const insoleCartItemData = {
    type: "standard-insole",
    additional_product_gtin: isElten ? insoleGtin : insoleCategories[insoleCategoryIndex],
    itemName: (currentScanner.insoleRecommendation.insoles.female || currentScanner.insoleRecommendation.insoles.unisex) && findProductBySize(currentScanner.insoleRecommendation.insoles, insoleGtin),
    itemIcon: (currentScanner.insoleRecommendation.insoles.female || currentScanner.insoleRecommendation.insoles.unisex) && findProductImageBySize(currentScanner.insoleRecommendation.insoles, insoleGtin),
  };

  const handleRedirectToCartOverview = async () => {
    if (!_.isEmpty(carts)) {
      let cartUuid
      if(getStandardCartId(carts)){
        const cart = getStandardCartId(carts);
        cartUuid = cart;
      } else {
        const newCart = await ProductService.createCart(cartData);
        cartUuid = newCart.uuid;
        // Update the local state with the new cart
        addNewCart(setCarts, cartUuid, cartData);
      }
      

      if (isInsoleOrder) {
        addItemToCart(setCarts, cartUuid, insoleCartItemData);
      } else {
        addItemToCart(setCarts, cartUuid, cartItemData);
        if (includeInsole) {
          addItemToCart(setCarts, cartUuid, insoleCartItemData);
        }
      }
      navigation.navigate("CartModal");
    } else {
      // If no carts exist, create a new cart first
      try {
        const newCart = await ProductService.createCart(cartData);
        const cartUuid = newCart.uuid;

        // Update the local state with the new cart
        addNewCart(setCarts, cartUuid, cartData);

        if (isInsoleOrder) {
          addItemToCart(setCarts, cartUuid, insoleCartItemData);
        } else {
          addItemToCart(setCarts, cartUuid, cartItemData);
          if (includeInsole) {
            addItemToCart(setCarts, cartUuid, insoleCartItemData);
          }
        }
        navigation.navigate("CartModal");
      } catch (error) {
        console.error("ERROR creating cart ", error);
      }
    }
  }
  const onSubmit = () => {
    let valid = true;
    setSubmitError(false);

    if (!firstName) {
      setFirstNameError(i18n.t("auth.firstNameRequired"));
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError(i18n.t("auth.lastNameRequired"));
      valid = false;
    } else {
      setLastNameError("");
    }

    if (!dateOfBirth) {
      setDateOfBirthError(i18n.t("auth.dateOfBirthRequired"));
      valid = false;
    } else {
      setDateOfBirthError("");
    }

    if (!validateEmail(email)) {
      setEmailError(i18n.t("auth.emailInvalid"));
    }

    const assignScanToUser = () => {
      ScannerCoreService.postRegistrationWithScan(email ? email : employeeNumber, currentScan.customer_uuid, email ? true : false)
        .then()
        .catch((error) => {
          console.log("ERROR ======", error);
        });
    };

    if (valid) {
      if (employeeNumber) {
        ScannerCoreService.postRegistrationWithUserData(undefined, employeeNumber, firstName, lastName, dateOfBirth, customerStoreID || null)
          .then(async (response: any) => {
            setCurrentUser((prevState: any) => ({
              ...prevState,
              uuid: response.uuid,
              employeeNumber,
              firstName,
              lastName,
              dateOfBirth: dateOfBirth.toLocaleDateString(),
            }));
            setCurrentScan((prevState: any) => ({
              ...prevState,
              customer_uuid: response.uuid,
            }));
            assignScanToUser();
            if (isOrthoOrder) {
              navigation.navigate("OrthopedicInsoleOptionsModal")
            } else if (isInsoleCreation) {
              navigation.navigate("CreationModal");
            } else {
              handleRedirectToCartOverview();
            }
          })
          .catch(() => {
            setSubmitError(true);
          });
      } else if (email) {
        ScannerCoreService.postRegistrationWithUserData(email, undefined, firstName, lastName, dateOfBirth, customerStoreID || null)
          .then((response: any) => {
            setCurrentUser((prevState: any) => ({
              ...prevState,
              email,
              firstName,
              lastName,
              dateOfBirth: dateOfBirth.toLocaleDateString(),
            }));
            setCurrentScan((prevState: any) => ({
              ...prevState,
              customer_uuid: response.uuid,
            }));
            assignScanToUser();
            if (isOrthoOrder) {
              navigation.navigate("OrthopedicInsoleOptionsModal");
            } else if (isInsoleCreation) {
              navigation.navigate("CreationModal");
            } else { 
              handleRedirectToCartOverview();
            }
          })
          .catch(() => {
            setSubmitError(true);
          });
      } else {
        ScannerCoreService.patchAnonymousUser(currentScan.customer_uuid, firstName, lastName, dateOfBirth, customerStoreID || null)
        .then(async (response: any) => {
          setCurrentUser((prevState: any) => ({
            ...prevState,
            firstName,
            lastName,
            dateOfBirth: dateOfBirth.toLocaleDateString(),
            uuid: response.uuid
          }));
          if (isOrthoOrder) {
            navigation.navigate("OrthopedicInsoleOptionsModal");
          } else if (isInsoleCreation){
            navigation.navigate("CreationModal");
          } else {
            handleRedirectToCartOverview();
          }
        })
        .catch(() => {
          setSubmitError(true);
        });
      }
    }
  };

  useEffect(() => {
    if (firstName) {
      setFirstNameError("");
    }

    if (lastName) {
      setLastNameError("");
    }

    if (dateOfBirth) {
      setDateOfBirthError("");
    }
    if (validateEmail(email)) {
      setEmailError("");
    }
  }, [firstName, lastName, dateOfBirth, email]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          <View style={styles.formWrapper}>
            <Text style={styles.titleText}>{i18n.t("auth.register")}</Text>
            <Image source={Icons.registerIcon} style={styles.iconContainer} />
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"First Name"} errorValidation={firstNameError} parentCallback={(fName) => setfirstName(fName)} />
              {firstNameError ? <Text style={styles.errorText}>{firstNameError}</Text> : null}
            </View>
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"Last Name"} errorValidation={lastNameError} parentCallback={(lName) => setLastName(lName)} />
              {lastNameError ? <Text style={styles.errorText}>{lastNameError}</Text> : null}
            </View>
            {!isElten && <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"Email"} errorValidation="" parentCallback={(email) => setEmail(email)} />
                {emailError ? <Text style={styles.errorText}>{emailError}</Text> : null}
            </View>}
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"Employee Number"} errorValidation="" parentCallback={(employNumber) => setEmployeeNumber(employNumber)} />
              {/* {employeeNumberError ? <Text style={styles.errorText}>{employeeNumberError}</Text> : null} */}
            </View>
            <TouchableOpacity onPress={() => setOpen(true)} style={[styles.textWrapper, { paddingTop: 0 }]}>
              <View style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
                <View style={[{ marginTop: 0, paddingLeft: "4%", width: "80%", justifyContent: "center" }]}>
                  <Text style={styles.dateText}>{dateOfBirth && dateOfBirth.toLocaleDateString()}</Text>
                </View>
                <View style={{ width: "20%", height: "95%", justifyContent: "center", alignItems: "flex-end" }}>
                  <Image source={Icons.calendarIcon} style={{ width: "65%", height: "65%", resizeMode: "contain" }} />
                </View>
              </View>

              {dateOfBirthError ? <Text style={styles.errorText}>{dateOfBirthError}</Text> : null}
              <SafeAreaProvider>
                <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                  <DatePickerModal
                    validRange={{ startDate: undefined, endDate: today }}
                    endYear={today.getFullYear()}
                    locale="de"
                    mode="single"
                    saveLabel={i18n.t("SAVE")}
                    visible={open}
                    onDismiss={onDismissSingle}
                    date={date}
                    onConfirm={onConfirmSingle}
                  />
                </View>
              </SafeAreaProvider>
            </TouchableOpacity>
            {submitError ? (
              <View style={styles.errorMessageWrapper}>
                <Text style={[styles.errorText, { textAlign: "center" }]}>{i18n.t(`auth.${employeeNumber ? "employeeNumberExists" : "genericError"}`)}</Text>
              </View>
            ) : null}
            <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={onSubmit}>
              <Text style={styles.buttonText}>{i18n.t("auth.register")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default OrthoRegisterModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "space-between",
  },
  modalWrapper: {
    flex: 1,
    width: "57%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  closeIconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  iconContainer: {
    width: "10%",
    height: "10%",
    resizeMode: "contain",
    alignSelf: "center",
    margin: "2%",
  },
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  formWrapper: {
    width: "90%",
    height: "90%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  textWrapper: {
    width: "80%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "2%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignSelf: "center",
  },
  errorMessageWrapper: {
    width: "80%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "2%",
    alignSelf: "center",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "2%",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  dateText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#000",
    textTransform: "uppercase",
    alignSelf: "flex-start",
  },
  errorText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: "red",
    paddingLeft: "4%",
  },
});
