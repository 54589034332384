import React, { ReactNode } from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { Icons } from '../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { Fonts } from '../../styles';

type Props = {
  children?: ReactNode;
  title?: string;
};

const MeasurementScreenModalWrapper: React.FC<Props> = ({ children, title }: Props): JSX.Element => {
  const navigation = useNavigation();
  const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');

  return (
      <View style={styles.container}>
        <View style={styles.titleStyle}>
          {title && <Text style={styles.titeText}> {title} </Text>}
        </View>
        <View style={styles.modalWrapper}>
          <TouchableOpacity style={styles.closeButton} onPress={() => [setModalIsDisplayed(false), navigation.goBack(null)]}>
            <Image source={Icons.closeIconSquare} style={styles.iconContainer} />
          </TouchableOpacity>
          {children}
        </View>
      </View>
  );
};

export default MeasurementScreenModalWrapper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    marginTop: '10%',
    marginRight: '5%',
  },
  modalWrapper: {
    width: '45%',
    height: '77%',
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    opacity: 0.97,
    justifyContent: 'space-between',
    paddingLeft: '2%',
    marginRight: '1%',
  },
  titleStyle: {
    marginBottom: '2%',
    height: '5%',
  },
  closeButton: {
    width: '15%',
    position: 'absolute',
    right: 0,
    top: '-12.5%',
    zIndex: 10
  },
  iconContainer: {
    width: '100%',
    resizeMode: 'contain'
  },
  titeText: {
    ...Fonts.mBlack,
    ...Fonts.center,
    fontSize: 21,
    fontWeight: '900',
    textTransform: 'uppercase'
  }
});
