import I18n from '../../i18nSets/i18n';

export default function validate(values: any) {
	let errors = { email: '', employeeNumber:'', password: '' };
	if (values.email.length <= 0) {
		errors.email = `*${I18n.t('auth.emailRequired', { locale: I18n.locale })}`;
	} else if (!/\S+@\S+\.\S+/.test(values.email)) {
		errors.email = `*${I18n.t('auth.emailInvalid', { locale: I18n.locale })}`;
	}
	if (values.password.length <= 0) {
		errors.password = `*${I18n.t('auth.passwordRequired', { locale: I18n.locale })}`;
	}
	if (values.employeeNumber.length <= 0) {
		errors.employeeNumber = `*${I18n.t('auth.employeeNumberRequired', { locale: I18n.locale })}`;
	}

	return errors;
}
