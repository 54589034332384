import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View, ActivityIndicator, Platform } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Icons } from "../../assets";
import CustomTextInput from "../../components/Text/CustomTextInput";
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from "../../shared/state";
import { generateUuidv4, I18N, mapNewInsoleRecommendation } from "../../shared/util";
import validate from "./FormValidation";
import CustomCheckBox from "../../components/Checkbox/CustomCheckBox";
import ScannerCoreService from "../../shared/services/core-services";
import { getMatchedproducts } from "../../shared/products";
import { ScannerConfigDTO } from "../../shared/model/types";
import ScannerService from "../../shared/services/scanner-service";
import API from "../../shared/api";

type Props = {
  title?: string;
  isLogin?: boolean;
};

const Form: React.FC<Props> = ({ title, isLogin }: Props): JSX.Element => {
  const [, setMatchedProducts] = useGlobalState("matchedProducts");
  const [, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [, setAllProducts] = useGlobalState("allProducts");
  const [, setUserToken] = useGlobalState("userToken");
  const [, setCurrentMeasurements] = useGlobalState("currentMeasurements");
  const [, setScanfiles] = useGlobalState("scanfiles");
  const [currentScanner, setCurrentScanner] = useGlobalState("currentScanner");
  const [language] = useGlobalState("language");
  const [currentScan, setCurrentScan] = useGlobalState("currentScan");
  const [currentUser, setCurrentUser] = useGlobalState("currentUser");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [Colors] = useGlobalState('colors');

  const [submitted, setSubmitted] = useState(false);
  const [offlineErrorMessage, setOfflineErrorMessage] = useState({ email: "", employeeNumber: "", password: "" });
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [form, setValues] = useState({ email: "", employeeNumber: "", password: "" });
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [registerToNewsletter, setRegisterToNewsletter] = useState<boolean>(false);
  const [displayEmployeeNumberForm, setDisplayEmployeeNumberForm] = useState<boolean>(currentScanner && currentScanner["mandatoryLogin"] ? true : false);
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  const i18n = I18N(language);
  const navigation = useNavigation();
  const formIcon = isLogin ? Icons.loginIconOutlined : Icons.registerIcon;

  const selectScanner = (scanner: ScannerConfigDTO) => {
    AsyncStorage.removeItem("authtoken");
    AsyncStorage.setItem("authtoken", scanner["scanner-token"]);
    ScannerService.getScannerInfo(scanner["scanner-token"])
      .then(res => {
        setCurrentScanner(Object.assign(res, scanner));
      })
      .catch(e => console.log("ERROR getScannerInfo :", e));
  };

  const onSubmit = () => {
    setSubmitted(true);
    if (isLogin && displayEmployeeNumberForm && offlineErrorMessage.employeeNumber.length <= 0) loginwithEmployeeNumber();
    if (isLogin && offlineErrorMessage.email.length <= 0 && offlineErrorMessage.password.length <= 0) login();
    if (!isLogin && offlineErrorMessage.employeeNumber.length <= 0 && termsAndConditions) register();
  };

  const getUserMeasurements = (email: string, userToken?: string) => {
    ScannerCoreService.fetchUserMeasurements(email, userToken)
      .then((Measurement: any) => {
        const condition: string = Object.keys(Measurement.left.standing).length > 0 ? "standing" : "sitting";
        Measurement.left.standing.length_foot != null
          ? [
            setCurrentMeasurements({
              measurements: [
                {
                  condition,
                  side: "Left",
                  measurements: condition === "standing" ? Measurement.left.standing : Measurement.left.sitting,
                  categorized_measurements: condition === "standing" ? Measurement.left.standing.categorized_measurements : Measurement.left.sitting.categorized_measurements,
                  plantar_image: condition === "standing" ? Measurement.left.standing.plantar_image : Measurement.left.sitting.plantar_image,
                  insole_recommendation: condition === "standing" ? mapNewInsoleRecommendation(Measurement.left.standing.foot_type_category) : mapNewInsoleRecommendation(Measurement.left.sitting.foot_type_category),
                  colored_sole: condition === "standing" ? Measurement.left.standing.colored_sole : Measurement.left.sitting.colored_sole
                },
                {
                  condition,
                  side: "Right",
                  measurements: condition === "standing" ? Measurement.right.standing : Measurement.right.sitting,
                  categorized_measurements: condition === "standing" ? Measurement.right.standing.categorized_measurements : Measurement.right.sitting.categorized_measurements,
                  plantar_image: condition === "standing" ? Measurement.right.standing.plantar_image : Measurement.right.sitting.plantar_image,
                  insole_recommendation: condition === "standing" ? mapNewInsoleRecommendation(Measurement.right.standing.foot_type_category) : mapNewInsoleRecommendation(Measurement.right.sitting.foot_type_category),
                  colored_sole: condition === "standing" ? Measurement.right.standing.colored_sole : Measurement.right.sitting.colored_sole
                }
              ]
            }),
            navigation.goBack(null),
            navigation.push("Measurements")
          ]
          : [navigation.goBack(null), navigation.navigate("Home")];
      })
      .catch(error => {
        navigation.goBack(null);
        navigation.navigate("Home");
        setLoginInProgress(false);
        console.log("ERROR getUserMeasurements: ", error);
      });
  };

  const assignScanToUser = () => {
    ScannerCoreService.postRegistrationWithScan(form.employeeNumber, currentScan.customer_uuid, false)
      .then(() => navigation.navigate("RegisterConfirmation"))
      .catch(error => {
        console.log("ERROR assignScanToUser: ", error);
        setErrorMessage(i18n.t("auth.emailExists"));
      });
  };

  const register = () => {
    ScannerCoreService.postRegistration(form.employeeNumber, termsAndConditions)
      .then(() => {
        if (currentScan) {
          assignScanToUser();
        }
        if (registerToNewsletter) {
          ScannerCoreService.postRegisterToNewsletter(form.employeeNumber)
            .then((res: any) => {
              console.log("res", res);
            })
            .catch(error => {
              console.log("ERROR register: ", error);
            });
        } else navigation.navigate("RegisterConfirmation");
      })
      .catch(error => {
        console.log("ERROR register: ", error);
        setErrorMessage(i18n.t("auth.emailExists"));
      });
  };

  const getUserProfile = (userToken: string) => {
    ScannerCoreService.fetchUserProfile(userToken)
      .then((response: any) => {
        setCurrentUser({
          email: response.email,
          uuid: response.uuid,
          firstName: response.first_name,
          lastName: response.last_name,
          gender: response.gender,
          quota: response.order_quota,
          quotaRemaining: response.quota_remaining,
          safetyClass: response.safety_class
        });
      })
      .catch(error => {
        console.log("ERROR getUserProfile: ", error);
        setLoginInProgress(false);
      });
  };

  const getUserAccount = (
    email: string,
    safetyClass?: string,
    firstName?: string,
    lastName?: string
  ) => {
    ScannerCoreService.fetchUserAccount(email)
      .then((response: any) => {
        setCurrentUser((prevState: any) => ({
          ...prevState,
          employeeNumber: response.employee_number,
          quota: response.quota,
          quotaRemaining: response.quota_remaining,
          safetyClass: response.safety_class || safetyClass,
          email: response.email,
          uuid: response.uuid,
          firstName: response.first_name || firstName,
          lastName: response.last_name || lastName,
          dateOfBirth: response.birth_date,
          gender: response.gender
        }));
        getUserMeasurements(email);
      })
      .catch(e => {
        console.log("ERROR getUserAccount: ", e);
        setErrorMessage(i18n.t("auth.unableToLogin"));
        setLoginInProgress(false);
      });
  };

  const getExternalUserAccount = (employeeNumber: string) => {
    API.get(`https://ssvkroschkepov-dev.outsystemsenterprise.com/SHOEPoint_BE/rest/SP_RESTAPI/GetStaffData?StaffID=${employeeNumber}&StandortID=${currentScanner.kDebitor}`, {
      auth: {
        username: "SHOEpoint",
        password: "hCt73mGCDgUQ733",
      }
    })
      .then((response: any) => response.StaffData)
      .then((response: any) => {
        if (response.ShoePass) {
          setCurrentUser({
            externeID: response.ExternalID
          })
          getUserAccount(
            response.ShoePass,
            response.SK_Schuhe,
            response.Vorname,
            response.Name
          );
        } else {
          const uuidv4 = generateUuidv4();
          setCurrentUser({
            employeeNumber: uuidv4,
            externeID: response.ExternalID,
            firstName: response.Vorname,
            lastName: response.Name,
            gender: "m",
            quota: null,
            quotaRemaining: null,
            safetyClass: response.SK_Schuhe
          });
          navigation.goBack(null);
          navigation.navigate("Home");
        }
      })
      .catch(error => {
        console.log("ERROR getUserProfile: ", error);
        setErrorMessage(i18n.t("auth.unableToLogin"));
        setLoginInProgress(false);
      });
  };

  const loginwithEmployeeNumber = () => {
    setLoginInProgress(true);
    setCurrentMeasurements(undefined);
    setMatchedProducts(undefined);
    setInitialMatchedProducts(undefined);
    setCurrentScan(undefined);
    setAllProducts([]);
    currentScanner.partner?.toLowerCase() === "ssv" ? getExternalUserAccount(form.employeeNumber) : getUserAccount(form.employeeNumber);
  };

  const login = () => {
    if (currentScanner) {
      setLoginInProgress(true);
      ScannerCoreService.postLogin(form.email, form.password)
        .then(result => {
          setUserToken(result.token);
          setCurrentMeasurements(undefined);
          setMatchedProducts(undefined);
          setInitialMatchedProducts(undefined);
          setScanfiles([]);
          setCurrentScan(undefined);
          setAllProducts([]);
          getUserProfile(result.token);
          getUserMeasurements(form.email, result.token);
        })
        .catch(error => {
          console.log("ERROR: ", error);
          setLoginInProgress(false);
          setErrorMessage(i18n.t("auth.unableToLogin"));
        });
    } else navigation.goBack(null);
  };

  const getCheckBoxValue = () => {
    setTermsAndConditions(!termsAndConditions);
  };
  const getCheckBoxNewsletterValue = () => {
    setRegisterToNewsletter(!registerToNewsletter);
  };

  const getInputValue = (text: string, type: string) => {
    return type === "password"
      ? setValues({ email: form.email, employeeNumber: form.employeeNumber, password: text })
      : type === "employeeNumber"
        ? setValues({ email: form.email, employeeNumber: text, password: form.password })
        : setValues({ email: text, employeeNumber: form.employeeNumber, password: text });
  };

  const switchForm = () => {
    setDisplayEmployeeNumberForm(!displayEmployeeNumberForm);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    setOfflineErrorMessage(validate(form));
    if (Platform.OS === "web" && isLogin && displayEmployeeNumberForm && form.employeeNumber) loginwithEmployeeNumber();
    if (!currentScanner && form.email.includes("test_app_store@")) {
      globalThis.env.MOCK_PARTNER_TOKEN = "a492e04c25b913e58fe2bc44ebdc6883d4affddc";
      selectScanner({
        "scanner-address": "0.0.0.0",
        "scanner-name": "Mock Scanner",
        "scanner-token": "a492e04c25b913e58fe2bc44ebdc6883d4affddc"
      });
    }
  }, [form]);

  useEffect(() => {
    if (currentUser) {
      var storeID: string = customerStoreID ? customerStoreID : currentScanner["storeid"] ;
      var gender: string = currentUser.gender || "m";
      var customer_uuid = currentUser.email || currentUser.employeeNumber;
      var lastwidthcategoryMode: string = currentScanner.lastwidthcategoryMode || "default";
      var safetyClass: string = currentUser.safetyClass || undefined;
      var showPartnerProducts: boolean = currentScanner["showPartnerProducts"] || undefined;
      var showZeroStockProducts: boolean = currentScanner["showZeroStockProducts"];
      getMatchedproducts(setAllProducts, setMatchedProducts, setInitialMatchedProducts, lastwidthcategoryMode, customer_uuid, currentScanner["attribute_name"], gender, safetyClass, storeID, showPartnerProducts, showZeroStockProducts);
    }
  }, [currentUser]);

  const loginForm = () => {
    return (
      <>
        <CustomTextInput parentCallback={getInputValue} errorValidation={offlineErrorMessage.email} submitted={submitted} placeholder={i18n.t("auth.emailPlaceholder")} />
        <CustomTextInput isPassword parentCallback={getInputValue} errorValidation={offlineErrorMessage.password} submitted={submitted} placeholder={i18n.t("auth.passwordPlaceholder")} />
      </>
    );
  };

  const loginWithEmployeeNumberForm = () => {
    return (
      <>
        <CustomTextInput
          isEmployeeNumber
          parentCallback={getInputValue}
          errorValidation={offlineErrorMessage.employeeNumber}
          submitted={submitted}
          placeholder={i18n.t("auth.employeenumberPlaceholder")}
        />
      </>
    );
  };
  const registerForm = () => {
    return (
      <>
        <CustomTextInput parentCallback={getInputValue} isEmployeeNumber errorValidation={offlineErrorMessage.employeeNumber} submitted={submitted} placeholder={i18n.t("auth.emailPlaceholder")} />
        <CustomCheckBox text={i18n.t("auth.termsAndConditions")[0]} submitted={submitted} parentCallback={getCheckBoxValue} termsAndConditions={termsAndConditions} />
        {currentScanner && currentScanner.partner && currentScanner.partner.toLowerCase().includes("shoepassion") && (
          <CustomCheckBox text={i18n.t("auth.shoepassionNewsletter")[0]} submitted={submitted} parentCallback={getCheckBoxNewsletterValue} termsAndConditions={registerToNewsletter} isNewsLetter />
        )}
      </>
    );
  };

  return (
    <View style={styles.form}>
      {title && <Text style={styles.titeText}> {title} </Text>}
      <Image source={formIcon} style={styles.imageContainer} />

      <View style={styles.inputsWrapper}>
        {isLogin && displayEmployeeNumberForm && loginWithEmployeeNumberForm()}
        {isLogin && !displayEmployeeNumberForm && loginForm()}
        {!isLogin && registerForm()}

        {errorMessage && <Text style={styles.textError}>*{errorMessage}</Text>}
      </View>
      <>
        {loginInProgress ? (
          <ActivityIndicator size="large" color={Colors.mainColorDark.color} />
        ) : (
          <TouchableOpacity style={[styles.button, {backgroundColor: Colors.mainColorDark.color}  ]} onPress={onSubmit}>
            <Text style={styles.buttonText}> {i18n.t("auth.confirm")} </Text>
          </TouchableOpacity>
        )}
        {isLogin && (
          <TouchableOpacity style={styles.switchButton} onPress={() => switchForm()}>
            <Text style={Colors.mainColorLight}>{!displayEmployeeNumberForm ? "Connect with your employee Number" : "Connect with your oneFittingID"} </Text>
          </TouchableOpacity>
        )}
      </>
    </View>
  );
};

export default Form;

const styles = StyleSheet.create({
  form: {
    flex: 1,
    justifyContent: "space-evenly"
  },
  inputsWrapper: {
    height: "35%",
    justifyContent: "space-evenly",
    marginTop: "5%"
  },
  imageContainer: {
    height: "18%",
    width: "20%",
    resizeMode: "contain",
    alignSelf: "center"
  },
  switchButton: {
    marginTop: "5%",
    backgroundColor: "transparent",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center"
    },
  textError: {
    fontSize: 12,
    fontWeight: "100",
    fontFamily: "System",
    letterSpacing: 1,
    color: "#ff3434"
  },
  titeText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase"
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase"
  }
});
