import React, { useEffect, useState } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { background } from "../../assets";
import ThreeStage from "../../components/3DScene/ThreeStage";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useGlobalState } from "../../shared/state";
import { Styles } from "../../styles";
import MeasurementsPanel from "./MeasurementsPanel";
import MeasurementsTabs from "./MeasurementsTabs";
import { I18N } from "../../shared/util";

type BasicMeasurements = {
  left: {
    length: number | undefined
    width: number | undefined
    widthHeel: number | undefined
    heightInstepPoint: number | undefined
    widthCategory: number | undefined
  };
  right: {
    length: number | undefined
    width: number | undefined
    widthHeel: number | undefined
    heightInstepPoint: number | undefined
    widthCategory: number | undefined
  };
};

const mockTabs = [
  {
    tabId: "measurements",
    caption: "measurements"
  },
  {
    tabId: "analysis",
    caption: "analysis"
  },
  {
    tabId: "more",
    caption: "more"
  }
];

const Measurements = () => {
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentScan] = useGlobalState("currentScan");
  const [currentUser] = useGlobalState("currentUser");
  const [language] = useGlobalState("language");

  const [tabId, setTabId] = useState("measurements");
  const [meshURLS, setMeshURLS] = useState<any>(undefined);
  const [meshSole, setMeshSole] = useState<any>(undefined);
  const [basicMeasurement, setBasicMeasurement] = useState<BasicMeasurements>({
    left: { length: undefined, width: undefined, widthCategory: undefined },
    right: { length: undefined, width: undefined, widthCategory: undefined }
  });

  const i18n = I18N(language);

  const switchTab = (tabId: string) => {
    setTabId(tabId);
  };

  // convert MM TO CM
  const decimalAdjuster = (floatNumber: number) => parseFloat(((floatNumber * 10) / 100).toFixed(1));

  useEffect(() => {
    if (currentScan && !meshURLS) {
      setMeshURLS({
        left: currentScan.scanfiles.find((result: any) => result.side === "left").mesh_url,
        right: currentScan.scanfiles.find((result: any) => result.side === "right").mesh_url
      });
      setMeshSole({
        left: currentScan.scanfiles.find((result: any) => result.side === "left").colored_sole,
        right: currentScan.scanfiles.find((result: any) => result.side === "right").colored_sole
      });
    }
  }, []);

  // get basic measurements from current Measurements
  useEffect(() => {
    if (currentMeasurements) {
      Object.keys(currentMeasurements.measurements).map((measure: any) => {
        if (currentMeasurements.measurements[measure].side === "Left") {
          setMeshSole((prevState: any) => ({
            ...prevState,
            left: currentMeasurements.measurements[measure].colored_sole
          }));

          setBasicMeasurement((prevState: any) => ({
            ...prevState,
            left: {
              length: decimalAdjuster(currentMeasurements.measurements[measure].measurements.length_foot),
              width: decimalAdjuster(currentMeasurements.measurements[measure].measurements.width_ball),
              widthHeel: decimalAdjuster(currentMeasurements.measurements[measure].measurements.width_heel),
              heightInstepPoint: decimalAdjuster(currentMeasurements.measurements[measure].measurements.height_instep_point),
              widthCategory: currentMeasurements.measurements[measure].measurements.width_category
            }
          }));
        } else {
          setMeshSole((prevState: any) => ({
            ...prevState,
            right: currentMeasurements.measurements[measure].colored_sole
          }));
          setBasicMeasurement((prevState: any) => ({
            ...prevState,
            right: {
              length: decimalAdjuster(currentMeasurements.measurements[measure].measurements.length_foot),
              width: decimalAdjuster(currentMeasurements.measurements[measure].measurements.width_ball),
              widthHeel: decimalAdjuster(currentMeasurements.measurements[measure].measurements.width_heel),
              heightInstepPoint: decimalAdjuster(currentMeasurements.measurements[measure].measurements.height_instep_point),
              widthCategory: currentMeasurements.measurements[measure].measurements.width_category
            }
          }));
        }
      });
    }
  }, [currentMeasurements]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false}>
        <View style={styles.container}>
          <View style={styles.measurementsPanelContainer}>
            <View style={styles.measurementsTabs}>
              <MeasurementsTabs tabs={mockTabs} activeTab={tabId} onClickHandler={switchTab} />
            </View>
            <View style={styles.measurementsPanel}>
              <MeasurementsPanel panelId={tabId} BasicMeasurements={basicMeasurement} />
            </View>
          </View>
          <View style={styles.sceneContainer}>
            {meshSole ? (
              currentScan && meshURLS ? (
                <ThreeStage leftSole={meshSole.left} leftPLY={meshURLS.left} rightPLY={meshURLS.right} rightSole={meshSole.right} />
              ) : (
                <ThreeStage leftSole={meshSole.left} rightSole={meshSole.right} email={currentUser ? (currentUser.email ? currentUser.email : currentUser.employeeNumber) : undefined} />
              )
            ) : (
              undefined
            )}
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default Measurements;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  sceneContainer: {
    flex: 1
  },
  measurementsTabs: {
    height: "12%",
    marginLeft: "-3%"
  },
  measurementsPanelContainer: {
    width: "25%",
    height: "95%",
    alignSelf: "flex-end"
  },
  measurementsPanel: {
    height: "88%",
    marginLeft: "-3%",
    backgroundColor: "rgba(255, 255, 255, .71)"
  }
});
