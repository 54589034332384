import React from 'react';
import { StyleSheet, View, KeyboardAvoidingView, Platform } from 'react-native';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import I18n from '../../i18nSets/i18n';
import BelowForm from '../Auth/BelowForm';
import Form from '../Auth/Form';

const LoginModal: React.FC = (): JSX.Element =>
  <ModalWrapper title={I18n.t('auth.login')} >
    <KeyboardAvoidingView behavior="padding" enabled keyboardVerticalOffset={Platform.OS === 'ios' ? -50 : 0} style={styles.container}>
      <View style={styles.formWrapper}>
        <Form isLogin  />
      </View>
      <BelowForm isLogin />

    </KeyboardAvoidingView>
  </ModalWrapper>;

export default LoginModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  formWrapper: {
    width: '60%',
    height: '70%',
    alignSelf: 'center'
  }
});
