import React, { useState, useEffect } from 'react';
import { Image, View, StyleSheet, Text, ActivityIndicator, TouchableOpacity, PixelRatio } from 'react-native';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import I18n from '../../i18nSets/i18n';
import { useGlobalState } from '../../shared/state';
import { Fonts } from '../../styles/font.styles';
import { Icons } from '../../assets';
import { Styles } from '../../styles';
import SizeScale from '../../components/SizeScale/SizeScale';
import * as WebBrowser from 'expo-web-browser';
import { useNavigation } from '@react-navigation/native';
import MarketingProductExtension from './MarketingProductExtension';
import { insoleCategories } from '../InsoleRecommendation/InsoleRecommendation';
import CrossProductExtension from './CrossProductExtension';

const ProductDetailModal: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState('language');
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentScanner] = useGlobalState('currentScanner');
  const [allProducts] = useGlobalState('allProducts');
  const [Colors] = useGlobalState('colors');

  const navigation = useNavigation();
  const [product] = useState<any>(params.product || undefined)
  const [warehouseBins, setWarehouseBins] = useState<string[]>([])
  const [isLoading, setLoading] = useState<boolean>(true);
  const primarySize: any = product.leading_system === 'eu' ? product.size.eu : product.leading_system === 'uk' ? product.size.uk : product.size.us
  const secondarySize: any = currentScanner.enableSecondarySize || !('enableSecondarySize' in currentScanner)
    ? product.leading_system === 'eu'
      ? product.size.secondary_eu
      : product.leading_system === 'uk'
        ? product.size.secondary_uk
        : product.size.secondary_us
    : undefined
  const price: any = product.price || undefined
  const salePrice: any = product.salePrice || undefined

  const EnableOrderButton: boolean = currentScanner.brandsToOrder && currentScanner.brandsToOrder.length > 0 ? currentScanner.brandsToOrder.toLocaleString().toLowerCase().split(',').includes(params.product.brand.toLowerCase()) ? true : false : true

  const insoleCategoryIndex = Math.min(insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation), insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation));

  // if categoryToRecommend list is empty or undefined we display isoles for all product-category 
  const isRecommendedForCategory = (): boolean => !currentScanner.insoleRecommendation.categoryToRecommend
    ? true
    : currentScanner.insoleRecommendation.categoryToRecommend.includes(product.categoryName?.toLowerCase()) || !currentScanner.insoleRecommendation.categoryToRecommend.length ?
      true
      : false

  const displayInsoleRecommendation: boolean =
    insoleCategories[insoleCategoryIndex] !== 'none' &&
    insoleCategories[insoleCategoryIndex] !== 'special' &&
    currentScanner.insoleRecommendation &&
    isRecommendedForCategory()

  useEffect(() => {
    if (product) {
      setLoading(false)
      if (currentScanner.displayStock)
        getProductWhereHouseBin()
    }
  }, []);

  const getProductWhereHouseBin = () => {
    var warehouseBinsArray: any[] = []
    allProducts.map(products => {
      products.details.map((detail: any) => {
        if (product.gtin === detail.gtin && currentScanner['storeid'] === detail.store_id)
          detail.warehouse_bin ? warehouseBinsArray.push(detail.warehouse_bin) : undefined
      })
    })
    setWarehouseBins(warehouseBinsArray)
  }

  const orderProduct = () => navigation.navigate('OrderModal', { GTIN: product?.gtin });

  const customiseProduct = () => navigation.navigate('CustomiseProductModal', { GTIN: product?.gtin });

  const openShop = (gtin?: string, size?: string) => WebBrowser.openBrowserAsync(`https://desma.expivi.com/product/perfectknit3d/?gtin=${gtin}&size=${size}`, {windowFeatures: {width:1200,height:1000}}).catch((err) => console.error('An error occurred', err));

  /**
   * @description function to render the extension card
   */
  const renderExtensionCard = () =>
    <View style={styles.extensionCard} >
      <View style={styles.advertisingProductCard}>
        {secondarySize ? <>
          <Text style={Fonts.textHuge}>{I18n.t('secondaryRecommendatiom.yourSize')}</Text>
          <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message1')}</Text>
          <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message2')} {secondarySize < primarySize ? secondarySize : primarySize} </Text>
          <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message3')} {secondarySize > primarySize ? secondarySize : primarySize}</Text>
        </>
          : <Text style={Fonts.textHuge}>OneFID - Füße scannen und Schuhe finden die passen</Text>}
          {/* : <Image source={Icons.advertisingImage} style={styles.imageContainer} />} */}
      </View>
      {currentScanner.enableExpiviShop ? <TouchableOpacity style={styles.basketContainer} onPress={() => openShop(product?.gtin, primarySize)} >
        <View style={styles.basketContainerView}>
          <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
          <Text style={styles.basketContainerText}>Open External shop</Text>
        </View>
      </TouchableOpacity>
        : currentScanner.enableOrder && EnableOrderButton ?
          <TouchableOpacity style={styles.basketContainer} onPress={() => product?.msi ? customiseProduct() : orderProduct()} >
            <View style={styles.basketContainerView}>
              <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
              <Text style={styles.basketContainerText}>{I18n.t('order now', { locale: language })}</Text>
            </View>
          </TouchableOpacity> : undefined}
    </View>

  if (isLoading) {
    return (
      <ModalWrapper>
        <View style={styles.loader}>
          <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
        </View>
      </ModalWrapper>
    )
  }
  return (
    <ModalWrapper>
      <View style={styles.container}>
        {product &&
          <View style={Styles.card} >
            <View style={styles.productCard}>
              <View style={styles.imageGallery}>
                <Image source={{ uri: product.image_url }} style={styles.productImageContainer} />
              </View>
            </View>
            <View style={styles.productCardDetails}>
              <Text style={styles.productDescriptionText}>{product.description}</Text>
              <Text adjustsFontSizeToFit style={styles.productCardNameText}>{product.name}</Text>
              {product.width_name != undefined && <Text style={styles.widthNameText}>{product.width_name}</Text>}
              {product.price != undefined &&
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.productCardPriceText}>{`${I18n.t('price')}: `}</Text>
                  {salePrice && price && salePrice < price ?
                    <>
                      <Text style={[styles.productCardPriceText, { textDecorationLine: 'line-through' }]}>{`${price}€ `}</Text>
                      <Text style={[styles.productCardPriceText, { color: 'red' }]}>{`${salePrice}€`}</Text>
                    </>
                    : <Text style={styles.productCardPriceText}>{`${price}€`}</Text>
                  }
                </View>}
              <Text style={styles.productCardManufacturerText}>{product.brand} - {product.gtin}</Text>
              {warehouseBins.length > 0 && currentScanner.displayStock && <Text style={styles.productCardPriceText}>{warehouseBins.map((w => `${w}  `))}</Text>}
            </View>
            <View style={styles.soleIconContainer}>
              <Image source={Icons.soleIcon} style={styles.soleIcon}></Image>
              <Text style={styles.soleIconText}>{product.last_width_category}</Text>
            </View>
            <View style={[styles.sizeContainer, {backgroundColor: Colors.mainColorDark.color}]}>
              <SizeScale caption={"EU"}
                valueCount={5}
                categories={secondarySize ?
                  secondarySize < primarySize ? [{ name: secondarySize, value: secondarySize }, { name: primarySize, value: primarySize }]
                    : [{ name: primarySize, value: primarySize }, { name: secondarySize, value: secondarySize }]
                  : [{ name: '', value: 0 }, { name: primarySize, value: 2 }, { name: '', value: 4 }]}
                values={{ left: 2 }}
              />
            </View>
          </View>}
      </View>
      <View style={styles.subContainer}>
        {product.crossProduct ?
          <CrossProductExtension crossProduct={product.crossProduct} />
          : displayInsoleRecommendation
            ? <MarketingProductExtension enableOrderButton productData={product} />
            : renderExtensionCard()}
      </View>
    </ModalWrapper>
  );
};

export default ProductDetailModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '65%',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    elevation: 1,
    backgroundColor: '#ffffff',
    opacity: 1
  },
  subContainer: {
    flex: 1,
    right: 0,
    position: 'absolute',
    width: '35%',
    height: '100%'
  },
  extensionCard: {
    height: '98.5%',
    margin: 0,
    borderWidth: 0,
    padding: 0,
    justifyContent: 'center'
  },
  productCard: {
    height: '65%',
    backgroundColor: 'white'
  },
  soleIconContainer: {
    height: '13%',
    width: '10%',
    position: 'absolute',
    right: '17%',
    bottom: '1%'
  },
  soleIcon: {
    flex: 1,
    resizeMode: 'contain'
  },
  soleIconText: {
    ...Fonts.mSemiBold,
    fontSize: 16,
    position: 'absolute',
    right: '37%',
    bottom: '35%',
    zIndex: 11
  },
  productImageContainer: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain'
  },
  productCardDetails: {
    height: '25%',
    width: '72%',
    paddingLeft: '3%',
    paddingBottom: '3%',
    bottom: 0
  },
  productCardNameText: {
    textAlign: "left",
    fontFamily: 'Montserrat-Black',
    fontSize: 16,
  },
  productCardPriceText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 14
  },
  productCardManufacturerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 12
  },
  sizeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '16.7%',
    width: '16%',  
    right: 0,
    bottom: 0
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '2%',
    paddingLeft: '1%'
  },
  productDescriptionText: {
    fontFamily: 'Montserrat-Light',
    fontSize: 13,
    paddingBottom: '1%'
  },
  imageContainer: {
    flex: 1,
    resizeMode: 'contain'
  },
  basketContainer: {
    position: 'absolute',
    right: 0,
    bottom: -7,
    borderWidth: 0.5,
    borderColor: '#d6d7da',
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    zIndex: -10,
    height: '17%',
    width: '100%',
    backgroundColor: 'transparent'
  },
  basketContainerView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  basketIcon: {
    width: '15%',
    height: '40%',
    resizeMode: 'contain',
    marginRight: '5%'
  },
  basketContainerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 15
  },
  advertisingProductCard: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
    height: '70%',
    width: '90%',
    bottom: '5%'
  },
  imageGallery: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  secondaryRecomendationText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 15,
    textAlign: 'center'
  },
  widthNameText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 10,
    fontWeight: '600',
    right: "-34%",
    bottom: 40,
    position: 'absolute'
  },

});
