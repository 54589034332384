import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { Styles } from '../../styles';
import { CategoryCardDTO } from '../../shared/model/types';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';

export type Props = {
  CategoryCard: CategoryCardDTO,
  onPress: (categorie: CategoryCardDTO) => void;
}

const CategoryCard: React.FC<Props> = ({ CategoryCard, onPress }: Props): JSX.Element => {
  const [language] = useGlobalState('language');
  const i18n = I18N(language);
  const categoryName: string = i18n.t(`SafetyCatalog.${CategoryCard['name'].toLocaleLowerCase()}`).includes('missing') ? CategoryCard['name'] : i18n.t(`SafetyCatalog.${CategoryCard['name'].toLocaleLowerCase()}`)

  return (
    <TouchableOpacity style={styles.touchContainer} activeOpacity={0.6} onPress={() => onPress(CategoryCard)}>
      <View style={Styles.card}>
        <View style={styles.categoryCard}>
          <Image source={{ uri: CategoryCard.image }} style={styles.imageContainer} />
        </View>
        <View style={styles.categoryCardTextWrapper}>
          <Text style={styles.categoryCardText}> {categoryName} </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default CategoryCard;

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: 'contain'
  },
  touchContainer: {
    height: '49%',
    width: '33%',
    backgroundColor: 'transparent',
    paddingTop: 2,
    paddingLeft: 2
  },
  categoryCard: {
    height: '70%',
    backgroundColor: 'white'
  },
  categoryCardTextWrapper: {
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  categoryCardText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 16,
    textTransform: 'uppercase'
  }
});
