import React from "react";
import { WebView } from "react-native-webview";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { StyleSheet } from 'react-native';
import MeasurementScreenModalWrapper from "../../components/ModalWrapper/MeasurementScreenModalWrapper";
import { useNavigation } from '@react-navigation/native';

const DataProtectionModal = (): JSX.Element => {

  const navigation = useNavigation();
  const MeasurementModal = navigation.getParam('MeasurementModal', undefined);
  const url = navigation.getParam('isNewsLetter') ? "https://www.shoepassion.de/datenschutz/" : "https://onefid.com/datenschutz-plattform/"
  //disable click on webView
  let jsCode = `document.body.addEventListener("click", function (evt) { evt.preventDefault(); });`;

  if (MeasurementModal)
    return (
      <MeasurementScreenModalWrapper >
        <WebView source={{ uri: url }} style={styles.webViewStyle} injectedJavaScript={jsCode} />
      </MeasurementScreenModalWrapper>
    );
  else
    return (
      <ModalWrapper >
        <WebView source={{ uri: url }} style={styles.webViewStyle} injectedJavaScript={jsCode} />
      </ModalWrapper>
    );
};
export default DataProtectionModal;

const styles = StyleSheet.create({
  webViewStyle: {
    marginTop: 20
  }
})
