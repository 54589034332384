import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TextInput, View } from 'react-native';
import { Icons } from '../../assets';

type Props = {
  placeholder: string;
  isPassword?: boolean;
  isEmployeeNumber?: boolean
  submitted?: boolean;
  errorValidation: string;
  parentCallback: (text: string, type: string) => void;
}

const CustomTextInput: React.FC<Props> = ({
  placeholder,
  isPassword,
  isEmployeeNumber,
  submitted,
  errorValidation,
  parentCallback
}: Props): JSX.Element => {

  const inputIcon = isPassword ? Icons.lockIcon : Icons.inboxIcon;
  const inputType = isPassword ? 'password' : isEmployeeNumber ? 'employeeNumber' : 'email';
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(errorValidation.length <= 0);
  }, [errorValidation]);

  const toProps = {
    placeholder: placeholder,
    secureTextEntry: !!isPassword,
    placeholderTextColor: 'black',
    autoCorrect: false,
    style: styles.textInput,
    autoFocus: isEmployeeNumber || errorValidation ? true : false,
    
    onChangeText: (e: any) => parentCallback(e, inputType)
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        <View style={!submitted || valid ? styles.inputWrapper : inputWrapperDanger}>
          <TextInput {...toProps} autoCapitalize="none" />
          <Image source={inputIcon} style={styles.iconContainer} />
        </View>
        {submitted && !isPassword
          ? <Image source={valid ? Icons.checkmarkIcon : Icons.errorIcon} style={styles.errorIcon} />
          : submitted && isPassword && !valid
            ? <Image source={valid ? Icons.checkmarkIcon : Icons.errorIcon} style={styles.errorIcon} />
            : undefined
        }
      </View>

      <View style={styles.textErrorWrapper}>
        {submitted && !valid && <Text style={styles.textError}>{errorValidation}</Text>}
      </View>
    </View>
  );
};

export default CustomTextInput;

const styles = StyleSheet.create({
  container: { height: '50%' },
  inputContainer: {
    width: '100%',
    height: "80%",
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  inputWrapper: {
    width: '95%',
    height: '100%',
    paddingLeft: '4%',
    paddingRight: '2%',
    backgroundColor: '#f0eff1',
    borderRadius: 6,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center'
  },
  textInput: {
    flex: 1
  },
  iconContainer: {
    width: '10%',
    height: '60%',
    resizeMode: 'contain',
    alignItems: 'flex-end'
  },
  errorIcon: {
    height: 20,
    width: 20,
    alignSelf: 'center'
  },
  textErrorWrapper: {
    height: '20%',
    width: '100%'
  },
  textError: {
    fontSize: 12,
    fontWeight: '100',
    fontFamily: 'System',
    letterSpacing: 1,
    color: '#ff3434'
  }
});
export const inputWrapperDanger = {
  width: '95%',
  height: '100%',
  paddingLeft: '4%',
  paddingRight: '2%',
  backgroundColor: '#f0eff1',
  borderRadius: 6,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  alignSelf: 'center',
  borderColor: 'red',
  borderWidth: 1
};
