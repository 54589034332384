import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useGlobalState } from '../../shared/state';

interface Props {
    data: any[],
    onPress: (value: any) => void;

}

const RadioButton = ({ data, onPress }: Props): JSX.Element => {
    const [language] = useGlobalState('language');
    const [value, setValue] = useState<any>(undefined);

    useEffect(() => {
        if (value) onPress(value)
    }, [value])
    return (
        <View>
            {data.map(res => {
                return (
                    <View key={res.key} style={styles.container}>
                        <TouchableOpacity
                            style={styles.radioCircle}
                            onPress={() => {
                                setValue(res.key)
                            }}>
                            {value === res.key && <View style={styles.selectedRb} />}
                        </TouchableOpacity>
                        <Text style={styles.radioText}>{res.text}</Text>
                    </View>
                );
            })}
        </View>
    );
}

export default RadioButton;

const styles = StyleSheet.create({
    container: {
        marginBottom: 35,
        alignItems: 'center',
        flexDirection: 'row',
    },
    radioText: {
        marginLeft: 30,
        fontSize: 20,
        color: '#000',
        fontWeight: '700'
    },
    radioCircle: {
        height: 30,
        width: 30,
        borderRadius: 100,
        borderWidth: 2,
        borderColor: '#2bc6ff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedRb: {
        width: 15,
        height: 15,
        borderRadius: 50,
        backgroundColor: '#2bc6ff',
    },
    result: {
        marginTop: 20,
        color: 'white',
        fontWeight: '600',
        backgroundColor: '#F3FBFE',
    },
});