import React from 'react';
import { ImageBackground, View, StyleSheet } from 'react-native';
import { background } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import BelowForm from '../Auth/BelowForm';
import Form from '../Auth/Form';

const Login: React.FC = (): JSX.Element => {
  const [language] = useGlobalState('language');
  const i18n = I18N(language);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
        <View style={styles.container}>
          <View style={styles.subcontainer}>
            <View style={styles.formWrapper}>
              <Form isLogin title={i18n.t('auth.login')} />
            </View>
            <BelowForm isLogin />
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};


export default Login;
const styles = StyleSheet.create({
  container: {
    width: '70%',
    height: '90%',
    alignSelf: 'center',
    backgroundColor: 'white'
  },
  subcontainer: {
    width: '70%',
    height: '90%',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  formWrapper: {
    width: '100%',
    height: '75%',
    alignSelf: 'center',

  }

})
