import React from 'react';
import { Image, ImageBackground, StyleSheet, View } from 'react-native';
import { background, defaultLogo } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useGlobalState } from '../../shared/state';
import { Styles } from '../../styles';

const Home: React.FC = (): JSX.Element => {
  const [currentScanner] = useGlobalState('currentScanner');
  const logo = currentScanner && currentScanner['scanner-logo'] ? currentScanner['scanner-logo'] : undefined;
  return (
    <ImageBackground source={background} style={Styles.backgroundImage}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false}>
        <View style={styles.logoWrapper}>
          <View style={{ flex: 1 }} />
          <Image style={styles.logo} source={logo ? { uri: logo } : defaultLogo} />
          <View style={{ flex: 1 }} />
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default Home;

const styles = StyleSheet.create({
  logoWrapper: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center'
  },
  logo: {
    width: '40%',
    height: '20%',
    resizeMode: 'contain'
  }
});
