import React, { useState } from "react";
import { Image, View, StyleSheet, TouchableOpacity, Text, ImageBackground } from "react-native";
import { background, Icons } from "../../assets";
import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import { Fonts, Styles } from "../../styles";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";
import _ from "lodash";

const Order: React.FC = (route: any): JSX.Element => {
  const { params } = route.route;
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [language] = useGlobalState("language");
  const [Colors] = useGlobalState("colors");
  const [currentScanner] = useGlobalState("currentScanner");
  const [orderMessage, setOrderMessage] = useState<string>("");

  const i18n = I18N(language);
  const navigation = useNavigation();

  const orderProcessResult = params.orderProcessResult;

  var insoleCategoryIndex = Math.min(
    insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation),
    insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)
  );
  let insoleType: number;

  if (insoleCategoryIndex) {
    switch (insoleCategories[insoleCategoryIndex]) {
      case "low":
        insoleType = 9999999999991;
        break;
      case "mid":
        insoleType = 9999999999992;
        break;
      case "high":
        insoleType = 9999999999993;
        break;
      default:
        insoleType = 9999999999992;
    }
  }

  if (orderProcessResult === "FAILURE") {
    setOrderMessage(i18n.t("Order.failureMessage"));
  } else {
    setOrderMessage(i18n.t("Order.successMessage"));
  }

  const handleGoBack = () => {
    navigation.navigate("MainApp");
  };

  const orderSuccess = () => {
    return (
      <View style={styles.content}>
        <View style={styles.iconsContainer}>
          <Image style={styles.iconWrapper} source={Icons.checkmarkIconCircle} />
        </View>
        <Text style={[Fonts.pageSubTitle, Fonts.center]}>{i18n.t("Order.success")}!</Text>
        <Text style={[Fonts.textBold, Fonts.center]}>{orderMessage}</Text>
        <TouchableOpacity style={Styles.submitButtonLight} onPress={() => handleGoBack()}>
          <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t("goBack")}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const orderFailure = () => {
    return (
      <View style={styles.content}>
        <View style={styles.iconsContainer}>
          <Image style={styles.iconWrapper} source={Icons.errorIcon} />
        </View>
        <Text style={[Fonts.pageSubTitle, Fonts.center]}>Oops!</Text>
        <Text style={[Fonts.textBold, Fonts.center]}>{orderMessage}</Text>
        <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.goBack(null)}>
          <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t("goBack")}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
        <View style={styles.container}>
          <TouchableOpacity style={styles.closeButton} onPress={() => navigation.goBack(null)}>
            <Image
              source={currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv" ? Icons.closeIconSquareSSV : Icons.closeIconSquare}
              style={styles.iconContainer}
            />
          </TouchableOpacity>
          <View style={styles.subcontainer}>
            <View style={styles.modalWrapper}>
              {orderProcessResult === "SUCCESS" && orderSuccess()}
              {orderProcessResult === "FAILURE" && orderFailure()}
            </View>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default Order;

const styles = StyleSheet.create({
  container: {
    width: "70%",
    height: "80%",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: "2%",
    backgroundColor: "white",
  },
  subcontainer: {
    width: "70%",
    height: "90%",
    alignSelf: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalWrapper: {
    width: "50%",
    height: "80%",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
  },
  closeButton: {
    width: "6%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  content: {
    width: "70%",
    height: "100%",
    justifyContent: "space-around",
    backgroundColor: "white",
  },
  iconsContainer: {
    width: "50%",
    height: "25%",
    alignSelf: "center",
  },
  iconWrapper: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
    alignSelf: "center",
    //top:'-10%'
  },
  button: {
    width: 150,
    height: 38,
    top: "5%",
    borderRadius: 17,
    borderColor: "#707070",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "rgba(255, 255, 255, .5)",
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: "uppercase",
  },
});
