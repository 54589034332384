import React, { useEffect, useState } from 'react';
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../../shared/state';
import TextTitle from '../../Text/TextTitle';

interface Props {
  square?: boolean;
  circle?: boolean;
  color: string;
  text?: string;
  icon?: ImageSourcePropType;
  navigateTo: string;
  navigateOptions?: any;
  deactivated?: boolean;
  onPress?: any;
}

const Button = ({ square, circle, color, text, icon, navigateOptions, navigateTo, deactivated = false, onPress }: Props) => {
  const [route, setRoute] = useGlobalState('currentScreen');
  const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');
  const [measurements] = useGlobalState('currentMeasurements');
  const [Colors] = useGlobalState('colors');
  const [currentRoute, setCurrentRoute] = useState<string>("Home");

  const navigation = useNavigation();

  useEffect(() => {
    const routeIndex = navigation.getState().index
    const routeName = navigation.getState().routes[routeIndex].name
    setCurrentRoute(routeName);
  }, [navigation]);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route === 'Logout') {
      let redirectTo: string = Platform.OS === 'web' ? route : measurements && currentRoute.includes('Measurement') ? 'LogoutMeasurementModal' : 'LogoutModal';
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route === 'NewScanGenderSelection' && measurements !== undefined) {
      let redirectTo: string = Platform.OS === 'web' ? 'NewScan' : currentRoute.includes('Measurement') ? 'NewScanMeasurementModal' : 'NewScanModal';
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route && route.includes('Modal')) {
      setModalIsDisplayed(true);
      navigation.push(route, options);
      setRoute(route);
    } else if (route === 'DetectedScanners') {
      navigation.replace(route, options);
      setRoute(route);
    } else if (route === 'CloseButton') {
      navigation.goBack()
    } else if (route === 'Measurements' && Platform.OS === 'web') {
      navigation.push(route, options);
      setRoute(route);
    }
    else if (route) {
      navigation.navigate(route, options);
      setRoute(route);
    }
  };
  const windowWidth = Dimensions.get('window').width;
  const circleIconContainerWidth = windowWidth < 2000 ? '55%' : '75%';
  const circleIconContainerHeight = windowWidth < 2000 ? '55%' : '95%';

  const btnColor: string = deactivated ? 'white' : currentRoute.includes(navigateTo) ? Colors.mainColorDarkHover.color : color;

  const buttonStyle = `${square ? 'square' : circle ? 'circle' : 'middle'}Button`;
  const iconContainerStyle = `${circle ? 'circleIconContainer' : 'iconContainer'}`

  const toProps = {
    // @ts-ignore
    style: [styles[buttonStyle], { backgroundColor: circle ? 'transparent' : btnColor }],
    onPress: deactivated ? undefined : onPress ? () => onPress() : () => navigate(navigateTo, navigateOptions),
    disabled: [currentRoute != navigateTo || route != navigate, currentRoute === 'DetectedScanners'] ? false : true,
  };

  const textTitleProps = {
    color: btnColor === 'white' ? 'black' : 'white',
    fontWeight: square ? '900' : '600',
    fontSize: 21,
  };

  return (
    <TouchableOpacity {...toProps}>
      {text && <TextTitle text={text} {...textTitleProps} />}
      {icon && <Image source={icon} style={[styles[iconContainerStyle], { backgroundColor: color, width: circleIconContainerWidth, height: circleIconContainerHeight }]} />}
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  squareButton: {
    width: Platform.OS === 'web' ? '7%' : '10.7%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleButton: {
    height: '100%',
    // width: '50%',
    width: '100%',
    aspectRatio: 1,
  },
  middleButton: {
    flex: 1,
    marginRight: '0.2%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    flex: 1,
    height: '38.7%',
    width: '33.9%',
    resizeMode: 'contain',
  },
  circleIconContainer: {
    // height: '95%',
    // width: '75%',
    maxHeight: "185px",
    maxWidth: "185px",
    resizeMode: 'contain',
    borderRadius: 100
  },
});
