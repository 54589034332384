/* eslint-disable */
import * as _ from "lodash";
import API from "../../api";
import { firstN } from "../../util";

const CORE_BASE_URL = globalThis.env.ONEFID_CORE_API;
const apiLevel = "v2/";

// End points
const PRODUCTS_URL = `${CORE_BASE_URL}${apiLevel}products/?page_size=5000&is_affiliate=False&gender=u`;
const MULTI_RECOMMENDATION_BY_PARTNER = `${CORE_BASE_URL}${apiLevel}partner-matching/multi-recommendation/`;
const PRODUCTORDER_URL = `${CORE_BASE_URL}${apiLevel}orders/`;
const RUNNING_PRODUCTS_URL = `${CORE_BASE_URL}${apiLevel}matching/product-recommendation/running/`;
const SKI_PRODUCTS_URL = `${CORE_BASE_URL}${apiLevel}matching/product-recommendation/skiboots/`;
const SIZE_RECOMMENDATION_BY_GTIN = `${CORE_BASE_URL}${apiLevel}matching/size-recommendation-from-onefid/?lastwidthcategory_mode=loose/`;
const SIMPLE_SIZE_RECOMMENDATION_BY_ONEFID =  `${CORE_BASE_URL}${apiLevel}matching/simple-size-recommendation-from-onefid/`;
const INSOLE_CREATE = `${CORE_BASE_URL}${apiLevel}insoles/create-insole-from-contour/`;
const INSOLE_CONTOURS = `${CORE_BASE_URL}${apiLevel}insoles/contours/`;
const STORES = `${CORE_BASE_URL}${apiLevel}stores/`;
const PRODUCTS_URL_WITH_STORE = `${CORE_BASE_URL}${apiLevel}products/`;
const CARTS = `${CORE_BASE_URL}${apiLevel}carts/`;

const PAGE_SIZE = 6;
const recommendations = {};

/**
 * @description function to get list of all products
 */
const getProducts = (storeID: string, gender?: string, safetyClass?: any, showPartnerProducts?: boolean) => {
  const baseUrl = showPartnerProducts ? `${PRODUCTS_URL}&show_partner_products=true` : `${PRODUCTS_URL}&store=${storeID}`;
  const url =
    gender && safetyClass ? `${baseUrl}&gender=${gender}&safety_class=${safetyClass}` : gender ? `${baseUrl}&gender=${gender}` : safetyClass ? `${baseUrl}&safety_class=${safetyClass}` : baseUrl;
  return API.get(url, { timeout: 500000 }).then((response: any) => response.results.filter((x: any) => !!x.mpn && !!x.brand));
};

/**
 * @description function to get products of a store
 */
const getStoreProductsbyMpn = (storeID: string, mpn: string) => {
  const url = `${PRODUCTS_URL_WITH_STORE}?store=${storeID}&mpn=${mpn}`;
  return API.get(url, { timeout: 500000 }).then((response: any) => response.results);
};

/**
 * @description function to get list of matched products
 */
const getMatchingProducts = (data: any) => API.post(MULTI_RECOMMENDATION_BY_PARTNER, data, { timeout: 500000 });


/**
 * @description function to create a cart.
 */
const createCart = (data: any) => API.post(CARTS, data);

/**
 * @description function to delete a cart.
 */
const deleteCart = (cartUUID: string) => API.deleteRequest(`${CARTS}${cartUUID}`);

/**
 * @description function to add a cart item.
 */
const addCartItem = (cartUUID: string, data: any) => {
  const url = `${CARTS}${cartUUID}/add-item/`
  return API.post(url, data);
}

/**
 * @description function to complete an order
 */
const completeOrder = (cartUUID: string, data: any) => {
  const url = `${CARTS}${cartUUID}/complete-order/`
  return API.post(url, data);
}

/**
 * @description function to get list of stores
 */
const getStores = () => API.get(STORES);

 /**
   * Returns a simple size recommendation by user's email address after login.
   *
   * @param onefid - User's email address.
   * @param brand - Shoe brand provided by partner.
   * @param category - Category provided by partner.
   */
 const fetchSimpleRecommendationByOnefid = (
  onefid: string,
  brand: string,
  category: string
) =>
  API
    .get(`${SIMPLE_SIZE_RECOMMENDATION_BY_ONEFID}?onefid=${onefid}&brand=${brand}&category=${category}&lastwidthcategory_mode=loose`);


/**
* @description function to make api call to create insole
*/
const postInsoleCreation = (data: any) => API.post(INSOLE_CREATE, data);

/**
* @description function to get insole contour ids
*/
const getInsoleContourIDs = () => { return API.get(INSOLE_CONTOURS); }


/**
 * @description function to get list of matched running products
 */
const getMatchingRunnigProducts = (
  ageValue: any,
  experienceValue: any,
  applicationValue: any,
  undergroundValue: any,
  kneeValue: any,
  heelValue: any,
  genderValue:any,
  onefid: any,
  cushioningValue?: any
): Promise<any> => {
  const baseURL = `${RUNNING_PRODUCTS_URL}?experience=${experienceValue}&application=${applicationValue}&underground=${undergroundValue}&knee_axis=${kneeValue}&heel_angle=${heelValue}&age_group=${ageValue}&gender=${genderValue}&onefid=${onefid}`
  const url = cushioningValue ? `${baseURL}&cushioning=${cushioningValue}` : baseURL
  return API.get(url, { timeout: 500000 });
};

/**
 * @description function to get list of matched ski products
 */
const getMatchingSkiProducts = (ageValue: any, skillValue: any, terrainValue: any, weightValue: any, genderValue, onefid: any) => {
  const url = `${SKI_PRODUCTS_URL}?skill=${skillValue}&underground=${terrainValue}&weight=${weightValue}&age_group=${ageValue}&gender=${genderValue}&onefid=${onefid}`;
  return API.get(url, { timeout: 500000 });
};

/**
 * @description function to get size recommendation of product by gtin
 */
const getMatchingProductsByGTIN = (onefid: string, gtin: string) => {
  const url = `${SIZE_RECOMMENDATION_BY_GTIN}&onefid=${onefid}&gtin=${gtin}`;
  return API.get(url);
};

/**
 * @description function to make an order
 */
const postProductOrder = (data: any) => API.post(PRODUCTORDER_URL, data);

/**
 * @description function to display diferrent products order based on scanID
 */
const mapRecommendationResponse = (response: any, currentScanId: string) => {
  const products = response;

  const firstSetOfProducts: any[] = [];

  // do we already have a recommendation set for this scanId?
  if (currentScanId && recommendations[currentScanId]) {
    const recommendation = recommendations[currentScanId];
    // are all of the previously recommended products in the server response?
    const intersection = _.intersection(recommendation, products);
    if (intersection.length === recommendation.length) {
      return recommendation;
    } else {
      // if not, let's start with what's in both and add the rest
      intersection.forEach(product => firstSetOfProducts.push(product));
    }
  }

  // get all unique brands from the product list
  const brands: string[] = [...new Set<string>(products.map((product: any) => product.brand))];

  // and group products by brand
  const productsByBrand = {};
  brands.forEach((brand: string) => {
    productsByBrand[brand] = products.filter(product => product.brand === brand);
  });

  const brandCount = brands.length;
  // how many products do we want to show per brand, at least 1
  const productsPerBrand = Math.max(Math.floor(PAGE_SIZE / brandCount), 1);
  const productsModulo = PAGE_SIZE % productsPerBrand;

  // do we start with an empty set?
  if (firstSetOfProducts.length === 0) {
    // randomize brand order, take the first 6, if there's that many
    const brandsToShow = firstN(PAGE_SIZE, _.shuffle(brands));
    brandsToShow.forEach((brand: string, index: number) => {
      // if we can't divide the page size by productsPerBrand, some brands have to get one more
      const count = productsModulo === 0 || index >= productsModulo ? productsPerBrand : productsPerBrand + 1;
      // randomize the products per brand, take the first x products
      const brandProducts = firstN(count, _.shuffle(productsByBrand[brand]));
      // and add them to the first results page
      brandProducts.forEach(product => firstSetOfProducts.push(product));
    });
  } else {
    // how many new products do we need to add?
    const extraCount = PAGE_SIZE - firstSetOfProducts.length;
    // what brands do we already have?
    const containedBrands: string[] = [...new Set<string>(firstSetOfProducts.map((product: any) => product.brand))];
    const brandsRest = _.difference(brands, containedBrands);
    // randomize brand order, take the first 'extraCount' brands, if there's that many
    const brandsToAdd = firstN(extraCount, brandsRest.length > 0 ? _.shuffle(brandsRest) : _.shuffle(brands));
    // add one product per brand to recommendation set
    brandsToAdd.forEach(brand => {
      firstSetOfProducts.push(firstN(1, _.shuffle(productsPerBrand[brand])));
    });
  }

  // take all products without our recommendations
  let rest = _.difference(products, firstSetOfProducts);

  // if for some reason the recommendation set does have fewer than PAGE_SIZE products, fill up with random products from the rest
  if (firstSetOfProducts.length < PAGE_SIZE) {
    firstN(PAGE_SIZE - firstSetOfProducts.length, _.shuffle(rest)).forEach(product => firstSetOfProducts.push(product));
    // re-calculate the products rest
    rest = _.difference(products, firstSetOfProducts);
  }

  // save generated recommendation for this user scan so we can show the same recommendations for the same user
  if (currentScanId) recommendations[currentScanId] = firstSetOfProducts;

  // and return everything together, recommendations first
  return [...firstSetOfProducts, ...rest];
};

const ProductService = {
  getProducts,
  getMatchingProducts,
  mapRecommendationResponse,
  getMatchingRunnigProducts,
  getMatchingSkiProducts,
  postProductOrder,
  getMatchingProductsByGTIN,
  fetchSimpleRecommendationByOnefid,
  postInsoleCreation,
  getInsoleContourIDs,
  getStores,
  getStoreProductsbyMpn,
  createCart,
  deleteCart,
  addCartItem,
  completeOrder,
};
export default ProductService;
