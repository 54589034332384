import { Platform, View } from "react-native";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, ImageBackground, StyleSheet, Text } from "react-native";
import Swiper from "react-native-web-swiper";
import { background, Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useGlobalState } from "../../shared/state";
import { Styles, Fonts } from "../../styles";
import ProductCard from "./../Product/ProductCard";
import { I18N } from "../../shared/util";

const AccessoriesCatalog: React.FC = (route: any): JSX.Element => {
  const { params } = route.route;
  const [matchedProducts] = useGlobalState("matchedProducts");
  const [currentScanner] = useGlobalState("currentScanner");
  const [Colors] = useGlobalState("colors");
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const [isLoading, setLoading] = useState<boolean>(false);

  const catalogTitle: string = i18n.t("accessoriesCatalogTitle");

  const insoleGenders = ["unisex", "female"];
  // const matchedInsole = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter];
  const matchedInsole = insoleGenders.map((gender) => currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[gender]);
  const chunkArray = (arr: any[], size: number) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArray.push(arr.slice(i, i + size));
    }

    return chunkedArray;
  };
  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={catalogTitle}>
          <View style={styles.loaderWrapper}>
            <View style={styles.loader}>
              <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
              <Text style={[Fonts.textTaller, Fonts.size18]}>{i18n.t("loadProductMessage")}</Text>
            </View>
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  } else if (!isLoading)
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={catalogTitle}>
          <View style={styles.swipeWrapper}>
          <Swiper
              from={0}
              controlsProps={{
                dotsTouchable: true,
                prevPos: false,
                nextPos: false,
                dotActiveStyle: { backgroundColor: "#3a3a3a" },
                dotsWrapperStyle: { width: 13, height: 13 },
              }}
            >
              {matchedInsole &&
                chunkArray(
                  matchedInsole.flatMap((insole: any) => Object.values(insole)),
                  6
                ).map((chunkedInsoles: any[], index: number) => (
                  // Ensure each chunk is wrapped properly in a view with a unique key
                  <View key={`chunk-${index}`} style={styles.cardWrapper}>
                    {chunkedInsoles.map((key: any) => (
                      <ProductCard
                        key={key.name}
                        name={key.name}
                        image_url={key.image}
                        size={matchedProducts && matchedProducts[0].products.size.eu}
                        gtin={key.sizes[matchedProducts && matchedProducts[0].products.size.eu]}
                        insoleSizes={key.sizes}
                        description={key.description}
                        isInsole
                      />
                    ))}
                  </View>
                ))
              }
            </Swiper>
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
};

export default AccessoriesCatalog;

const styles = StyleSheet.create({
  swipeWrapper: {
    flex: 1,
    marginTop: "2.2%",
  },
  cardWrapper: {
    width: "80%",
    height: "90%",
    flexDirection: "row",
    alignSelf: "center",
    flexWrap: "wrap",
    paddingLeft: "0.2%",
  },
  loaderWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: "15%",
    width: "100%",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: "1%",
  },
});
